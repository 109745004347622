import React from 'react'
import PropTypes from 'prop-types'

import classes from './onboarding.module.scss'

export default function TasksStatusHeading({ isOnboardingComplete }) {
  const title = isOnboardingComplete
    ? 'Well done! Your setup is complete!'
    : "Let's get you set up!"
  return (
    <div className={classes.onboardingHeaderContainer}>
      <div className={classes.onboardingTopHeader}>READY FOR BUSINESS</div>
      <div className={classes.onboardingBottomHeader}>{title}</div>
    </div>
  )
}

TasksStatusHeading.propTypes = {
  isOnboardingComplete: PropTypes.bool,
}
