import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'

import { businessName } from 'redux/modules/session/selectors'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import LoadingView from 'components/loaders/LoadingView'
import Alert from 'components/notifications/Alert'
import { callJSONApi } from 'libs/api'
import { If } from 'libs/formats'
import PaddedContent from 'ui/layout/PaddedContent'
import PageHeader from 'ui/components/PageHeader'
import Spacer from 'ui/layout/Spacer'
import typography from 'ui/typography/typography.module.scss'
import CenteredContent from 'ui/layout/CenteredContent'
import ShareBlock from 'ui/social/ShareBlock'
import giftVoucher from 'assets/images/gift-voucher@2x.png'
import FlatButton from 'components/buttons/FlatButton'
import CopyableLink from 'components/online/CopyableLink'
import { withOnboardingBlocker } from 'libs/withOnboardingBlocker'

import GiftVoucherImage from '../../assets/images/gift-voucher.svg'

class VouchersPageActivatedPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    callJSONApi(
      '/paymentPage/',
      'GET',
      null,
      (data) => {
        const { paymentPages } = data.data
        const paymentPage = paymentPages.length > 0 ? paymentPages[0] : null

        if (
          !paymentPage ||
          (paymentPage.supportedPageTypes &&
            paymentPage.supportedPageTypes.indexOf('voucher') === -1)
        ) {
          browserHistory.push('/vouchers/activate')
        } else {
          this.setState({
            isLoading: false,
            path: paymentPage.path,
            errorMessage: null,
          })
        }
      },
      (prettyError) => {
        this.setState({
          isLoading: false,
          errorMessage: prettyError,
        })
      }
    )
  }

  deactivateVouchersPage() {
    callJSONApi(
      '/paymentPage/',
      'DELETE',
      { pageType: 'voucher' },
      () => {
        browserHistory.push('/online/vouchers')
      },
      (prettyError) => {
        this.setState({
          isLoading: false,
          errorMessage: prettyError,
        })
      }
    )
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingView />
    }

    const voucherURL = `https://pay.yoco.com/v/${this.state.path}`

    return (
      <MaxWidthBlock>
        <PaddedContent isTextCentered limitWidth>
          <If condition={this.state.errorMessage}>
            <Alert
              messageType='danger'
              message={this.state.errorMessage}
              closable
              onClose={() => this.setState({ errorMessage: null })}
            />
          </If>
          <PageHeader>Gift vouchers page activated!</PageHeader>

          <img src={giftVoucher} alt='Gift voucher' height='141px' />
          <Spacer size='xl' />

          <p>
            You can now start selling gift vouchers to your customers online. Earn immediate sales
            from customers that they can then redeem in store at a later date. Giving you instant
            sales and cashflow relief.
          </p>

          <p>
            To allow merchants to purchase vouchers, you can share this link via Email, WhatsApp,
            SMS or Social Media
          </p>

          <Spacer size='large' />

          {this.state.errorMessage ? (
            'Unable to load voucher page'
          ) : (
            <CopyableLink link={voucherURL} />
          )}

          <Spacer size='xl' />

          <p>Share your gift voucher page:</p>

          <CenteredContent>
            <ShareBlock
              url={voucherURL}
              title={`Buy a gift voucher from ${this.props.businessName}`}
            />
          </CenteredContent>

          <Spacer size='xl' />

          <p className={typography.textSmall}>
            Need help?
            <br />
            <a href='https://get.yoco.help/hc/en-za/articles/360007134158'>
              Learn more about selling gift vouchers
            </a>
          </p>

          <p>
            <FlatButton
              label='Deactivate gift vouchers page'
              className='tertiary'
              onClick={() => this.deactivateVouchersPage()}
            />
          </p>
        </PaddedContent>
      </MaxWidthBlock>
    )
  }
}

VouchersPageActivatedPage.propTypes = {
  businessName: PropTypes.string.isRequired,
}

export default withOnboardingBlocker(
  connect((state) => ({
    businessName: businessName(state),
  }))(VouchersPageActivatedPage),
  {
    image: GiftVoucherImage,
    header: 'Gift Vouchers',
    body:
      'Accept online payments with Gift Vouchers. Let your customers support you whenever, wherever.',
    buttonLabel: 'Activate Gift Vouchers',
  }
)
