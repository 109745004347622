import { DeclineReasons } from 'redux/modules/capital/types'

const DECLINE_REASONS: {
  label: string
  value: DeclineReasons
}[] = [
  {
    label: 'I would rather speak to someone, please call me back',
    value: DeclineReasons.CallBack,
  },
  { label: `I'm just browsing around`, value: DeclineReasons.Browsing },
  { label: `I don't need this at this time`, value: DeclineReasons.DontNeed },
  { label: `I'm unhappy with the pricing`, value: DeclineReasons.Pricing },
  { label: `I'm experiencing system errors`, value: DeclineReasons.SystemError },
  { label: 'Other', value: DeclineReasons.Other },
]

export default DECLINE_REASONS
