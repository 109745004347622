import React from 'react'

import InstructionsHeader from './components/InstructionsHeader'
import DocumentRowHeading from './components/DocumentRowHeading'
import DocumentRow from './components/DocumentRow'
import InstructionsNote from './components/InstructionsNote'
import smartCardIdIcon from './images/single-neutral-id-card-3.svg'
import passportIcon from './images/earth-3.svg'
import greenBookIdIcon from './images/book-flip-page.svg'

function CCDInstructions() {
  return (
    <div>
      <InstructionsHeader />
      <DocumentRowHeading />

      <DocumentRow rowIcon={greenBookIdIcon} rowDescription='South African Green ID Book' />

      <DocumentRow rowIcon={smartCardIdIcon} rowDescription='South African Smart ID Card' />

      <DocumentRow rowIcon={passportIcon} rowDescription='Foreign Passport' />

      <InstructionsNote />
    </div>
  )
}

export default CCDInstructions
