import React from 'react'
import PropTypes from 'prop-types'

import { FORM_NAMES } from 'redux/modules/onboarding'
import FlatButton from 'components/buttons/FlatButton'
import * as forms from 'libs/forms'
import { track } from 'libs/analytics'
import LoadingView from 'components/loaders/LoadingView'

import classes from './styles.module.scss'

function IidentifiiForm({
  formData,
  businessUserDetails: { businessDetails },
  setPopupVisible,
  onSetIdDetailsPopupVisible,
  postBusinessDetails,
  postIidentifiiIdNumber,
  setIsVerifyingIDLoaderVisible,
  isVerifyingIdNumber,
}) {
  const idNumber = formData && formData.get('idNumber')
  const isFieldDisabled = !businessDetails.businessHasNotBeenVetted

  const popupHandlers = () => {
    onSetIdDetailsPopupVisible(false)
    setPopupVisible(true)
    setIsVerifyingIDLoaderVisible(false)
  }

  const handleOnSubmit = () => {
    track('portal_fica_iidentifii_click_button_next_id_details')
    const vettingInformation = {
      personal: {
        firstName: formData && formData.get('firstName'),
        lastName: formData && formData.get('lastName'),
        idNumber,
      },
    }

    setIsVerifyingIDLoaderVisible(true)
    if (businessDetails.businessHasNotBeenVetted) {
      postBusinessDetails({
        vettingInformation,
        onBusinessDetailsPostSuccess: () => {
          postIidentifiiIdNumber(idNumber, popupHandlers)
        },
      })
    } else {
      postIidentifiiIdNumber(idNumber, popupHandlers)
    }
  }

  return (
    <div className={classes.iidentifiiForm}>
      {isVerifyingIdNumber ? (
        <LoadingView message='Please wait, we are scanning your id number.' />
      ) : (
        <forms.Form name={FORM_NAMES.IIDENTIFII} onSubmit={handleOnSubmit} showLoader={false}>
          <forms.TextField
            name='personal.firstName'
            label='First name'
            required
            isFullWidth
            className={classes.iidentifiiField}
            disabled={isFieldDisabled}
          />

          <forms.TextField
            name='personal.lastName'
            label='Last name'
            required
            isFullWidth
            className={classes.iidentifiiField}
            disabled={isFieldDisabled}
          />

          <forms.TextField
            name='personal.idNumber'
            label='ID Number'
            type='number'
            required
            isFullWidth
            className={classes.iidentifiiField}
            validators={[new forms.LuhnIDValidator('You must provide a valid ID number')]}
            disabled={isFieldDisabled}
          />

          <FlatButton
            label='Scan'
            className={`${classes.iidentifiiButton} blueBackground`}
            type='submit'
          />
        </forms.Form>
      )}
    </div>
  )
}

IidentifiiForm.propTypes = {
  formData: PropTypes.object,
  setPopupVisible: PropTypes.func,
  onSetIdDetailsPopupVisible: PropTypes.func,
  postBusinessDetails: PropTypes.func,
  postIidentifiiIdNumber: PropTypes.func,
  isVerifyingIdNumber: PropTypes.bool,
  setIsVerifyingIDLoaderVisible: PropTypes.func,
  businessUserDetails: PropTypes.object,
}

export default IidentifiiForm
