import { callJSONApi } from 'libs/api'

import { fetchOnboardingTasks } from '../index'

import {
  EFT_ACTIONS,
  eftProofSubmitted,
  eftProofError,
  eftProofSubmitting,
  eftProofFetching,
  eftFetchedProof,
} from './actions'

/**
 * Fetches latest proof of payment uploaded in the Core DB.
 */
function fetchLatestProofOfPayment() {
  return (dispatch) => {
    dispatch(eftProofFetching())
    callJSONApi(
      `/onboarding/eft/fetchProof/`,
      'GET',
      {},
      (response) => {
        console.log('Response from Core [fetchProof]')
        if (response.status === 200) {
          const storedFile = response.data.latestProofOfPayment
          dispatch(eftFetchedProof(storedFile))
        }
      },
      (prettyError, jqXHR, textStatus, errorThrown) => {
        console.error(
          'Error response from Core [fetchProof] ',
          prettyError,
          jqXHR,
          textStatus,
          errorThrown
        )
        if (jqXHR.status !== 404) {
          dispatch(eftProofError(prettyError))
        } else {
          dispatch(eftFetchedProof(null))
        }
      }
    )
  }
}

/**
 *  Submits the proof of payment to Core which will trigger an email being sent to support
 *
 * @param {*} storedFileUUID -  stored file UUID in DB
 */
function submitEFTProof(storedFileUUID) {
  return (dispatch) => {
    dispatch(eftProofSubmitting())
    callJSONApi(
      `/onboarding/eft/submitProof`,
      'POST',
      { storedFileUUID },
      (response) => {
        console.log('Response from Core [submitProof]')
        if (response.status === 200) {
          // we have successfully submitted to core
          // this should refresh the current tasks with the updated state
          dispatch(eftProofSubmitted())
          dispatch(fetchOnboardingTasks())
        }
      },
      (prettyError, jqXHR, textStatus, errorThrown) => {
        console.error(
          'Error response from core [submitProof] :::',
          prettyError,
          jqXHR,
          textStatus,
          errorThrown
        )
        dispatch(eftProofError(prettyError))
      }
    )
  }
}

export {
  EFT_ACTIONS,
  eftProofSubmitted,
  eftProofError,
  eftProofSubmitting,
  eftProofFetching,
  eftFetchedProof,
  fetchLatestProofOfPayment,
  submitEFTProof,
}
