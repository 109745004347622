import { Map } from 'immutable'

import {
  fetchBusinessOwnerDetails,
  fetchBusinessVetting,
  postBusinessDetails,
  submitFICADetails,
  FORM_NAMES,
} from 'redux/modules/onboarding'

export default function uploadIdentityDocument(
  forms,
  businessDetails,
  userDetails,
  dispatch,
  dismissUploadIDPopup
) {
  const ficaFormData = (forms.getIn([FORM_NAMES.FICA_VERIFICATION, 'data']) || Map()).toJS()
  const vettingInformation = {
    personal: {
      idNumber: businessDetails.businessOwnerInfoIsMissing
        ? ficaFormData.idNumber
        : userDetails.idNumber,
      firstName: businessDetails.businessOwnerInfoIsMissing
        ? ficaFormData.firstName
        : userDetails.firstName,
      lastName: businessDetails.businessOwnerInfoIsMissing
        ? ficaFormData.lastName
        : userDetails.lastName,
      passportNumber: businessDetails.businessOwnerInfoIsMissing
        ? ficaFormData.passportNumber
        : userDetails.passport,
    },
  }

  if (businessDetails.businessHasNotBeenVetted && businessDetails.businessOwnerInfoIsMissing) {
    dispatch(
      postBusinessDetails({
        vettingInformation,
        actionFlag: 'ficaDocumentUpload',
        onBusinessDetailsPostSuccess: () => {
          dispatch(fetchBusinessVetting())
          dispatch(fetchBusinessOwnerDetails())
          dispatch(submitFICADetails())
          dismissUploadIDPopup()
        },
      })
    )
  } else {
    dispatch(submitFICADetails(dismissUploadIDPopup))
  }
}
