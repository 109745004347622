/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import RadioButton from './components/RadioButton'
import styles from './styles.module.scss'

class RadioButtonForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedIndex: 0,
    }
  }

  handlePress = (option, index) => {
    this.setState({ selectedIndex: index })
    this.props.onPress(option.value)
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.options.map((option, index) => (
          <RadioButton
            key={index}
            index={index}
            option={option}
            checked={this.state.selectedIndex === index}
            onPress={this.handlePress}
          />
        ))}
      </div>
    )
  }
}

RadioButtonForm.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  onPress: PropTypes.func.isRequired,
}

export default RadioButtonForm
