import { createSelector } from 'reselect'

import {
  isFetchingFICASelector,
  isSubmittingFICACommentsSelector,
  isAttachingFICAFileSelector,
  isSubmittingFICASelector,
  isFICASubmittedSelector,
  isFICAAttachedSelector,
  isFICACommentsSubmittedSelector,
  errorMessageFICASelector,
  uploadedIdentityDocumentSelector,
  ficaVerificationRequestSelector,
  isUploadFicaSubmitButtonDisabled,
  ficaUploadPopupInfo,
  shouldLaunchFicaPassportIdPopup,
  shouldLaunchFicaVerificationModal,
  shouldLaunchFicaIdDetailsPopup,
  shouldLaunchFicaIidentifiiPopup,
  isVerifyingIidentifiiIdNumber,
  iFrameUrl,
  iFrameIsLoading,
  shouldLaunchFicaFailedIidentifiiPopup,
  shouldLaunchFicaIidentifiiCompletePopup,
} from './fica/selectors'
import {
  businessAddress,
  disableBusinessAddress,
  shouldLaunchAddressPopup,
} from './businessDetails/selectors'
import {
  isSubmittingEFTSelector,
  isEFTSubmittedSelector,
  isFetchingEFTSelector,
  errorMessageEFTSelector,
  uploadedEFTProofSelector,
  onboardingTasksSelector,
  shouldLoadOnboardingSelector,
  isDeliveryMethodCcdSelector,
  fulfillmentSelector,
  paymentStateSelector,
  isDeliveryMethodCourierSelector,
  paymentMethodSelector,
} from './eft/selectors'

import {
  firstSettlementPayoutStatus,
  isProgressStepCompleted,
  PROGRESS_STATUS,
  PROGRESS_TYPES,
} from './index'

const vettingState = (state) => state.session.getIn(['business', 'vettingState']) || ''

const businessType = (state) => state.session.getIn(['business', 'info', 'businessType']) || ''

const tradingName = (state) => state.session.getIn(['business', 'tradingName']) || ''

const avgTotalMonthlyRevenue = (state) =>
  state.session.getIn(['business', 'info', 'avgTotalMonthlyRevenue'])

const getProgress = (state) => state.onboarding.get('progress')

const progressSelector = createSelector([getProgress], (progressEntries) =>
  progressEntries.map((progress) => {
    switch (progress.cardType) {
      case PROGRESS_TYPES.VETTING:
        return {
          cardType: progress.cardType,
          title: 'Application Approval',
          description: progress.description,
          status: isProgressStepCompleted(progress)
            ? PROGRESS_STATUS.FINISH
            : PROGRESS_STATUS.PROCESS,
        }
      case PROGRESS_TYPES.DELIVERY:
        return {
          cardType: progress.cardType,
          title: 'Receive card machine',
          description: progress.description,
          status: isProgressStepCompleted(progress) ? PROGRESS_STATUS.FINISH : PROGRESS_STATUS.WAIT,
        }
      case PROGRESS_TYPES.FIRST_SETTLEMENT:
        return {
          cardType: progress.cardType,
          title: 'First payout',
          description: progress.description,
          status: firstSettlementPayoutStatus(progress.status),
        }
      default:
        return {}
    }
  })
)

export {
  progressSelector,
  getProgress,
  vettingState,
  businessType,
  tradingName,
  avgTotalMonthlyRevenue,
  isFetchingFICASelector,
  isSubmittingFICACommentsSelector,
  isAttachingFICAFileSelector,
  isSubmittingFICASelector,
  isFICASubmittedSelector,
  isFICAAttachedSelector,
  isFICACommentsSubmittedSelector,
  errorMessageFICASelector,
  uploadedIdentityDocumentSelector,
  ficaVerificationRequestSelector,
  isUploadFicaSubmitButtonDisabled,
  ficaUploadPopupInfo,
  shouldLaunchFicaPassportIdPopup,
  shouldLaunchFicaVerificationModal,
  shouldLaunchFicaIdDetailsPopup,
  shouldLaunchFicaIidentifiiPopup,
  isVerifyingIidentifiiIdNumber,
  iFrameUrl,
  iFrameIsLoading,
  shouldLaunchFicaFailedIidentifiiPopup,
  shouldLaunchFicaIidentifiiCompletePopup,
  businessAddress,
  disableBusinessAddress,
  shouldLaunchAddressPopup,
  isSubmittingEFTSelector,
  isEFTSubmittedSelector,
  isFetchingEFTSelector,
  errorMessageEFTSelector,
  uploadedEFTProofSelector,
  onboardingTasksSelector,
  shouldLoadOnboardingSelector,
  isDeliveryMethodCcdSelector,
  fulfillmentSelector,
  paymentStateSelector,
  isDeliveryMethodCourierSelector,
  paymentMethodSelector,
}
