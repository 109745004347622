import React from 'react'
import FlatButton from 'components/buttons/FlatButton'
import PropTypes from 'prop-types'
import Spinner from 'components/loaders/Spinner'

import classes from './BlockedPage.module.scss'
import OnBoardingCards from './OnboardingStates'

export const SpinnerContainer = () => {
  return (
    <div className={classes.container}>
      <Spinner blue />
    </div>
  )
}

const BlockedPage = ({ BlockPageProps, isOnboarded }) => {
  const { image, header, body, buttonLabel } = BlockPageProps
  const [showOnboardingCard, setShowOnboardingCard] = React.useState(false)

  return (
    <div className={classes.container}>
      <img src={image} className={classes.image} alt='BlockerPageImage' />
      <h1 className={classes.header}>{header}</h1>
      <p className={classes.body}>{body}</p>
      <FlatButton
        label={buttonLabel}
        className={`${classes.button} blueBackground`}
        onClick={() => setShowOnboardingCard(true)}
      />

      {showOnboardingCard ? <OnBoardingCards isOnboarded={isOnboarded} /> : null}
    </div>
  )
}

BlockedPage.propTypes = {
  isOnboarded: PropTypes.string.isRequired,
  BlockPageProps: PropTypes.exact({
    image: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.string,
    buttonLabel: PropTypes.string,
  }).isRequired,
}

export default BlockedPage
