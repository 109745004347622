import React from 'react'
import PropTypes from 'prop-types'

import DigitalFicaComponent from 'components/onboarding/inlineActionContent/digitalfica/DigitalFicaComponent'

import classes from './styles.module.scss'

function DocumentUpload({
  ficaVerificationRequest,
  ficaForms,
  isIDUploadPopupRendered,
  southAfricanID,
}) {
  return (
    <div>
      <div className={classes.uploadIDTextContainer}>
        <h5 className={`${classes.uploadIDTextContainer} ${classes.uploadIDText}`}>
          {southAfricanID ? 'ID Upload' : 'Passport Upload'}
        </h5>
      </div>
      <div className={classes.uploadIDNoteContainer}>
        <h6 className={`${classes.uploadIDNoteContainer} ${classes.uploadIDNote}`}>
          {southAfricanID
            ? 'A clear copy of the bank account owner’s SA ID or passport'
            : 'A clear copy of your foreign passport'}
        </h6>
      </div>

      <div>
        <DigitalFicaComponent
          ficaVerification={ficaVerificationRequest}
          forms={ficaForms}
          isIDUploadPopupRendered={isIDUploadPopupRendered}
        />
      </div>
    </div>
  )
}

DocumentUpload.propTypes = {
  ficaVerificationRequest: PropTypes.object.isRequired,
  ficaForms: PropTypes.object,
  isIDUploadPopupRendered: PropTypes.bool,
  southAfricanID: PropTypes.bool,
}

export default DocumentUpload
