import React from 'react'

import classes from './styles.module.scss'

function InstructionsHeader() {
  return (
    <div>
      <h2 className={classes.headerText}>What happens on delivery?</h2>
      <h3 className={classes.headerSubtext}>
        On delivery of your card machine, CCD couriers will take a picture of your ID
      </h3>
    </div>
  )
}

export default InstructionsHeader
