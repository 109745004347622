import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import SplitBlock from 'components/blocks/SplitBlock'
import { CustomerForm, FormName } from 'containers/store/components/Customers'

import { fetchInvoiceOnboardingData } from '../utils'

function TaskFirstCustomer({ onClose, children }) {
  const [customer] = useState(new Map())

  return (
    <SplitBlock
      showingSplit
      header='Add a Customer'
      formName={FormName}
      renderSplitContent={() => (
        <CustomerForm
          customer={customer}
          onSaved={() => {
            onClose()
            fetchInvoiceOnboardingData({ showLoader: true })
          }}
        />
      )}
      onDismiss={onClose}
    >
      {children}
    </SplitBlock>
  )
}

TaskFirstCustomer.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default TaskFirstCustomer
