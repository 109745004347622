/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  fieldUpdated,
  initializeForm,
  multipleFieldsUpdated,
  submitForm,
} from 'redux/modules/forms'
import { FORM_NAMES, updateBusinessAddressSubmitErrorMessage } from 'redux/modules/onboarding'
import { track } from 'libs/analytics'
import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'
import BusinessInfoForm from 'components/popups/OnboardingPopups/BusinessInfoPopup/components/BusinessInfoForm'
import { isFlagship } from 'libs/utils'
import { connect } from 'react-redux'
import { businessDetails } from 'redux/modules/session/selectors'

const BusinessAddressPopup = ({
  popupHeaderTitle,
  onClosePopup,
  showAddressPopup,
  dispatch,
  dismissAddressPopup,
  businessAddress,
  forms,
  failedAddressSubmitError,
  registrationNumber,
  isRegisteredBusiness,
  provinces,
}) => {
  const [submitted, setSubmitted] = useState(0)

  const showForms = {
    addressForm: !businessAddress.get('firstLine'),
    companyRegistrationForm: isRegisteredBusiness && !registrationNumber,
  }

  const onClick = () => {
    showForms.addressForm && dispatch(submitForm(FORM_NAMES.BUSINESS_ADDRESS))
    showForms.companyRegistrationForm &&
      dispatch(submitForm(FORM_NAMES.COMPANY_REGISTRATION_NUMBER))
    track('portal_business_address_submit_button_clicked', {
      flagship: isFlagship,
    })
  }

  const onSuccess = () => {
    if (submitted + 1 >= Object.values(showForms).reduce((a, item) => a + item, 0)) {
      dismissAddressPopup()
    }
    setSubmitted(submitted + 1)
  }

  const onFail = (submitErrorMessage) => {
    setSubmitted(0)
    dispatch(updateBusinessAddressSubmitErrorMessage(submitErrorMessage))
  }

  useEffect(() => {
    businessAddress.get('firstLine')
      ? dispatch(initializeForm(FORM_NAMES.BUSINESS_ADDRESS, businessAddress))
      : null
  }, [businessAddress, dispatch])

  return (
    <PopupContentLayout
      headerTitle={popupHeaderTitle}
      onClosePopup={onClosePopup}
      showing={showAddressPopup}
      popupErrorMessage={failedAddressSubmitError}
      popupBodyContent={
        <BusinessInfoForm
          dismissForm={dismissAddressPopup}
          firstLine={forms.getIn([FORM_NAMES.BUSINESS_ADDRESS, 'data', 'firstLine'])}
          updateFirstLine={(firstLine) =>
            dispatch(fieldUpdated(FORM_NAMES.BUSINESS_ADDRESS, 'firstLine', firstLine))
          }
          updateAddressFields={(data) =>
            dispatch(multipleFieldsUpdated(FORM_NAMES.BUSINESS_ADDRESS, data))
          }
          onSuccess={onSuccess}
          onFailedSubmit={(submitErrorMessage) => onFail(submitErrorMessage)}
          showForms={showForms}
          provinces={provinces}
        />
      }
      hideFooterContent={false}
      onFooterButtonClick={onClick}
    />
  )
}

BusinessAddressPopup.propTypes = {
  popupHeaderTitle: PropTypes.string,
  onClosePopup: PropTypes.func,
  showAddressPopup: PropTypes.bool,
  dispatch: PropTypes.func,
  dismissAddressPopup: PropTypes.func,
  forms: PropTypes.object,
  failedAddressSubmitError: PropTypes.string,
  businessAddress: PropTypes.object,
  registrationNumber: PropTypes.string,
  isRegisteredBusiness: PropTypes.bool,
  provinces: PropTypes.array,
}

const mapStateToProps = (state) => ({
  registrationNumber: businessDetails(state).businessDetails.registrationNumber,
  isRegisteredBusiness: businessDetails(state).businessDetails.isRegisteredBusiness,
})

export default connect(mapStateToProps)(BusinessAddressPopup)
