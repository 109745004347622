import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import FlatButton from 'components/buttons/FlatButton'

import classes from './tasks.module.scss'

function Task({ title, iconName, buttonLabel, onButtonClick, isDone, disabled, testID }) {
  return (
    <div className={classNames(classes.task, { [classes.taskDone]: isDone })}>
      <Icon name={iconName} size={32} color='#878B90' />
      <span>{isDone ? 'Done' : 'To do'}</span>
      <h2>{title}</h2>
      <FlatButton
        label={buttonLabel}
        className='blueBackground'
        onClick={onButtonClick}
        disabled={disabled || isDone}
        testID={testID}
      />
    </div>
  )
}

Task.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.oneOf(['invoice', 'contacts', 'plane-paper']).isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  isDone: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  testID: PropTypes.string,
}

export default Task
