import React from 'react'
import Alert from 'components/notifications/Alert'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectBankAccountUpdateRequest } from 'redux/modules/onboarding/bankDetails/selectors'

function VerificationAlert({ bankAccountUpdateRequest }) {
  return (
    <Alert messageType='info' closable={false}>
      <b>We’re busy verifying your bank details</b>
      <br />
      <br />
      <b>Bank:</b> {bankAccountUpdateRequest?.updateInfo?.bank}
      <br />
      <b>Account Number:</b> {bankAccountUpdateRequest?.updateInfo?.accountNumber}
      <br />
      <br />
      <p>Until then you’ll receive funds in the account below.</p>
    </Alert>
  )
}

VerificationAlert.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bankAccountUpdateRequest: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  bankAccountUpdateRequest: selectBankAccountUpdateRequest(state).toJS(),
})

export default connect(mapStateToProps)(VerificationAlert)
