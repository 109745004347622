import React from 'react'

import BlockHeader from 'components/headers/BlockHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import { withOnboardingBlocker } from 'libs/withOnboardingBlocker'

import { withOnboarding } from './components/Onboarding'
import { UpdateInvoicesSettings } from './components/Settings'
import InvoiceImage from '../../assets/images/Invoices-64px.svg'

function InvoicesSettingsPage() {
  return (
    <MaxWidthBlock>
      <BlockHeader title='Invoice Settings' />
      <div style={{ maxWidth: 540 }}>
        <UpdateInvoicesSettings />
      </div>
    </MaxWidthBlock>
  )
}
export default withOnboardingBlocker(
  withOnboarding(InvoicesSettingsPage, {
    // General Onboarding have to be completed in order to show this page
    isOnboardingComplete: true,
  }),
  {
    image: InvoiceImage,
    header: 'Invoices',
    body:
      'Create and send professional click-to-pay invoices, and get paid faster with online payments',
    buttonLabel: 'Create an invoice',
  }
)
