/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import Calendar from 'react-calendar'
import { Icon } from '@yoco/design-system-icons/dist/react'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'
import PropTypes from 'prop-types'

import DismissComponent from 'libs/DismissComponent'
import classes from 'libs/filters/filters.module.scss'

export default class CalendarButton extends DismissComponent {
  dateChanged(dateObject) {
    if (!this.props.isRangePicker) {
      if (this.props.dateChanged) {
        this.props.dateChanged(dateObject)
      }

      if (!this.props.stayOpen) {
        this.toggle()
      }
    }
  }

  rangeChanged(initialDateObjects) {
    const dateObjects = initialDateObjects.map((date) => moment(date))
    if (this.props.isRangePicker && dateObjects && dateObjects.length > 1) {
      // This is a hack because it calls onRangeChange twice every time I change range.
      if (
        !this.rangePicked ||
        !this.rangePicked[0].isSame(dateObjects[0], 'days') ||
        !this.rangePicked[1].isSame(dateObjects[1], 'days')
      ) {
        this.rangePicked = dateObjects
        if (this.props.rangeChanged) {
          this.props.rangeChanged([dateObjects[0], dateObjects[1]])
        }

        if (!this.props.stayOpen) {
          this.onDismiss()
        }
      } else {
        // This means it was the second onRangeChange call, clear it so next time we get we listen
        // for it again.
        this.rangePicked = undefined
      }
    }
  }

  handleSelectedDate = (selectedDate) => {
    if (this.props.isRangePicker) {
      this.rangeChanged(selectedDate)
    } else {
      this.dateChanged(selectedDate)
    }
  }

  getDatePicker() {
    if (this.state.toggled) {
      const date = !this.props.isRangePicker ? this.props.date._d : undefined
      const defaultRange = this.props.isRangePicker ? this.props.defaultRange : undefined
      return (
        <div>
          <div className={`${classes.pickerContainer}`}>
            <Calendar
              value={this.props.isRangePicker ? defaultRange : date}
              onChange={this.handleSelectedDate}
              returnValue={this.props.isRangePicker ? 'range' : 'start'}
              selectRange={this.props.isRangePicker}
              locale='en'
            />
          </div>
        </div>
      )
    }
    return <div />
  }

  toggle() {
    this.setState((previousState) => ({
      toggled: !previousState.toggled,
    }))
  }

  getClassName() {
    let className = `${classes.numberSelect} ${classes.dateSelect}`
    if (this.props.className) {
      className = `${className} ${this.props.className}`
    }

    return className
  }

  getDateDisplay() {
    if (this.props.isRangePicker) {
      if (this.props.defaultRange && this.props.defaultRange.length > 1) {
        return `${this.props.defaultRange[0].format('DD/MM/YY')} to ${moment(
          this.props.defaultRange[1]
        ).format('DD/MM/YY')}`
      }
      return ''
    }

    return this.props.date.format('MMMM Do YYYY')
  }

  render() {
    return (
      <div
        className={this.getClassName()}
        onMouseDown={this.mouseDownHandler.bind(this)}
        onMouseUp={this.mouseUpHandler.bind(this)}
      >
        {this.getDatePicker()}
        <div
          className={`${classes.valueDisplay} ${classes.dateValueDisplay}`}
          onClick={this.toggle.bind(this)}
        >
          {this.getDateDisplay()}
        </div>

        <span onClick={this.toggle.bind(this)}>
          <Icon name='calendar' size={24} data-testid={this.props.testID} />
        </span>
      </div>
    )
  }
}

CalendarButton.propTypes = {
  date: PropTypes.object,
  className: PropTypes.string,
  pickerClass: PropTypes.string,
  dateChanged: PropTypes.func,
  rangeChanged: PropTypes.func,
  stayOpen: PropTypes.bool,
  isRangePicker: PropTypes.bool,
  defaultRange: PropTypes.array,
  testID: PropTypes.string,
}
