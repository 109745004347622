import { multipleFieldsUpdated } from 'redux/modules/forms'
import {
  updateBusinessHasNotBeenVetted,
  updateBusinessOwnerInfoIsMissing,
  updateCompanyRegistrationNumber,
  updateOwnerIDNumber,
  updateOwnerPassport,
  updateUserInfo,
} from 'redux/modules/session'
import { callJSONApi } from 'libs/api'

import {
  initializeFicaVerificationForm,
  updateFicaVerificationFromVettingInformation,
  updateIidentifiiFromVettingInformation,
} from '../fica'
import { FORM_NAMES } from '../index'

// BUSINESS VETTING ACTIONS
const BUSINESS_VETTING_ACTIONS = {
  VETTING_INFO_FETCH_ERROR_MESSAGE: 'VETTING_INFO_FETCH_ERROR_MESSAGE',
  FAILED_FETCHING_USER_INFO: 'FAILED_FETCHING_USER_INFO',
}

const updateVettingFetchErrorMessage = (payload) => ({
  type: BUSINESS_VETTING_ACTIONS.VETTING_INFO_FETCH_ERROR_MESSAGE,
  payload,
})

const updateFailedUserInfoFetchErrorMessage = (payload) => ({
  type: BUSINESS_VETTING_ACTIONS.FAILED_FETCHING_USER_INFO,
  payload,
})

const shouldInitiateVetting = (vettingState) => {
  const lockedVettingStates = [
    'in_process',
    'requires_additional_documentation',
    'requires_manual_decision',
    'requires_additional_information',
    'system_error',
    'vetting_on_hold',
    'automated_vetting_failed',
    'successful',
    'failed',
    'suspended',
  ]

  const isInLockedState =
    lockedVettingStates.filter((lockedState) => vettingState === lockedState).length > 0

  return !isInLockedState
}

const isOwnerInfoFound = (vettingInformation) => {
  return !!(
    vettingInformation &&
    vettingInformation.personal &&
    vettingInformation.personal.firstName &&
    vettingInformation.personal.lastName &&
    (vettingInformation.personal.idNumber || vettingInformation.personal.passportNumber)
  )
}

const updateBuyCardMachineUserInfoFromVettingInformation = (dispatch, vettingInformation) => {
  dispatch(
    multipleFieldsUpdated(FORM_NAMES.BUY_CARD_MACHINE, {
      firstName: vettingInformation.personal && vettingInformation.personal.firstName,
      lastName: vettingInformation.personal && vettingInformation.personal.lastName,
      idNumber: vettingInformation.personal && vettingInformation.personal.idNumber,
      passportNumber: vettingInformation.personal && vettingInformation.personal.passportNumber,
      isSouthAfricanID: !!(vettingInformation.personal && vettingInformation.personal.idNumber),
      isPassportDocument: !!(
        vettingInformation.personal && vettingInformation.personal.passportNumber
      ),
    })
  )
}

const updateUserInfoFromVettingInformation = (dispatch, vettingInformation) => {
  dispatch(
    updateUserInfo({
      firstName: vettingInformation && vettingInformation.personal.firstName,
      lastName: vettingInformation && vettingInformation.personal.lastName,
    })
  )
  dispatch(updateOwnerIDNumber(vettingInformation.personal && vettingInformation.personal.idNumber))
  dispatch(
    updateOwnerPassport(vettingInformation.personal && vettingInformation.personal.passportNumber)
  )
}

const fetchBusinessVetting = () => (dispatch) => {
  callJSONApi(
    '/vetting/',
    'GET',
    {},
    (response) => {
      const { vettingInformation, vettingState } = response.data.vettingRequest

      if (isOwnerInfoFound(vettingInformation)) {
        dispatch(updateBusinessOwnerInfoIsMissing(false))
        updateFicaVerificationFromVettingInformation(dispatch, vettingInformation)
        updateUserInfoFromVettingInformation(dispatch, vettingInformation)
        dispatch(
          updateCompanyRegistrationNumber(
            vettingInformation && vettingInformation.business.registrationNumber
          )
        )
      } else {
        dispatch(updateBusinessOwnerInfoIsMissing(true))
        initializeFicaVerificationForm(dispatch, vettingInformation)
      }
      dispatch(updateBusinessHasNotBeenVetted(shouldInitiateVetting(vettingState)))
      dispatch(updateVettingFetchErrorMessage(undefined))
      updateBuyCardMachineUserInfoFromVettingInformation(dispatch, vettingInformation)
      updateIidentifiiFromVettingInformation(dispatch, vettingInformation)
    },
    () => {
      dispatch(
        updateVettingFetchErrorMessage(
          'Failed fetching business vetting information, ' +
            'please check internet connection and reload the page'
        )
      )
    }
  )
}

export {
  BUSINESS_VETTING_ACTIONS,
  updateVettingFetchErrorMessage,
  updateFailedUserInfoFetchErrorMessage,
  shouldInitiateVetting,
  isOwnerInfoFound,
  updateBuyCardMachineUserInfoFromVettingInformation,
  updateUserInfoFromVettingInformation,
  fetchBusinessVetting,
}
