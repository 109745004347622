import React from 'react'
import { callOnboardingAPI } from 'libs/api'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hasFeature } from 'libs/features'

import BlockedPage, { SpinnerContainer } from './BlockedPage'

export const withOnboardingBlocker = (WrappedComponent, BlockPageProps) => {
  const WithBlocker = (props) => {
    const [onboardingStatus, setOnboardingStatus] = React.useState(undefined)
    const { shouldPreventTransactionsBeforeOnboarding } = props

    React.useEffect(() => {
      callOnboardingAPI('/onboarding_status', 'GET', {}, (response) => {
        const { data } = response
        setOnboardingStatus(data.status)
      })
    }, [])

    if (onboardingStatus === undefined) {
      return <SpinnerContainer />
    }

    return shouldPreventTransactionsBeforeOnboarding && onboardingStatus !== 'complete' ? (
      <BlockedPage BlockPageProps={BlockPageProps} isOnboarded={onboardingStatus} />
    ) : (
      <WrappedComponent {...props} />
    )
  }

  WithBlocker.propTypes = {
    shouldPreventTransactionsBeforeOnboarding: PropTypes.bool.isRequired,
  }

  return connect((state) => ({
    shouldPreventTransactionsBeforeOnboarding: hasFeature(
      state,
      'flagshipPreventTransactionsBeforeOnboarding'
    ),
  }))(WithBlocker)
}

withOnboardingBlocker.propTypes = {
  WrappedComponent: React.Component,
  BlockPageProps: PropTypes.exact({
    image: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.string,
    buttonLabel: PropTypes.string,
  }).isRequired,
}
