/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import {
  FORM_NAMES,
  fetchBusinessDetails,
  fetchOnboardingTasks,
  isTaskCompleted,
  TASK_ICONS,
  TASK_STEPS,
  TASK_TYPES,
  FICA_STATUSES,
} from 'redux/modules/onboarding'
import { isFlagship, toTitleCase } from 'libs/utils'
import { track } from 'libs/analytics'
import classes from 'components/onboarding/onboarding.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import TasksInlineContent from 'components/onboarding/TaskInlineContent'
import {
  BankDetailsPopup,
  BusinessAddressPopup,
  CCDInformationPopup,
  FicaIDUploadPopup,
  FlagshipFeedBackPopup,
} from 'components/onboarding/TaskCardPopups'
import PassportIdPopup from 'components/popups/OnboardingPopups/PassportIdPopup'
import IdDetailsPopup from 'components/popups/OnboardingPopups/IdDetailsPopup'
import IidentifiiPopup from 'components/popups/OnboardingPopups/IidentifiiPopup'
import IidentifiiCompletePopup from 'components/popups/OnboardingPopups/IidentifiiCompletePopup'
import FailedIidentifiiPopup from 'components/popups/OnboardingPopups/FailedIidentifiiPopup'
import KYCPopup from 'components/popups/OnboardingPopups/KYCPopup'

const hasInlineAction = (task) => {
  let taskExpandableSteps = []

  switch (task.taskType) {
    case TASK_TYPES.PAYMENT:
    case TASK_TYPES.APP_SETUP:
    case TASK_TYPES.DATECS_SETUP:
    case TASK_TYPES.FICA:
    case TASK_TYPES.ONLINE_SETUP:
    case TASK_TYPES.BUSINESS_INFO_SETUP:
      taskExpandableSteps = [TASK_STEPS.TODO, TASK_STEPS.IN_PROGRESS]
      break
    case TASK_TYPES.FIRST_TRANSACTION:
      taskExpandableSteps = [TASK_STEPS.TODO, TASK_STEPS.IN_PROGRESS]
      break
    case TASK_TYPES.BANK_DETAILS:
      taskExpandableSteps = [
        TASK_STEPS.TODO,
        TASK_STEPS.IN_PROGRESS,
        TASK_STEPS.FAILED,
        TASK_STEPS.CHANGE_REQUIRED,
      ]
      break
    default:
      taskExpandableSteps = []
      break
  }

  return taskExpandableSteps.indexOf(task.step) >= 0
}

const trackExpandedEvent = (task, expanded) => {
  const event = `Complete your setup - ${toTitleCase(getTaskTitle(task))}`
  if (task.taskType === TASK_TYPES.ONLINE_SETUP) {
    track('portal_complete_your_setup_app_store_link_clicked')
  } else {
    track(event, {
      task,
      expanded,
    })
  }
}

const onExpandClick = (onExpandClickProps) => {
  const { task, handleExpandClick, expanded } = onExpandClickProps
  if (hasInlineAction(task) && handleExpandClick) {
    onExpandClickProps.handleExpandClick(expanded)
    trackExpandedEvent(task, expanded)
  }
}

const getOnboardingPopupProps = ({
  ficaUploadPopupInfo: { showUploadIDPopup, showCCDDetailsPopup, isCitizenshipSelected },
  showAddressPopup,
  launchAddressPopup,
  launchFicaPopup,
  ficaVerificationRequest,
  businessUserDetails,
  disableBusinessAddress,
  businessAddress,
  onSetPassportIdPopupVisible,
  isPassportIdPopupVisible,
  onSetIdDetailsPopupVisible,
  isIdDetailsPopupVisible,
  showIidentifiiPopup,
  iiDentifiiIFrameUrl,
  iiDentifiiIFrameIsLoading,
  launchFicaIidentifiiPopup,
  popupErrorMessages,
  onSetIidentifiiCompletePopupVisible,
  isIidentifiiCompletePopupVisible,
  isFailedIiendtifiiPopupVisible,
  launchFicaIidentifiiFailedPopup,
  isInApp,
  businessUUID,
  hasIidentifiiFica,
  hasKYCFica,
  setKYCPopupVisible,
  isKYCPopupVisible,
  shouldShowBankDetailsPopup,
  shouldShowBankDetailsFlagshipFeedbackPopup,
  openBankDetailsPopup,
}) => ({
  showUploadIDPopup,
  showCCDDetailsPopup,
  isCitizenshipSelected,
  showAddressPopup,
  launchAddressPopup,
  launchFicaPopup,
  ficaVerificationRequest,
  businessUserDetails,
  disableBusinessAddress,
  businessAddress,
  onSetPassportIdPopupVisible,
  isPassportIdPopupVisible,
  onSetIdDetailsPopupVisible,
  isIdDetailsPopupVisible,
  showIidentifiiPopup,
  iiDentifiiIFrameUrl,
  iiDentifiiIFrameIsLoading,
  launchFicaIidentifiiPopup,
  popupErrorMessages,
  onSetIidentifiiCompletePopupVisible,
  isIidentifiiCompletePopupVisible,
  isFailedIiendtifiiPopupVisible,
  launchFicaIidentifiiFailedPopup,
  isInApp,
  businessUUID,
  hasIidentifiiFica,
  hasKYCFica,
  setKYCPopupVisible,
  isKYCPopupVisible,
  shouldShowBankDetailsPopup,
  shouldShowBankDetailsFlagshipFeedbackPopup,
  openBankDetailsPopup,
})

const getTodoTaskClass = ({ ficaVerificationRequest }) => {
  if (ficaVerificationRequest && ficaVerificationRequest.status === FICA_STATUSES.BEING_REVIEWED) {
    return `${classes.taskStepContainer} ${classes.inprogressStep}`
  }
  return `${classes.taskStepContainer} ${classes.todoStep}`
}

const getInProgressTaskClass = ({ ficaVerificationRequest }) => {
  if (ficaVerificationRequest && ficaVerificationRequest.status === FICA_STATUSES.INCOMPLETE) {
    return `${classes.taskStepContainer} ${classes.inprogressStep}`
  }
  return `${classes.taskStepContainer} ${classes.inprogressStep}`
}

const getFailedTodoClass = (forms, task) => {
  if (task.step === TASK_STEPS.FAILED) {
    return `${classes.taskStepContainer} ${classes.failedStep}`
  }
  Sentry.addBreadcrumb({
    category: 'onboarding',
    message: `Onboarding complete your setup page received an unknown TASK STEP: ${task.step}`,
    level: 'warning',
  })
  return `${classes.taskStepContainer} ${classes.failedStep}`
}

const getTaskStepClass = ({ onboardingPopupProps, task, forms }) => {
  switch (task.step) {
    case TASK_STEPS.TODO:
      return getTodoTaskClass(getOnboardingPopupProps(onboardingPopupProps))
    case TASK_STEPS.IN_PROGRESS:
      return getInProgressTaskClass(getOnboardingPopupProps(onboardingPopupProps))
    case TASK_STEPS.COMPLETED:
      return `${classes.taskStepContainer} ${classes.completeStep}`
    case TASK_STEPS.FAILED:
      return getFailedTodoClass(forms, task)
    case TASK_STEPS.CHANGE_REQUIRED:
      return `${classes.taskStepContainer} ${classes.changeRequiredStep}`
    default:
      return getFailedTodoClass(forms, task)
  }
}

const getTaskCardContainerClass = (expanded) => {
  return expanded
    ? `${classes.taskCardContainer} ${classes.expandedCardContainer}`
    : classes.taskCardContainer
}

const getTaskIcon = (step, type) => {
  if (step === TASK_STEPS.COMPLETED) {
    return TASK_ICONS.COMPLETE_TASK_ICON
  }

  switch (type) {
    case TASK_TYPES.PAYMENT:
      return TASK_ICONS.EFT_PAYMENT_TASK_ICON
    case TASK_TYPES.BANK_DETAILS:
      return TASK_ICONS.BANK_DETAILS_TASK_ICON
    case TASK_TYPES.FICA:
      return TASK_ICONS.IDENTITY_CONFIRMATION_TASK_ICON
    case TASK_TYPES.APP_SETUP:
    case TASK_TYPES.DATECS_SETUP:
    case TASK_TYPES.ONLINE_SETUP:
      return TASK_ICONS.POS_DOWNLOAD_TASK_ICON
    case TASK_TYPES.FIRST_TRANSACTION:
      return TASK_ICONS.FIRST_TRANSACTION_TASK_ICON
    case TASK_TYPES.BUSINESS_INFO_SETUP:
      return TASK_ICONS.BANK_DETAILS_TASK_ICON
    default:
      return TASK_ICONS.UNKNOWN_TASK_ICON
  }
}

const renderTaskCardIcon = (task) => {
  const iconContainerClass = isTaskCompleted(task)
    ? `${classes.taskIconContainer}`
    : `${classes.taskIconContainer} ${classes.ongoingTaskIconContainer}`

  const iconClass = isTaskCompleted(task)
    ? `${classes.taskIcon} ${classes.completedTaskIcon}`
    : classes.taskIcon

  return (
    <div className={iconContainerClass}>
      <i className={`icon2-${getTaskIcon(task.step, task.taskType)} ${iconClass}`} />
    </div>
  )
}

const getTaskTitle = (task) => {
  switch (task.taskType) {
    case TASK_TYPES.PAYMENT:
      return 'EFT PAYMENT'
    case TASK_TYPES.BANK_DETAILS:
      return 'BANK DETAILS'
    case TASK_TYPES.FICA:
      return 'CONFIRM IDENTITY'
    case TASK_TYPES.APP_SETUP:
      return 'START TRANSACTING'
    case TASK_TYPES.DATECS_SETUP:
      return 'VIEW SALES HISTORY'
    case TASK_TYPES.ONLINE_SETUP:
      return 'SETUP ONLINE PAYMENTS'
    case TASK_TYPES.BUSINESS_INFO_SETUP:
      return 'BUSINESS DETAILS'
    default:
      return 'SOMETHING WENT WRONG'
  }
}

const getTaskStepLabel = ({ onboardingPopupProps, task }) => {
  const { ficaVerificationRequest } = getOnboardingPopupProps(onboardingPopupProps)

  switch (task.step) {
    case TASK_STEPS.TODO:
      if (
        ficaVerificationRequest &&
        ficaVerificationRequest.status === FICA_STATUSES.BEING_REVIEWED
      ) {
        return 'in progress'
      }
      return 'to-do'
    case TASK_STEPS.IN_PROGRESS:
      return 'in progress'
    case TASK_STEPS.CHANGE_REQUIRED:
      return 'try again'
    default:
      return task.step.replace('_', ' ')
  }
}

const renderTaskCardButton = ({ task, buttonTitle, onButtonClick }) => {
  if (task.step === TASK_STEPS.COMPLETED) {
    return null
  }
  const taskCardClass = `blueBackground ${classes.taskCardButton}`
  return (
    <div style={{ minWidth: 150 }}>
      <FlatButton
        style={{ width: '100%' }}
        type='button'
        className={taskCardClass}
        label={buttonTitle}
        onClick={onButtonClick}
        disabled={task.step === TASK_STEPS.IN_PROGRESS}
      />
    </div>
  )
}

renderTaskCardButton.propTypes = {
  task: PropTypes.object,
  forms: PropTypes.object,
  buttonTitle: PropTypes.string,
  onButtonClick: PropTypes.func,
}

const renderFicaTaskCardButtonOrExpansionIcon = (
  hasDigitalFica,
  ficaVerificationRequest,
  ficaButtonProps,
  ccdButtonProps
) => {
  if (hasDigitalFica && ficaVerificationRequest) {
    if (
      ficaVerificationRequest.status === FICA_STATUSES.MANUAL_DECISION_REQUIRED ||
      ficaVerificationRequest.status === FICA_STATUSES.IN_PROGRESS ||
      ficaVerificationRequest.status === FICA_STATUSES.BEING_REVIEWED ||
      ficaVerificationRequest.status === FICA_STATUSES.INCOMPLETE ||
      ficaVerificationRequest.status === FICA_STATUSES.FAILED
    ) {
      return undefined
    }
    return renderTaskCardButton(ficaButtonProps)
  }
  return renderTaskCardButton(ccdButtonProps)
}

const renderBankTaskCardButtonOrExpansionIcon = (task, bankButtonProps) => {
  return task.step === TASK_STEPS.TODO ||
    task.step === TASK_STEPS.CHANGE_REQUIRED ||
    task.step === TASK_STEPS.FAILED
    ? renderTaskCardButton(bankButtonProps)
    : undefined
}

const getTaskCardButtonProps = (
  task,
  forms,
  buttonTitle,
  {
    launchFicaPopup,
    launchAddressPopup,
    onSetPassportIdPopupVisible,
    isInApp,
    hasIidentifiiFica,
    businessUUID,
    hasKYCFica,
    setKYCPopupVisible,
    openBankDetailsPopup,
  }
) => {
  if (buttonTitle === 'Scan') {
    const isIidentifiiAvailable = !isInApp && hasIidentifiiFica
    return {
      task,
      forms,
      buttonTitle,
      onButtonClick: () => {
        if (isIidentifiiAvailable) {
          track('portal_fica_iidentifii_click_button_scan_upload_id_start', {
            business_uuid: businessUUID,
          })
          onSetPassportIdPopupVisible(true)
        } else if (hasKYCFica) {
          track('portal_KYC_start_button_pressed', {
            business_uuid: businessUUID,
          })
          setKYCPopupVisible(true)
        } else {
          track('portal_complete_your_setup_confirm_identity')
          launchFicaPopup({ showUploadIDPopup: true, isCitizenshipSelected: false })
        }
      },
    }
  }
  if (buttonTitle === 'Learn more') {
    return {
      task,
      forms,
      buttonTitle,
      onButtonClick: () => launchFicaPopup({ showCCDDetailsPopup: true }),
    }
  }
  if (buttonTitle === 'Add' && task.taskType === TASK_TYPES.BANK_DETAILS) {
    return {
      task,
      forms,
      buttonTitle,
      onButtonClick: () => openBankDetailsPopup(),
    }
  }
  return {
    task,
    forms,
    buttonTitle,
    onButtonClick: () => {
      openAddressPopup(launchAddressPopup)
    },
  }
}

function openAddressPopup(launchAddressPopup) {
  launchAddressPopup(true)
  track('portal_complete_your_setup_business_address ')
}

const renderTaskCardButtonOrExpansionIcon = ({
  forms,
  onboardingPopupProps,
  task,
  hasDigitalFica,
}) => {
  const { ficaVerificationRequest } = getOnboardingPopupProps(onboardingPopupProps)

  switch (task.taskType) {
    case TASK_TYPES.BUSINESS_INFO_SETUP:
      return renderTaskCardButton(
        getTaskCardButtonProps(task, forms, 'Add', getOnboardingPopupProps(onboardingPopupProps))
      )
    case TASK_TYPES.FICA:
      return renderFicaTaskCardButtonOrExpansionIcon(
        hasDigitalFica,
        ficaVerificationRequest,
        getTaskCardButtonProps(task, forms, 'Scan', getOnboardingPopupProps(onboardingPopupProps)),
        getTaskCardButtonProps(
          task,
          forms,
          'Learn more',
          getOnboardingPopupProps(onboardingPopupProps)
        )
      )
    case TASK_TYPES.BANK_DETAILS:
      return renderBankTaskCardButtonOrExpansionIcon(
        task,
        getTaskCardButtonProps(task, forms, 'Add', getOnboardingPopupProps(onboardingPopupProps))
      )
    default:
      return undefined
  }
}

const renderTaskCardInlineContent = ({ expanded, task, localisation, dispatch, forms }) => {
  return expanded ? (
    <TasksInlineContent
      task={task}
      localisation={localisation}
      // eslint-disable-next-line react/forbid-component-props
      dispatch={dispatch}
      forms={forms}
    />
  ) : undefined
}

renderTaskCardInlineContent.propTypes = {
  expanded: PropTypes.bool,
  task: PropTypes.object,
  localisation: PropTypes.object,
  dispatch: PropTypes.func,
  forms: PropTypes.object,
}

const renderTaskCardPopup = ({ onboardingPopupProps, dispatch, forms }, provinces) => {
  const {
    showAddressPopup,
    launchAddressPopup,
    disableBusinessAddress,
    showUploadIDPopup,
    showCCDDetailsPopup,
    launchFicaPopup,
    ficaVerificationRequest,
    businessAddress,
    onSetPassportIdPopupVisible,
    isPassportIdPopupVisible,
    onSetIdDetailsPopupVisible,
    isIdDetailsPopupVisible,
    showIidentifiiPopup,
    iiDentifiiIFrameUrl,
    iiDentifiiIFrameIsLoading,
    launchFicaIidentifiiPopup,
    popupErrorMessages,
    onSetIidentifiiCompletePopupVisible,
    isIidentifiiCompletePopupVisible,
    isCitizenshipSelected,
    failedIidentifiiPopup,
    launchFicaIidentifiiFailedPopup,
    isKYCPopupVisible,
    setKYCPopupVisible,
    shouldShowBankDetailsPopup,
    shouldShowBankDetailsFlagshipFeedbackPopup,
  } = getOnboardingPopupProps(onboardingPopupProps)

  if (shouldShowBankDetailsFlagshipFeedbackPopup) {
    return <FlagshipFeedBackPopup isSuccess />
  }
  if (shouldShowBankDetailsPopup) {
    return <BankDetailsPopup />
  }
  if (showUploadIDPopup) {
    return (
      <FicaIDUploadPopup
        popupHeaderTitle='Upload your identity document'
        onClosePopup={() => launchFicaPopup({ showUploadIDPopup: false })}
        showUploadIDPopup={showUploadIDPopup}
        isCitizenshipSelected={isCitizenshipSelected}
        // eslint-disable-next-line react/forbid-component-props
        dispatch={dispatch}
        forms={forms}
        ficaVerificationRequest={ficaVerificationRequest}
        isIDUploadPopupRendered
        onboardingPopupProps={onboardingPopupProps}
        dismissIDUploadPopup={() => {
          track('portal_upload_id_submit_button_clicked')
          dispatch(fetchOnboardingTasks())
          launchFicaPopup({ showUploadIDPopup: false })
        }}
      />
    )
  }
  if (showCCDDetailsPopup) {
    return (
      <CCDInformationPopup
        popupHeaderTitle='Prepare your ID for delivery'
        onClosePopup={() => launchFicaPopup({ showCCDDetailsPopup: false })}
        showCCDDetailsPopup={showCCDDetailsPopup}
        dismissCCDDetailsPopup={() => launchFicaPopup({ showCCDDetailsPopup: false })}
      />
    )
  }
  if (showAddressPopup) {
    return (
      <BusinessAddressPopup
        popupHeaderTitle={isFlagship ? undefined : 'Complete business details'}
        onClosePopup={() => launchAddressPopup(false)}
        showAddressPopup={showAddressPopup}
        // eslint-disable-next-line react/forbid-component-props
        dispatch={dispatch}
        dismissAddressPopup={() => {
          const errorMessage = forms.getIn([FORM_NAMES.BUSINESS_ADDRESS, 'errorMessage'])
          if (!errorMessage) {
            dispatch(fetchBusinessDetails())
            dispatch(fetchOnboardingTasks())
            launchAddressPopup(false)
          }
        }}
        disableBusinessAddress={disableBusinessAddress}
        businessAddress={businessAddress}
        forms={forms}
        provinces={provinces}
      />
    )
  }
  if (isPassportIdPopupVisible) {
    return (
      <PassportIdPopup
        headerTitle='Which identity document are you scanning?'
        buttonLabel='Cancel'
        onSetPassportIdPopupVisible={onSetPassportIdPopupVisible}
        isPassportIdPopupVisible={isPassportIdPopupVisible}
      />
    )
  }
  const onKYCError = () => {
    track('portal_KYC_error')
    setKYCPopupVisible(false)
    launchFicaPopup({ showUploadIDPopup: true, isCitizenshipSelected: false })
  }
  if (isKYCPopupVisible) {
    return (
      <KYCPopup
        isKYCPopupVisible={isKYCPopupVisible}
        setKYCPopupVisible={setKYCPopupVisible}
        onError={onKYCError}
      />
    )
  }
  if (isIdDetailsPopupVisible) {
    return (
      <IdDetailsPopup
        headerTitle='Please enter your details as they are on your identity document'
        buttonLabel='Next'
        onSetIdDetailsPopupVisible={onSetIdDetailsPopupVisible}
        isIdDetailsPopupVisible={isIdDetailsPopupVisible}
        popupErrorMessage={popupErrorMessages.iidentifiiErrorMessage}
      />
    )
  }
  if (showIidentifiiPopup) {
    return (
      <IidentifiiPopup
        popupHeaderTitle='Please scan your document'
        buttonLabel='Close'
        iframeTitle='Iidentifii Yoco id verification'
        iiDentifiiIFrameUrl={iiDentifiiIFrameUrl}
        iiDentifiiIFrameIsLoading={iiDentifiiIFrameIsLoading}
        showIidentifiiPopup={showIidentifiiPopup}
        setPopupVisible={launchFicaIidentifiiPopup}
        popupErrorMessage={popupErrorMessages.iidentifiiErrorMessage}
      />
    )
  }
  if (isIidentifiiCompletePopupVisible) {
    return (
      <IidentifiiCompletePopup
        headerTitle='Your identity has been submitted'
        buttonLabel='Close'
        onSetIidentifiiCompletePopupVisible={onSetIidentifiiCompletePopupVisible}
        isIidentifiiCompletePopupVisible={isIidentifiiCompletePopupVisible}
        forms={forms}
      />
    )
  }
  if (failedIidentifiiPopup?.isVisible) {
    return (
      <FailedIidentifiiPopup
        showFailedIidentifiiPopup={failedIidentifiiPopup?.isVisible}
        launchFicaIidentifiiFailedPopup={launchFicaIidentifiiFailedPopup}
        launchFicaPopup={launchFicaPopup}
        buttonLabel='Upload ID document'
        errorType={failedIidentifiiPopup?.errorType}
      />
    )
  }
  return null
}

renderTaskCardPopup.propTypes = {
  onboardingPopupProps: PropTypes.object,
  dispatch: PropTypes.func,
  forms: PropTypes.object,
}

const renderFICATaskCardInlineOrPopup = (
  hasDigitalFica,
  ficaVerificationRequest,
  inlineTaskCardProps
) => {
  if (hasDigitalFica && ficaVerificationRequest) {
    if (
      ficaVerificationRequest.status === FICA_STATUSES.MANUAL_DECISION_REQUIRED ||
      ficaVerificationRequest.status === FICA_STATUSES.IN_PROGRESS ||
      ficaVerificationRequest.status === FICA_STATUSES.BEING_REVIEWED ||
      ficaVerificationRequest.status === FICA_STATUSES.INCOMPLETE ||
      ficaVerificationRequest.status === FICA_STATUSES.FAILED
    ) {
      return <div>{renderTaskCardInlineContent(inlineTaskCardProps)}</div>
    }
  }
}

const renderDefaultTaskCardInline = (inlineTaskCardProps) => (
  <div>{renderTaskCardInlineContent(inlineTaskCardProps)}</div>
)

const renderTaskCardPopupOrInlineContent = ({
  forms,
  onboardingPopupProps,
  dispatch,
  hasDigitalFica,
  expanded,
  task,
  localisation,
  provinces,
}) => {
  const { ficaVerificationRequest } = getOnboardingPopupProps(onboardingPopupProps)
  const taskCardPopupProps = { onboardingPopupProps, dispatch, forms }
  const inlineTaskCardProps = {
    expanded,
    task,
    localisation,
    dispatch,
    forms,
  }

  switch (task.taskType) {
    case TASK_TYPES.BUSINESS_INFO_SETUP:
      break
    case TASK_TYPES.BANK_DETAILS:
      return undefined
    case TASK_TYPES.FICA:
      return renderFICATaskCardInlineOrPopup(
        hasDigitalFica,
        ficaVerificationRequest,
        inlineTaskCardProps
      )
    default:
      return renderDefaultTaskCardInline(inlineTaskCardProps)
  }
  return renderTaskCardPopup(taskCardPopupProps, provinces)
}

export {
  onExpandClick,
  getTaskStepClass,
  getTaskCardContainerClass,
  renderTaskCardIcon,
  getTaskTitle,
  getTaskStepLabel,
  renderTaskCardButtonOrExpansionIcon,
  renderTaskCardPopupOrInlineContent,
  getOnboardingPopupProps,
  openAddressPopup,
}
