import { fieldUpdated } from 'redux/modules/forms'

const removeNonNumericCharacters = (inputEvent) => inputEvent.target.value.replace(/[^0-9]+/g, '')

export function sanitizeAccountNumber(formName, event, dispatch) {
  const accountNumber = removeNonNumericCharacters(event)
  dispatch(fieldUpdated(formName, 'accountNumber', accountNumber))
}

export function sanitizeIDNumber(formName, fieldName, event, dispatch) {
  const accountNumber = removeNonNumericCharacters(event)
  dispatch(fieldUpdated(formName, fieldName, accountNumber))
}
