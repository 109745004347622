import { withOnboardingBlocker } from 'libs/withOnboardingBlocker'

import { InvoicesList } from './components/Invoices'
import { withOnboarding } from './components/Onboarding'
import InvoiceImage from '../../assets/images/Invoices-64px.svg'

export default withOnboardingBlocker(
  withOnboarding(InvoicesList, {
    // General Onboarding have to be completed in order to show this page
    isOnboardingComplete: true,

    // At least one customer have to be added in order to show this page
    isAnyCustomerCreated: true,

    // At least one invoice have to be created in order to show this page
    isAnyInvoiceCreated: true,
  }),
  {
    image: InvoiceImage,
    header: 'Invoices',
    body:
      'Create and send professional click-to-pay invoices, and get paid faster with online payments',
    buttonLabel: 'Create an invoice',
  }
)
