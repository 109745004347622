import React from 'react'
import FlatButton from 'components/buttons/FlatButton'
import styles from './CapitalReApplyBlock.module.scss'

interface Props {
  onApplyForReAdvance: () => void
}

const CapitalReApplyBlock: React.FunctionComponent<Props> = ({ onApplyForReAdvance }) => {
  return (
    <div className={styles.container} data-testid='CapitalReApplyBlock'>
      <div>You qualify to apply for another cash advance.</div>
      <FlatButton className={styles.reApplyButton} label='Re-apply' onClick={onApplyForReAdvance} />
    </div>
  )
}

export default CapitalReApplyBlock
