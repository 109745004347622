import React from 'react'
import PropTypes from 'prop-types'

import { track } from 'libs/analytics'
import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'

import Content from './components/Content'

function PassportIdPopup({
  headerTitle,
  buttonLabel,
  onSetPassportIdPopupVisible,
  isPassportIdPopupVisible,
}) {
  const closePopup = () => {
    track('portal_fica_iidentifii_click_button_cancel')
    onSetPassportIdPopupVisible(false)
  }
  return (
    <PopupContentLayout
      headerTitle={headerTitle}
      buttonLabel={buttonLabel}
      onClosePopup={closePopup}
      showing={isPassportIdPopupVisible}
      popupBodyContent={<Content />}
      onFooterButtonClick={closePopup}
      hideFooterContent
    />
  )
}

PassportIdPopup.propTypes = {
  headerTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  onSetPassportIdPopupVisible: PropTypes.func,
  isPassportIdPopupVisible: PropTypes.bool,
}

export default PassportIdPopup
