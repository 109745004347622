/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { businessUUID } from 'redux/modules/session/selectors'
import { wasRoutedFromApp } from 'redux/modules/routes'
import { getENV } from 'libs/utils'

function ShopIFrame({ cartId, businessUUID, isInApp }) {
  const hostname = `${getENV('HELLO_YOCO_URL')}`
  const basePath = `${hostname}shop/business/${businessUUID}/`
  const path = cartId ? `${basePath}${cartId}/` : basePath
  const iframeSrc = `${path}?embed=true&storefront=portal&in-app=${isInApp}`

  const iframe = {
    __html: `<iframe src='${iframeSrc}' id='shopIframe' allow='fullscreen; geolocation' style='width:100%;height:100%; border: none; position:absolute; top:0px; left:0px; right:0px; bottom:0px;' />`,
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={iframe} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessUUID: businessUUID(state),
  isInApp: wasRoutedFromApp(state),
})

ShopIFrame.propTypes = {
  cartId: PropTypes.string,
  businessUUID: PropTypes.string,
  isInApp: PropTypes.bool,
}

export default connect(mapStateToProps, null)(ShopIFrame)
