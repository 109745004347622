import React from 'react'

import FlatButton from 'components/buttons/FlatButton'
import errorImage from 'assets/images/error.svg'

import classes from './CapitalErrorBlock.module.scss'

interface Props {
  error: string
  title: string
  retryButtonText?: string
  onRetryPress?: () => void
}

const CapitalErrorBlock: React.FunctionComponent<Props> = ({
  error,
  title,
  retryButtonText,
  onRetryPress,
}) => {
  return (
    <div className={classes.container} data-testid='CapitalErrorBlock'>
      <img className={classes.image} src={errorImage} alt='error' />
      <h1>{title}</h1>
      <h4>{error}</h4>
      {!!retryButtonText && (
        <FlatButton label={retryButtonText} onClick={onRetryPress} className='blueBackground' />
      )}
    </div>
  )
}

export default CapitalErrorBlock
