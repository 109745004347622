import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as forms from 'libs/forms'
import { callInvoicesJSONAPI } from 'libs/api'
import { track } from 'libs/analytics'
import { makeTestID } from 'libs/utils'
import { initializeForm, showError, submitResult, showSuccess } from 'redux/modules/forms'
import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'
import LoadingView from 'components/loaders/LoadingView'

import { FormNames } from './constants'
import { normalizeInitialFormData, normalizeFormData } from './utils'
import renderFormFields from './renderFormFields'

function UpdateInvoicesSettings({ dispatch }) {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    setLoading(true)
    setError(undefined)

    callInvoicesJSONAPI('business', 'GET')
      .then((response) => {
        dispatch(initializeForm(FormNames.Update, normalizeInitialFormData(response.data)))
      })
      .catch(() => setError('Failed to fetch invoice settings'))
      .finally(() => setLoading(false))
  }, [dispatch])

  if (loading) {
    return <LoadingView />
  }

  if (error) {
    return <Alert messageType='danger' message={error} closable={false} />
  }

  return (
    <forms.Form
      name={FormNames.Update}
      noWrap
      onSubmit={(data) => {
        track('invoices_set_up_save_button_clicked', {
          name: 'invoices_set_up_save_button_clicked',
        })

        callInvoicesJSONAPI('business', 'PATCH', normalizeFormData(data))
          .then((response) => {
            dispatch(submitResult(FormNames.Update, response.data))
            dispatch(showSuccess(FormNames.Update, { message: 'Invoice settings saved!' }))
          })
          .catch(() => {
            dispatch(submitResult(FormNames.Update, {}))
            dispatch(showError(FormNames.Update, 'Failed to save invoice settings'))
          })
      }}
      scrollToTop
      showLoader
      showSuccess
      showError
    >
      {renderFormFields(false)}
      <FlatButton
        label='Save'
        type='submit'
        className='blueBackground'
        ariaLabel={makeTestID('invoices', 'settings', 'save')}
        testID={makeTestID('invoices', 'settings', 'save')}
      />
    </forms.Form>
  )
}

UpdateInvoicesSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default connect()(UpdateInvoicesSettings)
