import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Map } from 'immutable'

import * as forms from 'libs/forms'
import { track } from 'libs/analytics'
import * as Onboarding from 'redux/modules/onboarding'
import * as OnboardingSelector from 'redux/modules/onboarding/selectors'
import { businessInfoSelector } from 'redux/modules/session/selectors'
import UserAlert from 'components/notifications/UserAlert'
import LoadingView from 'components/loaders/LoadingView'
import GenericUploadComponent from 'components/files/GenericUploadComponent'
import { BUSINESS_TYPES, FICA_STATUSES, FORM_NAMES } from 'redux/modules/onboarding'
import { If } from 'libs/formats'

import classes from './DigitalFicaUpload.module.scss'

const southAfricanIDTag = 'southAfricanID'
const southAfricanPassportTag = 'southAfricanPassport'
const passportTag = 'passport'

class DigitalFicaComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchFICADetails()
    this.props.fetchVettingInfo()
  }

  /* ************* CALLBACK METHODS ************ */
  handleUploadError(request, message) {
    if (request.status !== 200) {
      this.setState({
        errorDisplayed: true,
        errorMessage: message,
      })
    }
  }

  onFileUploadError = (error) => {
    this.handleUploadError(
      error,
      `There was a network error while trying to upload your FICA documents. Please check your internet connection`
    )
  }

  onFileUploadSuccess = (uploadResponse) => {
    let { data } = uploadResponse
    const comment = `FICA identityDocument uploaded on the ${moment().format('D MMM YYYY')}`
    const target = this.props.ficaVerification.uuid
    const file = {
      path: data.filename,
      url: data.url,
      size: data.fileSize,
      type: data.contentType,
    }

    // this is to keep track of the UUID's of each type of file upload type
    const storedFileData = { identityDocument: data.uuid }
    this.props.attachFICAStoredFile(storedFileData)

    data = {
      comment,
      target,
      files: [file],
    }
    this.props.submitFICAComments(data)
  }

  onSubmitDocuments = () => {
    if (this.props.uploadedIdentityDocument) {
      this.props.submitFICA()
      this.props.onFicaUploadFetchOnboardingTasks()
      track('upload_id_submit_button_clicked')
    } else {
      this.setState({
        errorDisplayed: true,
        errorMessage: `There seems to be a problem submitting your Identity document. (No identity document attached)`,
      })
    }
  }

  onDisplayError = () => {
    if (this.state.errorDisplayed) {
      return (
        <UserAlert
          userAlert={{
            htmlMessage: this.state.errorMessage,
            alertType: 'warning',
          }}
          dismissUserAlert={() => this.setState({ errorDisplayed: false })}
          style={{ top: '67px' }}
        />
      )
    }
    return null
  }

  /* ************* MISC METHODS ************ */
  getFICAMessage() {
    let msg = ''
    if (this.props.isFetchingFICA) {
      msg = 'Fetching FICA Details…'
    } else if (this.props.isSubmittingFICAComments || this.props.isAttachingFICAFile) {
      msg = 'Submitting Document Info…'
    } else if (this.props.isSubmittingFICA) {
      msg = 'Submitting FICA Details…'
    }
    return msg
  }

  isSoleProprietor(businessType) {
    return (
      businessType &&
      (businessType === BUSINESS_TYPES.SOLE_PROPRIETOR ||
        businessType === BUSINESS_TYPES.TRUST ||
        businessType === BUSINESS_TYPES.NOT_REGISTERED ||
        businessType === BUSINESS_TYPES.NPO)
    )
  }

  isFICAProcessing() {
    return (
      this.props.isSubmittingFICA ||
      this.props.isFetchingFICA ||
      this.props.isAttachingFICAFile ||
      this.props.isSubmittingFICAComments
    )
  }

  /* ************* RENDER METHODS ************ */

  renderNewBlock() {
    const errorMessage = this.onDisplayError()
    return (
      <div style={{ marginBottom: '-30px' }}>
        {this.props.ficaErrorMessage ? <p>{this.props.ficaErrorMessage}</p> : null}
        {errorMessage}
        {this.renderIDBlock()}
        {!this.props.isIDUploadPopupRendered ? this.renderSubmitFICAButton() : null}
      </div>
    )
  }

  renderIDBlock() {
    const targetUUID = this.props.ficaVerification ? this.props.ficaVerification.uuid : undefined
    const uploadedDocument = this.props.uploadedIdentityDocument
    const submitDisabled = this.props.ficaVerification.status !== FICA_STATUSES.REQUESTED
    const formData = (
      this.props.forms.getIn([FORM_NAMES.FICA_VERIFICATION, 'data']) || Map()
    ).toJS()
    const { southAfricanID, southAfricanPassport, passport } = formData
    const documentType = {
      southAfricanID,
      southAfricanPassport,
      passport,
    }
    const selectedDocumentType = Object.keys(documentType).filter((doc) => {
      if (documentType[doc]) {
        return doc
      }
      return null
    })

    return (
      <div>
        <div className={!this.props.isIDUploadPopupRendered ? classes.header : undefined}>
          <If condition={!this.props.isIDUploadPopupRendered}>
            <div>
              <p className={classes.selectYourIdentity}>Select your identity document:</p>
              <div>
                {this.renderRadioButtonFormField(
                  southAfricanIDTag,
                  [southAfricanPassportTag, passportTag],
                  'South African ID'
                )}
                {this.renderPassportOption()}
              </div>
              <p className={classes.helperTextCopy}>
                Make sure to upload a<strong> clear photo </strong>
                of your ID or passport. The file format should be a<strong> JPG </strong>
                or
                <strong> PNG</strong>.
              </p>
            </div>
          </If>
          <div className={!this.props.isIDUploadPopupRendered ? classes.mainContainer : undefined}>
            <GenericUploadComponent
              acceptedFileRE='image/*'
              acceptedFileTypes={['image/jpeg', 'image/png']}
              acceptedFileTypeError='Only JPEG or PNG files are supported for Identity document upload.'
              uploadedDocument={uploadedDocument}
              grouping='fica-id'
              fileUploadID='digitalFicaUploadID'
              disabled={submitDisabled}
              targetUUID={targetUUID}
              documentType={selectedDocumentType}
              completeCallback={this.onFileUploadSuccess}
              onFileUploadError={this.onFileUploadError}
            />
          </div>
        </div>
      </div>
    )
  }

  renderRadioButtonFormField(name, linkedFields, label) {
    return (
      <forms.RadioButtonField
        name={name}
        label={label}
        isFullWidth
        linkedFields={linkedFields}
        onFocus={(event) => this.onFocus(event)}
        className={`${classes.radioButtonContainer}}`}
      />
    )
  }

  renderPassportOption() {
    const businessType = this.props.businessInfo.get('businessType')
    if (this.isSoleProprietor(businessType)) {
      return this.renderRadioButtonFormField(
        southAfricanPassportTag,
        [southAfricanIDTag],
        'Passport'
      )
    }
    return this.renderRadioButtonFormField(passportTag, [southAfricanIDTag], 'Passport')
  }

  renderSubmitFICAButton() {
    const buttonEnabled = this.props.uploadedIdentityDocument && !this.isFICAProcessing()
    return (
      <div className={classes.inlineActionButtonContainer}>
        <button type='submit' className={classes.inlineActionButton} disabled={!buttonEnabled}>
          Submit
        </button>
      </div>
    )
  }

  render() {
    if (this.isFICAProcessing()) {
      return (
        <div style={{ flex: '1' }}>
          <LoadingView message={this.getFICAMessage()} />
        </div>
      )
    }

    return (
      <forms.Form
        name={FORM_NAMES.FICA_VERIFICATION}
        persistData
        onSubmit={this.onSubmitDocuments}
        showLoader={false}
      >
        {this.renderNewBlock()}
      </forms.Form>
    )
  }
}

DigitalFicaComponent.propTypes = {
  ficaVerification: PropTypes.object.isRequired,
  forms: PropTypes.object,
  businessInfo: PropTypes.object,
  isSubmittingFICAComments: PropTypes.bool,
  isAttachingFICAFile: PropTypes.bool,
  isSubmittingFICA: PropTypes.bool,
  isFetchingFICA: PropTypes.bool,
  ficaErrorMessage: PropTypes.string,
  uploadedIdentityDocument: PropTypes.object,
  fetchFICADetails: PropTypes.func.isRequired,
  fetchVettingInfo: PropTypes.func,
  submitFICA: PropTypes.func.isRequired,
  attachFICAStoredFile: PropTypes.func.isRequired,
  submitFICAComments: PropTypes.func.isRequired,
  isIDUploadPopupRendered: PropTypes.bool,
  onFicaUploadFetchOnboardingTasks: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  attachFICAStoredFile: (storedFileData) =>
    dispatch(Onboarding.attachFICAStoredFile(storedFileData)),
  submitFICAComments: (data) => dispatch(Onboarding.submitFICAComments(data)),
  submitFICA: () => dispatch(Onboarding.submitFICADetails()),
  onFicaUploadFetchOnboardingTasks: () => dispatch(Onboarding.fetchOnboardingTasks()),
  fetchFICADetails: () => dispatch(Onboarding.fetchLatestFICADetails()),
  fetchVettingInfo: () => dispatch(Onboarding.fetchBusinessVetting()),
})

const mapStateToProps = (state) => {
  return {
    isSubmittingFICAComments: OnboardingSelector.isSubmittingFICACommentsSelector(state),
    isAttachingFICAFile: OnboardingSelector.isAttachingFICAFileSelector(state),
    isSubmittingFICA: OnboardingSelector.isSubmittingFICASelector(state),
    ficaErrorMessage: OnboardingSelector.errorMessageFICASelector(state),
    isFetchingFICA: OnboardingSelector.isFetchingFICASelector(state),
    uploadedIdentityDocument: OnboardingSelector.uploadedIdentityDocumentSelector(state),
    businessInfo: businessInfoSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalFicaComponent)
