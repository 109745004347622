import { TASK_TYPES } from 'redux/modules/onboarding'

/** Hide the app_setup, datecs_setup, and online_setup task, if you're routing from the app */
export const filterTasks = ({ tasks, isInApp, hasPersonalisedAppHomeScreen }) =>
  tasks.filter(
    (task) =>
      !(isInApp && task.taskType === TASK_TYPES.APP_SETUP) &&
      !(isInApp && task.taskType === TASK_TYPES.DATECS_SETUP) &&
      !(isInApp && task.taskType === TASK_TYPES.ONLINE_SETUP) &&
      !(isInApp && hasPersonalisedAppHomeScreen && task.taskType === TASK_TYPES.PAYMENT)
  )

export default filterTasks
