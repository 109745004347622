import { Map } from 'immutable'
import { createSelector } from 'reselect'
import { BANK_REQUEST_STATUSES } from './index'

const formsNode = (state) => state.forms || Map()
const onboardingNode = (state) => state.onboarding

export const selectBankAccount = createSelector(
  onboardingNode,
  (onboardingData) => onboardingData.getIn(['bank', 'bankAccount']) || Map()
)

export const selectBankAccountUpdateRequest = createSelector(
  onboardingNode,
  (onboardingData) => onboardingData.getIn(['bank', 'bankAccountUpdateRequest']) || Map()
)

export const selectBankAccountUpdateRequestState = createSelector(
  selectBankAccountUpdateRequest,
  (bankAccountUpdateRequest) => bankAccountUpdateRequest.get('updateState')
)

export const selectBankAccountUpdateError = createSelector(onboardingNode, (onboardingData) =>
  onboardingData.getIn(['bank', 'bankAccountUpdateError'])
)

export const selectShouldShowBankDetailsPopup = createSelector(onboardingNode, (onboardingData) =>
  onboardingData.getIn(['bank', 'showBankDetailsPopup'])
)

export const selectShouldShowBankDetailsFlagshipFeedbackPopup = createSelector(
  onboardingNode,
  (onboardingData) => onboardingData.getIn(['bank', 'showBankDetailsFlagshipFeedbackPopup'])
)

export const selectCanEditBankAccount = createSelector(
  selectBankAccountUpdateRequestState,
  (bankAccountUpdateRequestState) => {
    const editableStates = [
      BANK_REQUEST_STATUSES.REQUESTED,
      BANK_REQUEST_STATUSES.CHANGE_REQUIRED,
      BANK_REQUEST_STATUSES.INFORMATION_REQUIRED,
    ]

    return !bankAccountUpdateRequestState || editableStates.includes(bankAccountUpdateRequestState)
  }
)

export const selectIsBankStatementRequired = createSelector(
  selectBankAccountUpdateRequest,
  formsNode,
  (bankAccountUpdateRequest, formData) => {
    if (bankAccountUpdateRequest.get('isBankStatementRequired')) {
      return true
    }

    // TODO: This should pull through from the backend

    const selectedBank = formData.getIn(['bankDetails', 'data', 'bank'])
    switch (selectedBank) {
      case 'Old Mutual':
      case 'Mercantile':
      case 'Habib Bank':
      case 'HBZ Bank Ltd':
      case 'Citibank SA':
      case 'SA Post Bank':
      case 'Bidvest Bank':
      case 'El Baraka':
      case 'Bank of Athens':
        return true
      default:
        return false
    }
  }
)

export const bankUpdateRequestUploadedDocuments = createSelector(
  onboardingNode,
  (onboardingData) => {
    const documents = onboardingData.getIn(['bank', 'uploadedDocuments'])
    if (documents) {
      const bankStatement = documents.find((file) => file.grouping === 'ahv_bank_statement')
      const loaDocument = documents.find((file) => file.grouping === 'ahv_loa')
      const boardResolutionDocument = documents.find(
        (file) => file.grouping === 'ahv_resolution_letter'
      )
      const identityDocument = documents.find((file) => file.grouping === 'ahv_identity_document')
      return {
        bankStatement,
        loaDocument,
        boardResolutionDocument,
        identityDocument,
      }
    }
    return {
      bankStatement: undefined,
      loaDocument: undefined,
      boardResolutionDocument: undefined,
      identityDocument: undefined,
    }
  }
)

// FIXME: This should be moved.  It's got nothing to do with bank accounts.
export const popupErrorMessages = createSelector(onboardingNode, (onboardingData) => {
  const ficaErrorMessage = onboardingData.getIn(['fica', 'ficaErrorMessage'])
  const failedAddressSubmitError = onboardingData.getIn([
    'businessAddress',
    'failedAddressSubmitError',
  ])
  const vettingInfoFetchError = onboardingData.getIn(['businessVetting', 'vettingInfoFetchError'])
  const failedUserInfoFetchError = onboardingData.getIn([
    'businessVetting',
    'failedUserInfoFetchError',
  ])
  const scanIdPassportError = onboardingData.getIn([
    'fica',
    'iidentifiiPopup',
    'iidentifiiErrorMessages',
    'scanIdPassportError',
  ])
  const iidentifiiCompleteError = onboardingData.getIn([
    'fica',
    'iidentifiiPopup',
    'iidentifiiErrorMessages',
    'iidentifiiCompleteError',
  ])

  return {
    iidentifiiErrorMessage: scanIdPassportError || iidentifiiCompleteError,
    ficaErrorMessage,
    failedAddressSubmitError,
    vettingInfoFetchError,
    failedUserInfoFetchError,
  }
})
