import ROLES from 'libs/roles'
import CongratulationsPage from 'containers/onboarding/CongratulationsPage'

import DashboardPage from './DashboardPage'

export default function getRoutes() {
  return {
    path: 'dashboard',
    text: 'Dashboard',
    icon: 'dashboard',
    requiresOneRole: [ROLES.VIEW_FINANCIAL],
    redirectOnAuthFail: '/sales/history',
    // The route below that is the same path as this parent does not load, hence we include the
    // component here.
    component: DashboardPage,
    routes: [
      {
        path: '',
        text: 'Dashboard',
        component: DashboardPage,
        hideInMenu: true,
      },
      {
        path: 'complete-setup',
        text: 'Onboarding Complete',
        component: CongratulationsPage,
        hideInMenu: true,
      },
    ],
  }
}
