import * as React from 'react'
import propTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import successIcon from 'assets/images/success-green-icon@2x.png'
import { track } from 'libs/analytics'
import { isFlagship } from 'libs/utils'
import { getQueryParamByName } from 'libs/queryParams'

import classes from './success.module.scss'

function FlagshipBankDetailsSuccess({ inApp }) {
  const notifyReactNativeWebView = () => {
    if (window.ReactNativeWebView) {
      track('account_bank_details_submitted_close_button_clicked', { inApp, flagship: isFlagship })
      window.ReactNativeWebView.postMessage('success')
    }
  }

  const isBusinessInformationPage = getQueryParamByName('task') === 'addBusinessInformation'

  return (
    <div className={classes.flagshipBankDetailsSuccessContainer}>
      <div className={classes.successIconContainer}>
        <img src={successIcon} alt='Success' />
      </div>
      <h2 className={classes.flagshipBankDetailsSuccessHeading}>
        {isBusinessInformationPage ? 'Business information updated' : 'Bank details submitted'}
      </h2>
      {!isBusinessInformationPage ? (
        <div className={classes.flagshipBankDetailsBody}>
          It can take up to 1 business day to verify your details
        </div>
      ) : null}

      <div className={classes.footerButtonContainer}>
        <FlatButton label='Close' className='blueBackground' onClick={notifyReactNativeWebView} />
      </div>
    </div>
  )
}

FlagshipBankDetailsSuccess.propTypes = {
  inApp: propTypes.bool,
}

export default FlagshipBankDetailsSuccess
