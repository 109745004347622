import React from 'react'

import * as forms from 'libs/forms'
import { If } from 'libs/formats'
import { track } from 'libs/analytics'
import { makeTestID } from 'libs/utils'

import { FormFieldNames, dueInSelectOptions } from './constants'

const baseTestID = 'invoiceSettingsForm'

const renderFormFields = (shouldShowInvoiceStartingNumber) => [
  <forms.FormBlock key='business_details' heading='Business details'>
    <forms.ImageUploadField
      name={FormFieldNames.LogoUri}
      label='Business logo'
      grouping='invoices-logo'
      uploadDescription={
        <>
          This will appear on your invoices and emails.
          <br />
          Maximum size: 5 MB
        </>
      }
      testID={makeTestID(baseTestID, FormFieldNames.LogoUri)}
      isFullWidth
    />
    <forms.TextField
      name={FormFieldNames.BusinessName}
      label='Business name'
      validators={[new forms.RequiredValidator('You must provide your business name')]}
      testID={makeTestID(baseTestID, FormFieldNames.BusinessName)}
      ariaLabel={makeTestID('invoices', 'settings', 'business-name')}
      isFullWidth
    />
    <forms.TextField
      name={FormFieldNames.PhysicalAddress}
      label='Physical address'
      validators={[new forms.RequiredValidator('You must provide your business physical address')]}
      testID={makeTestID(baseTestID, FormFieldNames.PhysicalAddress)}
      ariaLabel={makeTestID('invoices', 'settings', 'physical-address')}
      isFullWidth
    />
    <forms.EmailField
      name={FormFieldNames.Email}
      label='Email'
      validators={[
        new forms.EitherOrValidator(
          FormFieldNames.Phone,
          'You must provide an email address or phone number'
        ),
        new forms.EmailValidator('You must provide a valid email address'),
      ]}
      testID={makeTestID(baseTestID, FormFieldNames.Email)}
      ariaLabel={makeTestID('invoices', 'settings', 'email')}
      isFullWidth
    />
    <forms.TextField
      name={FormFieldNames.Phone}
      label='Phone'
      validators={[
        new forms.EitherOrValidator(
          FormFieldNames.Email,
          'You must provide a phone number or email address'
        ),
        new forms.MobileValidator('You must provide a valid phone number'),
      ]}
      testID={makeTestID(baseTestID, FormFieldNames.Phone)}
      ariaLabel={makeTestID('invoices', 'settings', 'phone')}
      isFullWidth
    />
    <forms.TextField
      name={FormFieldNames.VAT}
      label='VAT Number (optional)'
      validators={[new forms.VATNumberValidator('Please enter a valid VAT Number')]}
      testID={makeTestID(baseTestID, FormFieldNames.VAT)}
      ariaLabel={makeTestID('invoices', 'settings', 'vat-number')}
      isFullWidth
    />
  </forms.FormBlock>,
  <forms.FormBlock key='invoice_template' heading='Invoice template'>
    <If condition={shouldShowInvoiceStartingNumber}>
      <forms.TextField
        name={FormFieldNames.InitialInvoiceNumber}
        label='Invoice starting number'
        validators={[
          new forms.NumberValidator(
            false,
            undefined,
            undefined,
            {
              notNumeric: 'Only numbers can be entered',
            },
            true
          ),
        ]}
        testID={makeTestID(baseTestID, FormFieldNames.InitialInvoiceNumber)}
        arialLabel={makeTestID('invoices', 'settings', 'invoice-starting-number')}
        isFullWidth
      />
    </If>
    <forms.SwitchField
      name={FormFieldNames.IncludeEFTDetails}
      label='Include EFT details on the invoice PDF'
      onClick={() =>
        track('invoices_settings_manual_eft_toggle_clicked', {
          name: 'invoices_settings_manual_eft_toggle_clicked',
        })
      }
      arialLabel={makeTestID('invoices', 'settings', 'include-eft-details')}
      testID={makeTestID(baseTestID, FormFieldNames.IncludeEFTDetails)}
      isFullWidth
    />
    <forms.SelectField
      name={FormFieldNames.DueIn}
      label='Default due date'
      options={dueInSelectOptions}
      testID={makeTestID(baseTestID, FormFieldNames.DueIn)}
      isFullWidth
    />
    <forms.TextareaField
      name={FormFieldNames.Terms}
      label='Default terms'
      placeholder='e.g. Return policies, warranties and cancellations'
      rows={3}
      testID={makeTestID(baseTestID, FormFieldNames.Terms)}
      ariaLabel={makeTestID('invoices', 'settings', 'default-terms')}
      isFullWidth
    />
  </forms.FormBlock>,
]

export default renderFormFields
