import React, { useCallback, useEffect, useState } from 'react'
import OnboardingWebSDK, { Onboarding } from '@yoco/onboarding-web-sdk'
import { getToken } from 'libs/api'
import { getENV } from 'libs/utils'
import { connect } from 'react-redux'
import { bool, string } from 'prop-types'
import AppDownloadContent from 'components/onboarding/AppDownloadContent'
import { track } from 'libs/analytics'
import { businessType } from 'redux/modules/onboarding/selectors'
import { settlementActivated } from 'redux/modules/session/selectors'
import InfoCardHolder from 'components/infoCards/InfoCard'
import SubHeading from 'ui/components/SubHeading'
import classes from './onboarding.module.scss'

// eslint-disable-next-line @typescript-eslint/no-shadow
function OnboardingV3Page({ businessType, settlementActivated }) {
  const [isOnboardingReady, setOnboardingReady] = useState(false)

  const analyticsCallback = useCallback(
    (name, data) => {
      track(`onboarding_${name}`, { businessType, ...data })
    },
    [businessType]
  )

  useEffect(() => {
    const config = {
      apiURL: `${getENV('ONBOARDING_API_URL')}onboarding/api/client/v1/`,
      kycURL: `${getENV('KYC_API_URL')}api/client/v1/kyc`,
      apiAuthToken: getToken(),
    }
    const ready = OnboardingWebSDK.init(config)
    setOnboardingReady(ready)

    const listener = OnboardingWebSDK.addEventListener('*', analyticsCallback)

    return () => {
      OnboardingWebSDK.removeEventListener(listener)
    }
  }, [analyticsCallback, setOnboardingReady])

  return (
    <div className={classes.onboardingResetStyles}>
      <div className={`${classes.onboardingBackground} ${classes.backgroundImage}`}>
        <div className={`${classes.onBoardingPageContainer} ${classes.page}`}>
          <div className={classes.onboardingContainer}>{isOnboardingReady && <Onboarding />}</div>
          <div className={classes.onboardingDownloadContent}>
            <AppDownloadContent />
          </div>
          {settlementActivated && (
            <>
              <InfoCardHolder />
              <SubHeading>Refer a friend to Yoco</SubHeading>
              <InfoCardHolder referralInfo />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    businessType: businessType(state),
    settlementActivated: settlementActivated(state),
  }
}

OnboardingV3Page.defaultProps = {
  businessType: undefined,
  settlementActivated: undefined,
}

OnboardingV3Page.propTypes = {
  businessType: string,
  settlementActivated: bool,
}

export default connect(mapStateToProps)(OnboardingV3Page)
