import React from 'react'
import moment from 'moment'
import CenteredContainerPage from 'components/pages/CenteredContainerPage'
import CapitalHeaderBlock from 'components/capital/partials/CapitalHeaderBlock'
import TitleCard from 'components/cards/TitleCard'
import CapitalReApplyBlock from 'components/capital/partials/CapitalReApplyBlock'
import DateFilter from 'components/filters/DateFilter'
import CapitalFrequentlyAskedQuestionsBlock from 'components/capital/partials/CapitalFrequentlyAskedQuestionsBlock'
import CapitalHelpMessage from 'components/capital/partials/CapitalHelpMessage'
import CapitalCashAdvanceOverviewBlock from 'components/capital/partials/CapitalCashAdvanceOverviewBlock'
import CapitalRepaymentsTable from 'components/capital/partials/CapitalRepaymentsTable'
import styles from 'components/capital/capital.module.scss'
import { FilterBatch } from 'libs/filters'

interface Props {
  canReApplyForAdvance: boolean
  deductionPlanUUID: string
  prelimOfferUUID: string
  providerUUID: string
  offerUUID: string
  totalAmountPaid: number
  totalAmountPayable: number
  principalAmount: number
  outstandingBalance: number
  estimatedTerm: number
  processingFee: number
  totalPayable: number
  repaymentPercentage: number
  capitalAmount: number
  contractURL: string
  formatCurrency: (amount: number) => string
  getTotalAmountRepaid: (amount: number) => number
  onApplyForReAdvance: (prelimOfferUUID: string, data: any) => void
}

const CapitalAdvanceDetailsPage: React.FunctionComponent<Props> = ({
  canReApplyForAdvance,
  deductionPlanUUID,
  prelimOfferUUID,
  providerUUID,
  offerUUID,
  totalAmountPaid,
  totalAmountPayable,
  principalAmount,
  outstandingBalance,
  estimatedTerm,
  processingFee,
  totalPayable,
  repaymentPercentage,
  capitalAmount,
  contractURL,
  formatCurrency,
  getTotalAmountRepaid,
  onApplyForReAdvance,
}) => {
  const handleApplyForReAdvance = () => {
    if (prelimOfferUUID && capitalAmount) {
      onApplyForReAdvance(prelimOfferUUID, {
        capitalAmount,
        deductionPlanUUID,
        providerUUID,
        offerUUID,
      })
    }
  }
  return (
    <CenteredContainerPage>
      <div className={styles.page} aria-label='CapitalAdvanceDetailsPage'>
        <CapitalHeaderBlock
          title='Growing your business with Yoco Capital.'
          description='Track your repayment progress here.'
        />
        {canReApplyForAdvance ? (
          <CapitalReApplyBlock onApplyForReAdvance={handleApplyForReAdvance} />
        ) : null}
        <TitleCard title='Merchant Cash Advance overview'>
          <CapitalCashAdvanceOverviewBlock
            totalAmountPaid={totalAmountPaid}
            totalAmountPayable={totalAmountPayable}
            principalAmount={principalAmount}
            outstandingBalance={outstandingBalance}
            estimatedTerm={estimatedTerm}
            processingFee={processingFee}
            totalPayable={totalPayable}
            repaymentPercentage={repaymentPercentage}
            contractURL={contractURL}
          />
        </TitleCard>
        <div style={{ height: '21px' }} className={styles.lineBreak} />
        <TitleCard title='Cash advance repayment history'>
          <FilterBatch
            containerClass={styles.filterCapitalContainer}
            name='capitalDeductions'
            showMobileInline
          >
            <DateFilter
              noPosition
              defaults={[moment().startOf('day'), moment().startOf('day').add(1, 'day')]}
            />
          </FilterBatch>
          <CapitalRepaymentsTable
            deductionPlanUUID={deductionPlanUUID}
            totalAmountPayable={totalAmountPayable}
            formatCurrency={formatCurrency}
            getTotalAmountRepaid={getTotalAmountRepaid}
          />
        </TitleCard>
        <CapitalFrequentlyAskedQuestionsBlock />
        <CapitalHelpMessage />
      </div>
    </CenteredContainerPage>
  )
}

export default CapitalAdvanceDetailsPage
