// EFT : ACTION CREATORS
export const EFT_ACTIONS = {
  EFT_SUBMITTING: 'EFT_SUBMITTING',
  EFT_PROOF_ERROR: 'EFT_PROOF_ERROR',
  EFT_FETCHED: 'EFT_FETCHED_PROOF',
  EFT_FETCHING: 'EFT_FETCHING',
  EFT_PROOF_SUBMITTED: 'EFT_PROOF_SUBMITTED',
}

export function eftProofSubmitted() {
  return { type: EFT_ACTIONS.EFT_PROOF_SUBMITTED }
}

export function eftProofError(errorMessage) {
  return {
    type: EFT_ACTIONS.EFT_PROOF_ERROR,
    errorMessage,
  }
}

export function eftProofSubmitting() {
  return { type: EFT_ACTIONS.EFT_SUBMITTING }
}

export function eftProofFetching() {
  return { type: EFT_ACTIONS.EFT_FETCHING }
}

export function eftFetchedProof(storedFile) {
  return {
    type: EFT_ACTIONS.EFT_FETCHED,
    storedFile,
  }
}
