/* eslint-disable @typescript-eslint/no-shadow */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'

import Alert from 'components/notifications/Alert'
import LoadingView from 'components/loaders/LoadingView'
import CenteredContainerPage from 'components/pages/CenteredContainerPage'
import OnboardingTasks from 'components/onboarding/OnboardingTasks'
import TasksStatusHeading from 'components/onboarding/TasksStatusHeading'
import OnboardingProgressBar from 'components/onboarding/OnboardingProgressBar'
import {
  fetchBusinessOwnerDetails,
  fetchBusinessVetting,
  fetchOnboardingTasks,
  fetchBusinessDetails,
  launchAddressPopup,
  launchUploadPopup,
  onSetPassportIdPopupVisible,
  onSetIdDetailsPopupVisible,
  fetchLatestFICADetails,
  launchFicaIidentifiiPopup,
  updateIidentifiiIFrameUrl,
  updateIidentifiiIFrameIsLoading,
  updateIidentifiiErrorMessage,
  onSetIidentifiiCompletePopupVisible,
  iidentifiiComplete,
  launchFicaIidentifiiFailedPopup,
  setKYCPopupVisible,
  PAYMENT_METHOD,
} from 'redux/modules/onboarding'
import localisation from 'libs/localisation/localisation'
import { track } from 'libs/analytics'
import DeliveryStatusCard from 'components/onboarding/DeliveryStatusCard'
import {
  isDeliveryMethodCcdSelector,
  fulfillmentSelector,
  paymentStateSelector,
  isDeliveryMethodCourierSelector,
  ficaVerificationRequestSelector,
  disableBusinessAddress,
  businessAddress,
  isUploadFicaSubmitButtonDisabled,
  shouldLaunchAddressPopup,
  ficaUploadPopupInfo,
  shouldLaunchFicaPassportIdPopup,
  shouldLaunchFicaVerificationModal,
  shouldLaunchFicaIdDetailsPopup,
  shouldLaunchFicaIidentifiiPopup,
  iFrameUrl,
  iFrameIsLoading,
  shouldLaunchFicaIidentifiiCompletePopup,
  shouldLaunchFicaFailedIidentifiiPopup,
  paymentMethodSelector,
} from 'redux/modules/onboarding/selectors'
import { businessDetails, businessUUID } from 'redux/modules/session/selectors'
import { hasFeature } from 'libs/features'
import { wasRoutedFromApp } from 'redux/modules/routes'
import InfoCardHolder from 'components/infoCards/InfoCard'
import { openAddressPopup } from 'components/onboarding/TaskCard/utils'
import PaddedContent from 'ui/layout/PaddedContent'
import AppDownloadContent from 'components/onboarding/AppDownloadContent'
import SubHeading from 'ui/components/SubHeading'

import {
  selectBankAccountUpdateError,
  popupErrorMessages,
  selectShouldShowBankDetailsFlagshipFeedbackPopup,
  selectShouldShowBankDetailsPopup,
} from 'redux/modules/onboarding/bankDetails/selectors'
import { openBankDetailsPopup } from 'redux/modules/onboarding/bankDetails/actions'
import { fetchInvoiceOnboardingData } from '../invoices'

import classes from './onboarding.module.scss'
import { isOnboardingTasksComplete } from './utils'
import OnboardingV3Page from './OnboardingV3Page'

let gotoCongratulations
let timer

class OnboardingPage extends Component {
  UNSAFE_componentWillMount() {
    track('Complete setup viewed')
    this.props.dispatch(fetchOnboardingTasks())
    this.props.dispatch(fetchBusinessDetails())
    this.props.dispatch(fetchLatestFICADetails())
    if (this.props.isInvoicesEnabled) {
      fetchInvoiceOnboardingData()
    }
  }

  componentDidMount() {
    if (!this.receiveIdentifiiMessage) {
      this.receiveIdentifiiMessage = (event) => {
        if (event && event.data) {
          switch (event.data.status) {
            case 'CANCELLED':
              track('portal_fica_iidentifii_click_button_cancel')
              this.props.dispatch(launchFicaIidentifiiPopup(false))
              break
            case 'SUCCESS':
              track('portal_fica_iidentifii_completed')
              this.props.dispatch(launchFicaIidentifiiPopup(false))
              this.props.dispatch(onSetIidentifiiCompletePopupVisible(true))
              this.props.dispatch(iidentifiiComplete())
              break
            case 'FAILED':
              this.props.dispatch(launchFicaIidentifiiPopup(false))
              // eslint-disable-next-line no-case-declarations
              const errorType = this.getErrorType(event.data.properties)
              track('portal_fica_iidentifii_error', { errorType })
              this.props.dispatch(launchFicaIidentifiiFailedPopup({ isVisible: true, errorType }))
              break
            default:
          }
        }
      }

      window.addEventListener('message', this.receiveIdentifiiMessage, false)
    }
    // open a task popup directly if queried via the url, e.g ?task=addBankAccount
    const onboardingProps = this.getOnboardingProps(this.props)

    switch (this.props.location.query.task) {
      case 'addBankAccount':
        track('portal_complete_your_setup_bank_details')
        track('add_bank_account_button_clicked')

        this.props.dispatch(openBankDetailsPopup())
        break
      case 'addBusinessInformation':
        openAddressPopup(onboardingProps.launchAddressPopup)
        break
      default:
    }
  }

  componentDidUpdate() {
    if (this.props.setupComplete) {
      gotoCongratulations = setTimeout(
        () =>
          this.props.dispatch(
            push({
              pathname: '/dashboard/complete-setup',
              search: this.props.location.search,
            })
          ),
        1500
      )
    }

    const iframe = document.getElementById('iidentifiiIframe')
    if (iframe && iFrameIsLoading) {
      if (!timer) {
        timer = setTimeout(() => {
          this.props.dispatch(launchFicaIidentifiiPopup(false))
          this.props.dispatch(
            launchFicaIidentifiiFailedPopup({ isVisible: true, errorType: 'timeout' })
          )
        }, 8000)
      }
      iframe.addEventListener('load', () => {
        clearTimeout(timer)
        this.props.dispatch(updateIidentifiiIFrameIsLoading(false))
      })
    }
  }

  componentWillUnmount() {
    if (gotoCongratulations) {
      clearTimeout(gotoCongratulations)
    }

    if (this.receiveIdentifiiMessage) {
      window.removeEventListener('message', this.receiveIdentifiiMessage, false)
      this.receiveIdentifiiMessage = null
    }

    this.props.dispatch(fetchBusinessVetting())
    this.props.dispatch(fetchBusinessOwnerDetails())
  }

  getErrorType = (properties) =>
    properties &&
    properties.errors &&
    properties.errors.some((errors) => errors.message && errors.message.includes('camera'))
      ? 'camera'
      : 'unknown error'

  getOnboardingProps(props) {
    return {
      businessUserDetails: props.businessUserInfo,
      documentTargetUUID: props.documentTargetUUID,
      disableBusinessAddress: props.disableBusinessAddress,
      businessAddress: props.businessAddress,
      ficaUploadButtonIsDisabled: props.ficaUploadButtonIsDisabled,
      popupErrorMessages: props.popupErrorMessages,
      showAddressPopup: props.showAddressPopup,
      launchAddressPopup: (launchPopup) => props.dispatch(launchAddressPopup(launchPopup)),
      ficaUploadPopupInfo: props.ficaUploadPopupInfo,
      launchFicaPopup: (launchPopup) => props.dispatch(launchUploadPopup(launchPopup)),
      onSetPassportIdPopupVisible: (launchPop) =>
        props.dispatch(onSetPassportIdPopupVisible(launchPop)),
      setKYCPopupVisible: (bool) => props.dispatch(setKYCPopupVisible(bool)),
      isKYCPopupVisible: props.isKYCPopupVisible,
      isPassportIdPopupVisible: props.isPassportIdPopupVisible,
      onSetIdDetailsPopupVisible: (launchPop) =>
        props.dispatch(onSetIdDetailsPopupVisible(launchPop)),
      isIdDetailsPopupVisible: props.isIdDetailsPopupVisible,
      showIidentifiiPopup: props.showIidentifiiPopup,
      iiDentifiiIFrameUrl: props.iiDentifiiIFrameUrl,
      iiDentifiiIFrameIsLoading: props.iiDentifiiIFrameIsLoading,
      launchFicaIidentifiiPopup: (launchPopup) =>
        props.dispatch(launchFicaIidentifiiPopup(launchPopup)),
      updateIidentifiiIFrameUrl: (iFrameUrl) =>
        props.dispatch(updateIidentifiiIFrameUrl(iFrameUrl)),
      updateIidentifiiIFrameIsLoading: (iFrameIsLoading) =>
        props.dispatch(updateIidentifiiIFrameUrl(iFrameIsLoading)),
      updateIidentifiiErrorMessage: (errorMessage) =>
        props.dispatch(updateIidentifiiErrorMessage(errorMessage)),
      onSetIidentifiiCompletePopupVisible: (launchPop) =>
        props.dispatch(onSetIidentifiiCompletePopupVisible(launchPop)),
      isIidentifiiCompletePopupVisible: props.isIidentifiiCompletePopupVisible,
      ficaVerificationRequest: props.ficaVerification,
      fetchVettingInfo: () => props.dispatch(fetchBusinessVetting()),
      launchFicaIidentifiiFailedPopup: (showPopup) =>
        props.dispatch(launchFicaIidentifiiFailedPopup(showPopup)),
      isFailedIiendtifiiPopupVisible: props.isFailedIiendtifiiPopupVisible,
      isInApp: props.isInApp,
      hasIidentifiiFica: props.hasIidentifiiFica,
      hasKYCFica: props.hasKYCFica,
      businessUUID: props.businessUUID,
      tasks: props.tasks,
      shouldShowBankDetailsPopup: props.shouldShowBankDetailsPopup,
      shouldShowBankDetailsFlagshipFeedbackPopup: props.shouldShowBankDetailsFlagshipFeedbackPopup,
      openBankDetailsPopup: () => props.dispatch(openBankDetailsPopup()),
    }
  }

  renderErrorMessageBanner(errorMessage) {
    if (errorMessage) {
      return (
        <div className={classes.onBoardingPageTaskErrorBanner}>
          <p className={classes.onBoardingPageTaskErrorMessage}>{errorMessage}</p>
        </div>
      )
    }
    return null
  }

  render() {
    const {
      error,
      loading,
      style,
      paymentState,
      isDeliveryMethodCcd,
      fulfillmentState,
      hasDigitalFica,
      paymentMethod,
      isDeliveryMethodCourier,
      isInApp,
      hasPersonalisedAppHomeScreen,
      bankAccountUpdateError,
      location,
    } = this.props

    const isOnboardingComplete = isOnboardingTasksComplete(this.props.tasks)

    const isDeliveryInfoVisible =
      (isDeliveryMethodCcd || (!hasDigitalFica && isDeliveryMethodCourier)) &&
      !(isInApp && hasPersonalisedAppHomeScreen) &&
      paymentMethod !== PAYMENT_METHOD.CARD_ON_DELIVERY

    if (!error && !loading) {
      // Render the OnboardingV3 page if the user is not being routed to a specific task. This
      // can be updated once the Onboarding SDK supports filtering tasks.
      if (!location.query.task) {
        return <OnboardingV3Page />
      }

      return (
        <CenteredContainerPage className={classes.centeredContainerPage}>
          {this.renderErrorMessageBanner(this.getOnboardingProps(this.props).ficaErrorMessage)}
          {this.renderErrorMessageBanner(bankAccountUpdateError)}
          {this.renderErrorMessageBanner(this.getOnboardingProps(this.props).vettingInfoFetchError)}
          {this.renderErrorMessageBanner(
            this.getOnboardingProps(this.props).failedUserInfoFetchError
          )}
          {this.renderErrorMessageBanner(
            this.getOnboardingProps(this.props).iidentifiiErrorMessage
          )}
          <div className={classes.page} style={style}>
            <OnboardingProgressBar isOnboardingComplete={isOnboardingComplete} />
            <TasksStatusHeading isOnboardingComplete={isOnboardingComplete} />
            {isDeliveryInfoVisible && (
              <DeliveryStatusCard paymentState={paymentState} fulfillmentState={fulfillmentState} />
            )}
            <OnboardingTasks
              title='In order to receive your money, complete the steps below:'
              tasks={this.props.tasks}
              isInApp={isInApp}
              hasPersonalisedAppHomeScreen={hasPersonalisedAppHomeScreen}
              localisation={this.props.localisation}
              // eslint-disable-next-line react/forbid-component-props
              dispatch={this.props.dispatch}
              forms={this.props.forms}
              hasDigitalFica={this.props.hasDigitalFica}
              onboardingPopupProps={this.getOnboardingProps(this.props)}
            />
            <AppDownloadContent />
            <InfoCardHolder />
            <SubHeading>Refer a friend to Yoco</SubHeading>
            <InfoCardHolder referralInfo />
          </div>
        </CenteredContainerPage>
      )
    }

    if (error) {
      return (
        <PaddedContent>
          <Alert
            closable
            messageType='danger'
            message='Something went wrong, please try again later.'
          />
        </PaddedContent>
      )
    }

    return (
      <div style={{ flex: '1' }}>
        <LoadingView message='Preparing your setup tasks…' />
      </div>
    )
  }
}

OnboardingPage.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  setupComplete: PropTypes.bool,
  isDeliveryMethodCcd: PropTypes.bool,
  paymentState: PropTypes.string,
  fulfillmentState: PropTypes.string,
  hasDigitalFica: PropTypes.bool,
  isDeliveryMethodCourier: PropTypes.bool,
  isInvoicesEnabled: PropTypes.bool.isRequired,
  tasks: PropTypes.object,
  forms: PropTypes.object,
  isInApp: PropTypes.bool,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  query: PropTypes.object,
  task: PropTypes.string,
  search: PropTypes.string,
  localisation: PropTypes.object,
  hasPersonalisedAppHomeScreen: PropTypes.bool,
  paymentMethod: PropTypes.string,
  bankAccountUpdateError: PropTypes.string,
}

export default connect((state) => ({
  localisation: localisation(state),
  error: state.onboarding.get('error'),
  loading: state.onboarding.get('loading'),
  setupComplete: state.onboarding.get('setupComplete'),
  fulfillmentState: fulfillmentSelector(state),
  isInvoicesEnabled: hasFeature(state, 'onlineInvoices'),
  isDeliveryMethodCcd: isDeliveryMethodCcdSelector(state),
  paymentState: paymentStateSelector(state),
  hasDigitalFica: hasFeature(state, 'digitalFICA'),
  isDeliveryMethodCourier: isDeliveryMethodCourierSelector(state),
  ficaVerification: ficaVerificationRequestSelector(state),
  tasks: state.onboarding.get('tasks'),
  forms: state.forms,
  isInApp: wasRoutedFromApp(state),
  hasIidentifiiFica: hasFeature(state, 'iidentifiiFICA'),
  hasKYCFica: hasFeature(state, 'onfidoFICA'),
  hasPersonalisedAppHomeScreen: hasFeature(state, 'personalisedAppHomeScreen'),
  businessUUID: businessUUID(state),
  businessUserInfo: businessDetails(state),
  disableBusinessAddress: disableBusinessAddress(state),
  businessAddress: businessAddress(state),
  ficaUploadButtonIsDisabled: isUploadFicaSubmitButtonDisabled(state),
  popupErrorMessages: popupErrorMessages(state),
  showAddressPopup: shouldLaunchAddressPopup(state),
  ficaUploadPopupInfo: ficaUploadPopupInfo(state),
  isPassportIdPopupVisible: shouldLaunchFicaPassportIdPopup(state),
  isKYCPopupVisible: shouldLaunchFicaVerificationModal(state),
  isIdDetailsPopupVisible: shouldLaunchFicaIdDetailsPopup(state),
  showIidentifiiPopup: shouldLaunchFicaIidentifiiPopup(state),
  iiDentifiiIFrameUrl: iFrameUrl(state),
  iiDentifiiIFrameIsLoading: iFrameIsLoading(state),
  isIidentifiiCompletePopupVisible: shouldLaunchFicaIidentifiiCompletePopup(state),
  failedIidentifiiPopup: shouldLaunchFicaFailedIidentifiiPopup(state),
  paymentMethod: paymentMethodSelector(state),
  bankAccountUpdateError: selectBankAccountUpdateError(state),
  shouldShowBankDetailsPopup: selectShouldShowBankDetailsPopup(state),
  shouldShowBankDetailsFlagshipFeedbackPopup: selectShouldShowBankDetailsFlagshipFeedbackPopup(
    state
  ),
}))(OnboardingPage)
