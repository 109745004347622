import React from 'react'
import PropTypes from 'prop-types'
import FlatButton from 'components/buttons/FlatButton'
import { track } from 'libs/analytics'
import { Icon } from '@yoco/design-system-icons/dist/react'
import classes from './OnboardingStates.module.scss'
import CardMachineImage from '../../../assets/images/64px-icon-card-machine-payments.svg'

const Card = ({ header, body, primary, secondary, image, primaryAction, secondaryAction }) => {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        {image}
        <h3 className={classes.body}>{header}</h3>
        <p className={classes.body}>{body}</p>
        <div className={classes.buttonContainer}>
          <FlatButton label={primary} className={classes.button} onClick={primaryAction} />
          <FlatButton
            label={secondary}
            className={`${classes.button} blueBackground`}
            onClick={secondaryAction}
          />
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  image: PropTypes.element.isRequired,
  primaryAction: PropTypes.func.isRequired,
  secondaryAction: PropTypes.func.isRequired,
}

const OnboardingCards = ({ isOnboarded }) => {
  switch (isOnboarded) {
    case 'failed':
      return (
        <Card
          header='We couldn’t verify your profile'
          body='Please check your profile for incorrect details or outstanding documents.'
          primary='Get Help'
          secondary="Find out what's missing"
          image={<Icon name='alert-warning' color='#F70C13' size={64} />}
          primaryAction={() => track('not_onboarded_cannot_verify_whats_missing_clicked')}
          secondaryAction={() => track('not_onboarded_cannot_verify_close_clicked')}
        />
      )

    case 'todo':
      return (
        <Card
          header='Complete your profile to accept payments'
          body='All you need to do is verify your identity and provide your bank details.'
          primary='Not now'
          secondary='Complete profile'
          image={<img src={CardMachineImage} alt='cardMachineImage' />}
          primaryAction={() => track('not_onboarded_verify_check_status_clicked')}
          secondaryAction={() => track('not_onboarded_verify_close_clicked')}
        />
      )

    case 'in_progress':
      return (
        <Card
          header='You can accept payments once your profile is verified'
          body='If you haven’t heard back from us within 24 hours, get help from Yoco Support.'
          primary='Get help'
          secondary='Okay'
          image={<Icon name='connecting' color='#878B90' size={64} />}
          primaryAction={() => track('not_onboarded_verify_check_status_clicked')}
          secondaryAction={() => track('not_onboarded_verify_close_clicked')}
        />
      )

    default:
      return null
  }
}

OnboardingCards.propTypes = {
  isOnboarded: PropTypes.string.isRequired,
}

export default OnboardingCards
