/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'

import {
  onSetPassportIdPopupVisible,
  onSetIdDetailsPopupVisible,
  launchUploadPopup,
  fetchBusinessVetting,
  FORM_NAMES,
} from 'redux/modules/onboarding'
import { multipleFieldsUpdated } from 'redux/modules/forms'
import { track } from 'libs/analytics'

import smartCardIdIcon from './images/single-neutral-id-card-3.svg'
import passportIcon from './images/earth-3.svg'
import faceIcon from './images/face-id-4.svg'
import classes from './styles.module.scss'

const NON_SA_CITIZEN_DATA = {
  southAfricanID: false,
  southAfricanPassport: true,
  passport: false,
  otherDocument: false,
  idNumber: undefined,
}

function Content({
  onSetPassportIdPopupVisible,
  launchUploadPopup,
  setFicaPassport,
  fetchBusinessVetting,
}) {
  const onSouthAfricanId = () => {
    track('portal_fica_iidentifii_click_button_id_type', { id_type: 'sa_id' })
    fetchBusinessVetting()
    onSetPassportIdPopupVisible(false)
    onSetIdDetailsPopupVisible(true)
  }

  const onForeignPassport = () => {
    track('portal_fica_iidentifii_click_button_id_type', { id_type: 'foreign_passport' })
    setFicaPassport()
    onSetPassportIdPopupVisible({ isPassportIdPopupVisible: false })
    launchUploadPopup({ showUploadIDPopup: true, isCitizenshipSelected: true })
  }

  return (
    <div>
      <img src={faceIcon} alt='face-scan' className={classes.faceIcon} />
      <div className={classes.buttonGroup}>
        <button type='button' className={classes.iconButton} onClick={onSouthAfricanId}>
          <span>
            <img src={smartCardIdIcon} alt='ID Card' className={classes.icon} />
          </span>
          <span>South African ID</span>
        </button>
        <button type='button' className={classes.iconButton} onClick={onForeignPassport}>
          <span>
            <img src={passportIcon} alt='Passport' className={classes.icon} />
          </span>
          <span>Foreign Passport</span>
        </button>
      </div>
    </div>
  )
}

Content.propTypes = {
  onSetPassportIdPopupVisible: PropTypes.func,
  launchUploadPopup: PropTypes.func,
  setFicaPassport: PropTypes.func,
  fetchBusinessVetting: PropTypes.func,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetPassportIdPopupVisible,
      launchUploadPopup,
      setFicaPassport: () =>
        dispatch(multipleFieldsUpdated(FORM_NAMES.FICA_VERIFICATION, NON_SA_CITIZEN_DATA)),
      fetchBusinessVetting,
    },
    dispatch
  )

export default connect(undefined, mapDispatchToProps)(Content)
