/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'

import {
  FORM_NAMES,
  onSetIdDetailsPopupVisible,
  launchFicaIidentifiiPopup,
  postBusinessDetails,
  postIidentifiiIdNumber,
  verifyIdentityNumber,
} from 'redux/modules/onboarding'
import { businessDetails } from 'redux/modules/session/selectors'
import { isVerifyingIidentifiiIdNumber } from 'redux/modules/onboarding/selectors'

import IidentifiiForm from './IidentifiiForm'

function Content({
  forms,
  launchFicaIidentifiiPopup,
  onSetIdDetailsPopupVisible,
  businessUserDetails,
  postBusinessDetails,
  postIidentifiiIdNumber,
  verifyIdentityNumber,
  isIdNumberBeingVerified,
}) {
  return (
    <IidentifiiForm
      formData={forms.getIn([FORM_NAMES.IIDENTIFII, 'data', 'personal'])}
      setPopupVisible={launchFicaIidentifiiPopup}
      onSetIdDetailsPopupVisible={onSetIdDetailsPopupVisible}
      businessUserDetails={businessUserDetails}
      postBusinessDetails={postBusinessDetails}
      postIidentifiiIdNumber={postIidentifiiIdNumber}
      setIsVerifyingIDLoaderVisible={verifyIdentityNumber}
      isVerifyingIdNumber={isIdNumberBeingVerified}
    />
  )
}

Content.propTypes = {
  postBusinessDetails: PropTypes.func,
  postIidentifiiIdNumber: PropTypes.func,
  verifyIdentityNumber: PropTypes.func,
  forms: PropTypes.object,
  businessUserDetails: PropTypes.object,
  isIdNumberBeingVerified: PropTypes.bool,
  launchFicaIidentifiiPopup: PropTypes.bool,
  onSetIdDetailsPopupVisible: PropTypes.func,
}

const mapStateToProps = (state) => ({
  forms: state.forms,
  businessUserDetails: businessDetails(state),
  isIdNumberBeingVerified: isVerifyingIidentifiiIdNumber(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetIdDetailsPopupVisible,
      launchFicaIidentifiiPopup,
      postBusinessDetails,
      postIidentifiiIdNumber,
      verifyIdentityNumber,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Content)
