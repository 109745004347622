import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PageHeader from 'ui/components/PageHeader'
import SubHeading from 'ui/components/SubHeading'
import invoicesIcon from 'assets/images/Invoices-64px.svg'
import CenteredContainerPage from 'components/pages/CenteredContainerPage'
import SplitBlock from 'components/blocks/SplitBlock'
import { track } from 'libs/analytics'
import { makeTestID } from 'libs/utils'

import Task from './Task'
import TaskSetup from './Setup'
import TaskFirstInvoice from './FirstInvoice'
import TaskFirstCustomer from './FirstCustomer'
import classes from './tasks.module.scss'

function Tasks({ isOnboardingComplete, isAnyCustomerCreated, isAnyInvoiceCreated }) {
  const [taskInProgress, setTaskInProgress] = useState()

  const children = (
    <CenteredContainerPage>
      <div className={classes.content}>
        <div className={classes.heading}>
          <PageHeader>Yoco Invoices</PageHeader>
          <img src={invoicesIcon} alt='invoices' />
          <p>
            Send simple, professional invoices to your customers
            <br />
            and get paid fast.
          </p>
        </div>
        <div className={classes.tasksHeading}>
          <SubHeading>Get started with Yoco Invoices</SubHeading>
          <p>Complete the steps below and start sending invoices to your customers.</p>
        </div>
        <div className={classes.tasks}>
          <Task
            title='Set up invoice template'
            iconName='invoice'
            buttonLabel='Set up'
            onButtonClick={() => {
              setTaskInProgress('settings')
              track('invoices_set_up_button_clicked', { name: 'invoices_set_up_button_clicked' })
            }}
            isDone={isOnboardingComplete}
            testID={makeTestID('invoices', 'gettingStarted', 'taskSetupBusinessTrigger')}
          />
          <Task
            title='Add customers'
            iconName='contacts'
            buttonLabel='New customer'
            onButtonClick={() => {
              setTaskInProgress('customer')
              track('invoices_customers_new_customer_button_clicked', {
                name: 'invoices_customers_new_customer_button_clicked',
              })
            }}
            isDone={isAnyCustomerCreated}
            disabled={!isOnboardingComplete}
            testID={makeTestID('invoices', 'gettingStarted', 'taskAddCustomerTrigger')}
          />
          <Task
            title='Send your first invoice'
            iconName='plane-paper'
            buttonLabel='New invoice'
            onButtonClick={() => {
              setTaskInProgress('invoice')
              track('invoices_new_invoice_button_clicked', {
                name: 'invoices_new_invoice_button_clicked',
              })
            }}
            isDone={isAnyInvoiceCreated}
            disabled={!isOnboardingComplete}
            testID={makeTestID('invoices', 'gettingStarted', 'taskNewInvoiceTrigger')}
          />
        </div>
      </div>
    </CenteredContainerPage>
  )

  const handleClose = () => setTaskInProgress(undefined)
  switch (taskInProgress) {
    case 'settings':
      return <TaskSetup onClose={handleClose}>{children}</TaskSetup>
    case 'customer':
      return <TaskFirstCustomer onClose={handleClose}>{children}</TaskFirstCustomer>
    case 'invoice':
      return <TaskFirstInvoice onClose={handleClose}>{children}</TaskFirstInvoice>
    default:
      return <SplitBlock showingSplit={false}>{children}</SplitBlock>
  }
}

Tasks.propTypes = {
  isOnboardingComplete: PropTypes.bool.isRequired,
  isAnyCustomerCreated: PropTypes.bool.isRequired,
  isAnyInvoiceCreated: PropTypes.bool.isRequired,
}

export default Tasks
