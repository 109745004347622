/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Map } from 'immutable'
import classnames from 'classnames'

import * as misc from 'libs/formats'
import typography from 'ui/typography/typography.module.scss'
import layout from 'ui/layout/layout.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import FormCard from 'components/cards/FormCard'
import { prettyPaymentResult, prettyPaymentMethod, prettyPaymentType } from 'libs/payments'
import HStack from 'ui/layout/HStack'

import classes from './transactions.module.scss'
import TransactionStateIcon from './TransactionStateIcon'

export default class BillPayment extends PureComponent {
  renderRefunded() {
    return (
      <div className={classes.transactionLifeSpan}>
        {this.renderPayment(this.props.payment, false || !this.props.associatedPayment)}
        {this.props.associatedPayment && this.renderPayment(this.props.associatedPayment)}
      </div>
    )
  }

  renderPayment(payment, showActions = true) {
    const userName = payment.getIn(['user', 'fullName'])
    const date = payment.get('created')

    return (
      <div key={payment.get('uuid')}>
        <div
          className={classnames(classes.transactionOverviewCard, {
            [classes.active]: this.props.isActive,
          })}
        >
          {payment.get('paymentType') === 'refund' ? (
            <TransactionStateIcon
              transactionState={payment.get('transactionState')}
              iconName='refund'
            />
          ) : (
            <TransactionStateIcon transactionState={payment.get('transactionState')} />
          )}
          <div className={classes.details}>
            <div className={classes.amount}>
              <misc.Amount
                alignLeft
                amount={payment.get('amount')}
                style={{ display: 'inline-block' }}
              />
              &nbsp;
              {prettyPaymentMethod(payment)}
              &nbsp;
              {prettyPaymentType(payment)}
            </div>
            <div>
              <div className={classes.detailInfo}>{prettyPaymentResult(payment)}</div>
              <span> by </span>
              <div className={classes.detail}>{userName}</div>
            </div>
          </div>
          <div className={classnames(typography.subText, layout.textRight)}>
            <div className={classes.light}>
              <span className={classes.lighter}> {moment(date).format('dddd')} </span>
              {moment(date).format('h:mm a')}
            </div>
            <div>
              <div>
                {moment(date).format('Do')}
                <span className={classes.lighter}> of </span>
                {moment(date).format('MMMM')}
              </div>
            </div>
          </div>
        </div>

        {showActions && this.renderActions(payment)}
      </div>
    )
  }

  renderActions(payment) {
    if (this.props.isActive) {
      const refundDetails = payment.get('refundDetails', Map())
      const productType = payment.getIn(['transactionInfo', 'onlineMeta', 'productType'])
      const isInvoiceProductType = productType === 'invoice'
      const refundLabel = refundDetails.get('canBeRefunded') ? 'Refund' : 'Refund Unavailable'

      const refundButton = payment.get('paymentType') !== 'refund' && (
        <FlatButton
          icon={refundDetails.get('canBeRefunded') ? 'icon-refresh' : undefined}
          label={refundLabel}
          key='refund'
          disabled={!refundDetails.get('canBeRefunded')}
          className='destructive'
          onClick={() => this.props.refund(payment)}
        />
      )

      return (
        <HStack spacing='xs' className={classes.paymentActions}>
          {!isInvoiceProductType ? refundButton : null}
          <FlatButton
            icon='icon-plane-paper-2'
            label='Send receipt'
            key='send-receipt'
            className='primary'
            onClick={() => this.props.sendReceipt(payment)}
          />
        </HStack>
      )
    }

    return null
  }

  renderInner() {
    if (this.props.payment.get('transactionState') === 'refunded') {
      return this.renderRefunded()
    }

    return (
      <div className={classes.transactionLifeSpan}>{this.renderPayment(this.props.payment)}</div>
    )
  }

  render() {
    if (this.props.isActive) {
      return (
        <FormCard hideHeader style={{ marginBottom: 0, padding: '16px' }}>
          {this.renderInner()}
        </FormCard>
      )
    }

    return (
      <div
        className={classes.inactivePayment}
        onClick={() => this.props.setActive(this.props.payment)}
      >
        {this.renderInner()}
      </div>
    )
  }
}

BillPayment.propTypes = {
  payment: PropTypes.objectOf(Map).isRequired,
  associatedPayment: PropTypes.objectOf(Map),
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  sendReceipt: PropTypes.func,
  refund: PropTypes.func,
}
