import React from 'react'
import PropTypes from 'prop-types'

import GenericUploadComponent from 'components/files/GenericUploadComponent'
import { isFlagship } from 'libs/utils'

import classes from './styles.module.scss'

function UploadedDocumentField({
  documentTitle,
  documentSubtext,
  fileUploadID,
  grouping,
  targetUUID,
  uploadedDocument,
  onFileUploadSuccess,
}) {
  return (
    <div>
      <div>
        <h3 className={isFlagship ? classes.flagshipDocumentTitle : classes.documentTitle}>
          {documentTitle}
        </h3>
        <h5 className={isFlagship ? classes.flagshipDocumentSubtext : classes.documentSubtext}>
          {documentSubtext}
        </h5>
      </div>

      <div>
        <GenericUploadComponent
          fileUploadID={fileUploadID}
          grouping={grouping}
          uploadedDocument={uploadedDocument}
          targetUUID={targetUUID}
          completeCallback={() =>
            onFileUploadSuccess &&
            onFileUploadSuccess({
              submittedDocument: uploadedDocument,
              uploadedTargetUUID: targetUUID,
              documentTitle,
            })
          }
          acceptedFileRE='image/*,application/pdf'
        />
      </div>
    </div>
  )
}

UploadedDocumentField.propTypes = {
  documentTitle: PropTypes.string,
  documentSubtext: PropTypes.string,
  fileUploadID: PropTypes.string,
  grouping: PropTypes.any,
  targetUUID: PropTypes.string,
  uploadedDocument: PropTypes.any,
  onFileUploadSuccess: PropTypes.func,
}

export default UploadedDocumentField
