/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { loggedOut } from 'redux/modules/session'
import { clearOnboarding } from 'redux/modules/onboarding'
import { clearUserUpdateRequest } from 'redux/modules/profile'
import { clearForm } from 'redux/modules/forms'
import Spinner from 'components/loaders/Spinner'
import { userLoggedOut } from 'libs/analytics'
import { clearNotifications } from 'redux/modules/notifications'
import { FirebaseHandler } from 'libs/firebase'
import { callJSONApi } from 'libs/api'
import { setCookie } from 'libs/cookies'
import useReferralStore from 'containers/growth/store'

class UnconnectedLogoutPage extends Component {
  componentDidMount() {
    callJSONApi(
      '/user/logout',
      'POST',
      {},
      // success callback
      () => console.log('Logout was successfully'),
      // fail callback
      (prettyError) => Sentry.captureException(`Could not logout, got error: ${prettyError}`),
      // use credentials
      true,
      // Logout on 401 (we could get stuck in a loop if we logout again)
      false
    )

    window?.yocoStorage?.clear()
    userLoggedOut()
    this.props.dispatch(loggedOut())
    this.props.dispatch(clearOnboarding())
    this.props.dispatch(clearForm('ficaVerification'))
    this.props.dispatch(clearForm('businessAddress'))
    this.props.dispatch(clearUserUpdateRequest())
    const path = this.props.queryParams.message
      ? `/account/login?message=${this.props.queryParams.message}`
      : `/account/login`
    this.props.dispatch(clearNotifications())
    FirebaseHandler.disconnectFirebase()
    useReferralStore.getState().clearReferralStore
      ? useReferralStore.getState().clearReferralStore()
      : null

    setCookie('yoco-auth-cookie', '')
    setCookie('businessUUID', '')
    setCookie('userUUID', '')
    setCookie('sessionToken', '')
    this.props.dispatch(push(path))
  }

  render() {
    return (
      <div style={{ textAlign: 'center', paddingTop: '16px', paddingBottom: '38px' }}>
        <Spinner blue />
        <div style={{ paddingTop: '12px' }}>Logging out...</div>
      </div>
    )
  }
}

UnconnectedLogoutPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
}

const LogoutPage = connect((state) => ({
  queryParams: state.routing.locationBeforeTransitions.query,
}))(UnconnectedLogoutPage)

export default LogoutPage
