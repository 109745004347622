// BUSINESS ADDRESS ACTIONS
export const BUSINESS_ADDRESS_ACTIONS = {
  BUSINESS_ADDRESS_SUBMIT_ERROR_MESSAGE: 'BUSINESS_ADDRESS_SUBMIT_ERROR_MESSAGE',
  BUSINESS_ADDRESS_FETCH_ERROR_MESSAGE: 'BUSINESS_ADDRESS_FETCH_ERROR_MESSAGE',
  TRADING_ADDRESS: 'TRADING_ADDRESS',
  SHOW_ADDRESS_POPUP: 'SHOW_ADDRESS_POPUP',
}

// BUSINESS ADDRESS ACTION_CREATORS
export const updateBusinessTradingAddress = (payload) => ({
  type: BUSINESS_ADDRESS_ACTIONS.TRADING_ADDRESS,
  payload,
})

export const launchAddressPopup = (payload) => ({
  type: BUSINESS_ADDRESS_ACTIONS.SHOW_ADDRESS_POPUP,
  payload,
})

export const updateBusinessAddressSubmitErrorMessage = (payload) => ({
  type: BUSINESS_ADDRESS_ACTIONS.BUSINESS_ADDRESS_SUBMIT_ERROR_MESSAGE,
  payload,
})
