import React from 'react'
import { fromJS } from 'immutable'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import * as forms from 'libs/forms'
import PopupComponent from 'libs/modals/popups'
import classes from 'libs/modals/popups.module.scss'

const initialData = fromJS({
  accuracy: -1,
  latitude: -1,
  longitude: -1,
  mobileNumber: undefined,
  email: undefined,
})

const refundReasons = [
  {
    label: 'Accidental charge',
    value: 'Accidental charge',
  },
  {
    label: 'Returned goods',
    value: 'Returned goods',
  },
  {
    label: 'Incorrect amount',
    value: 'Incorrect amount',
  },
  {
    label: 'Service not delivered',
    value: 'Service not delivered',
  },
]

export default class RefundPopup extends PopupComponent {
  getContent() {
    return (
      <div>
        <div className={classes.popupTitle}>{this.props.title}</div>
        <div className={classes.popupSubtitle}>
          The full amount of this transaction will be returned to the cardholder
        </div>
        <forms.Form
          name='refundTransaction'
          action={`/transactions/${this.props.transaction.get('uuid')}/portalRefund/`}
          initialData={initialData}
          onSuccess={this.onFormSuccess}
        >
          <forms.SelectField
            name='reason'
            label='Reason for refund'
            validators={[new forms.RequiredValidator('You must select a reason')]}
            required
            isFullWidth
            options={refundReasons}
            placeholder='Please select a reason for refund'
          />
          <forms.PasswordField
            required
            isFullWidth
            name='password'
            label='Your password'
            validators={[new forms.RequiredValidator('Please enter your password')]}
            description='We use your password to confirm your identity'
          />
          <div className={classes.refundWarning}>
            <span className={classes.cannot}>You cannot undo this action.</span>
            <br />
            Be very sure you wish to proceed.
          </div>
          <div className={classes.actions}>
            <FlatButton
              label={`Refund R ${this.props.transaction.get('amount', 0).toFixed(2)}`}
              className='blueBackground'
              style={{ width: '100%' }}
            />
          </div>
        </forms.Form>
      </div>
    )
  }

  onFormSuccess = (result) => {
    if (this.props.onSuccess) {
      this.props.onSuccess(result)
    }
  }
}

RefundPopup.propTypes = {
  onSuccess: PropTypes.func,
  onEsc: PropTypes.func,
  title: PropTypes.string,
  transaction: PropTypes.object.isRequired,
}
