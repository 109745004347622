import React from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.scss'

function DocumentRow({ rowIcon, rowDescription }) {
  return (
    <div className={classes.documentRowContainer}>
      <div className={classes.rowIconContainer}>
        <img src={rowIcon} alt='Icon not available' />
      </div>
      <div className={classes.rowDescriptionContainer}>
        <h2 className={classes.rowDescription}>{rowDescription}</h2>
      </div>
    </div>
  )
}

DocumentRow.propTypes = {
  rowIcon: PropTypes.string,
  rowDescription: PropTypes.string,
}

export default DocumentRow
