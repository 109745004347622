import ROLES from 'libs/roles'
import FEATURES from 'libs/features'

import OnboardingPage from './OnboardingPage'

export default function getRoutes() {
  return {
    path: 'onboarding',
    text: 'Complete Setup',
    icon: 'note',
    component: OnboardingPage,
    redirectOnAuthFail: 'dashboard',
    redirectOnFeatureFail: 'dashboard',
    requiresOneRole: [ROLES.MANAGE_BUSINESS],
    requiresOneOfFeatures: [FEATURES.COMPLETE_SETUP],
    badgeStyle: { fontSize: '13px' },
  }
}
