import wooCommerce from 'assets/images/online/woo.svg'
import wix from 'assets/images/online/wix.svg'
import yocoSDK from 'assets/images/online/yoco_dev.svg'
import shopify from 'assets/images/online/shopify.svg'

const infoCards = [
  {
    img: wooCommerce,
    title: '',
    blurb: 'The Yoco payment gateway is available as a WooCommerce plugin.',
    linkAText: 'Learn more',
    linkAHref: 'https://support.yoco.help/s/article/Yoco-for-WooCommerce',
    popUp: false,
    tracking: 'woocommerce',
  },
  {
    img: wix,
    title: '',
    blurb: 'You can add Yoco as a payment gateway on your Wix website. ',
    linkAText: 'Learn more',
    linkAHref: 'https://support.yoco.help/s/article/Yoco-for-Wix',
    popUp: false,
    tracking: 'wix',
  },
  {
    img: yocoSDK,
    title: '',
    blurb: 'Try our SDK and integrate Yoco payments directly into your website.',
    linkAText: 'Learn more',
    linkAHref: 'https://developer.yoco.com/online/getting-started',
    popUp: false,
    tracking: 'yoco-sdk',
  },
  {
    img: shopify,
    title: '',
    blurb: 'Get paid on your Shopify store with Yoco payment gateway',
    linkAText: 'Learn more',
    linkAHref: 'https://support.yoco.help/s/article/Yoco-for-Shopify',
    popUp: false,
    tracking: 'shopify',
  },
]

export default infoCards
