import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'

import UserAlert from 'components/notifications/UserAlert'
import { pageViewed, userLoggedIn } from 'libs/analytics'
import classes from 'containers/app/app.module.scss'
import { getCookie, saveSession } from 'libs/cookies'
import { loggedIn } from 'redux/modules/session'
import { callJSONApi } from 'libs/api'
import { wasRoutedFromApp } from 'redux/modules/routes'
import Alert from 'components/notifications/Alert'
import PaddedContent from 'ui/layout/PaddedContent'
import FullscreenLoadingPage from 'components/loaders/FullscreenLoadingPage'
// Fonts
/* eslint-disable */
import yocoFont from 'assets/styles/fonts/yoco/yoco.module.scss'
import yocoRegisterFont from 'assets/styles/fonts/yocoRegister/yoco-register.module.scss'
import collectionIcons from 'assets/styles/fonts/collectionIcons/collection-icons.module.scss'

// New fonts
import sharpGrotesk from 'assets/styles/fonts/sharpGrotesk/sharpGrotesk.module.scss'

// Theme
import theme from 'assets/styles/theme.module.scss'

// Polyfills
import Storage from './polyfills/localStoragePolyfill'
import RequestAnimationFrame from './polyfills/requestAnimationFramePolyfill'
/* eslint-enable */

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: null,
      isLoadingSession: true,
    }
  }

  UNSAFE_componentWillMount() {
    // IF THE BROWSER IS INTERNET EXPLORER 10
    if (navigator.appVersion.indexOf('MSIE 10') !== -1) {
      this.setState({
        ie10: true,
      })
    }

    this.checkNextPath()
  }

  componentDidMount() {
    this.loadOrRefreshSession()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.path !== this.props.path) {
      pageViewed(nextProps.path)
    }
    // Necessary for handling refreshing of any page in portal as it triggers the initial route
    const isGoingToLogin = nextProps.path === '/' || nextProps.path.includes('login')
    const shouldStayOnCurrentPage =
      nextProps.loggedIn && isGoingToLogin && !this.props.path.includes('login')
    if (shouldStayOnCurrentPage) {
      this.props.dispatch(push(this.props.path + this.props.location.search))
    }
  }

  getIE10() {
    if (this.state.ie10) {
      return (
        <UserAlert
          userAlert={{
            htmlMessage: `You are using an old browser. Certain things will not work correctly. Yoco recommends updating your browser, following this <a href="http://outdatedbrowser.com/" target="_blank">link</a> to do so easily.</br></br>This browser may become <b>unsupported</b> in the near future.</b>`,
            alertType: 'warning',
          }}
          dismissUserAlert={() => this.setState({ ie10: false })}
          style={{ top: '67px' }}
        />
      )
    }
    return null
  }

  getPostLoginRedirectUrl() {
    const path = this.props.path || '/'

    let baseUrl
    if (path === '/' || path === '/account/login') {
      baseUrl = this.props.setupComplete ? '/dashboard' : '/onboarding'
    } else {
      baseUrl = path
    }

    return baseUrl + this.props.location.search
  }

  getLoginRedirectURL() {
    // If we do not have a session, this is the URL to push the user to to login and get back to their intended destination
    if (this.props.path.startsWith('/account/login')) {
      return this.props.path
    }

    return `/account/login?next=${encodeURIComponent(
      this.props.location.pathname + this.props.location.search
    )}`
  }

  getWarning() {
    return `<!--[if lte IE 9]>
      <div class=${classes.oldWarning}>
        <div class="title">
          Browser not supported
        </div>
        <div class="subtitle">
          Unfortunately the Yoco Business Portal does not support this browser version.<br/><br/>
          Please click the button below to update your browser.
        </div>
        <div class="update-button">
          <a href="http://outdatedbrowser.com/">Update browser</a>
        </div>
        <div class="comments">
          Using an outdated browser opens you up to security risks, slows down your computer and causes websites to display incorrectly.</br>
          Keeping your browser up to date is highly recommended.
        </div>
        <div class="support">
          Should you require some assistance, give us a call</br>087 231 0273
        </div>
      </div>
    <![endif]-->`
  }

  loadOrRefreshSession() {
    // Once we have loaded our session - which page should we direct to
    const postLoginRedirectUrl = this.getPostLoginRedirectUrl()

    let session = getCookie('signUpSession')
    if (!session || session.length < 1) {
      session = window?.yocoStorage?.getItem('session')
    }

    if (session) {
      // We actually refresh this user session each time the page is
      // refreshed. We will continue to show the loader until we receive
      // the result from `/user/session` and then redirect the user to
      // either the dashboard, or the onboarding page
      console.log('Using session inflated from local storage')

      const jsonSession = JSON.parse(session)
      saveSession(jsonSession)
      userLoggedIn(jsonSession.user)
      this.props.dispatch(loggedIn(jsonSession))

      callJSONApi(
        '/user/session',
        'GET',
        {},
        (response) => {
          saveSession(response.data)
          this.props.dispatch(loggedIn(response.data))
          this.props.dispatch(push(postLoginRedirectUrl))
        },
        (prettyError) => {
          console.log('Unable to fetch user session', prettyError)
          this.setState({
            errorMessage: `Unable to load user information: ${prettyError}`,
          })
        }
      )
    } else if (getCookie('sessionToken')) {
      console.log('Authenticating using `sessionToken` from app')
      // eslint-disable-next-line no-undef
      yocoStorage.setItem('token', getCookie('sessionToken'))

      callJSONApi(
        '/user/session',
        'GET',
        {},
        (response) => {
          this.setState({ isLoadingSession: false })
          saveSession(response.data)
          userLoggedIn(response.data.user)
          this.props.dispatch(loggedIn(response.data))
          this.props.dispatch(push(postLoginRedirectUrl))
        },
        (prettyError) => {
          console.error('Unable to fetch user session, redirecting to login', prettyError)
          this.props.dispatch(push(this.getLoginRedirectURL()))
        }
      )
      // eslint-disable-next-line react/prop-types
    } else if (this.props.isInApp) {
      callJSONApi(
        '/user/session',
        'GET',
        {},
        (response) => {
          this.setState({ isLoadingSession: false })
          saveSession(response.data)
          this.props.dispatch(loggedIn(response.data))
          this.props.dispatch(push(postLoginRedirectUrl))
        },
        (prettyError) => {
          console.error('Unable to fetch user session, redirecting to login', prettyError)
          this.props.dispatch(push(this.getLoginRedirectURL()))
        }
      )
    } else {
      // The "yat" cookie is the Yoco Auth Token (JWT) which can be used to auto-login the user in and initiate a session.
      // As an example, the yat is currently set in hello-yoco at the end of the signup funnel.
      const yat = getCookie('yat') || this.props.location.query.yat

      if (yat) {
        callJSONApi(
          '/user/auth',
          'POST',
          {
            service: 'portal',
            token: yat,
          },
          (response) => {
            this.setState({ isLoadingSession: false })
            saveSession(response.data)
            userLoggedIn(response.data.user)
            this.props.dispatch(loggedIn(response.data))
            this.props.dispatch(push(postLoginRedirectUrl))
          },
          (prettyError) => {
            console.log('Unable to generate long lived session from yat', prettyError)
            this.props.dispatch(push(this.getLoginRedirectURL()))
          }
        )
      } else if (!this.props.path.startsWith('/account')) {
        // You can access your account pages when you are not logged in
        console.log(`Atttempting to route to ${this.props.path} without a logged in user`)
        this.props.dispatch(push(this.getLoginRedirectURL()))
      }
    }
  }

  checkNextPath() {
    if (this.props.loggedIn) {
      const path = this.props.setupComplete ? '/dashboard' : '/onboarding'
      this.props.dispatch(push(path + this.props.location.search))
    } else if (!this.props.path.startsWith('/account')) {
      this.props.dispatch(push(`/account/login${this.props.location.search}`))
    }
  }

  render() {
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: this.getWarning() }} />
        {this.state.errorMessage ? (
          <PaddedContent isTextCentered>
            <Alert message={this.state.errorMessage} messageType='danger' />
          </PaddedContent>
        ) : null}
        {this.state.isLoadingSession ? (
          <FullscreenLoadingPage message='Loading your account information' />
        ) : null}
        {this.props.children}
        {this.getIE10()}
      </div>
    )
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.any,
  path: PropTypes.string,
  loggedIn: PropTypes.bool,
  setupComplete: PropTypes.bool,
  location: PropTypes.object,
}

export default connect((state) => ({
  path: state.routing.locationBeforeTransitions.pathname,
  loggedIn: state.session.get('loggedIn'),
  setupComplete: state.onboarding.get('setupComplete'),
  isInApp: wasRoutedFromApp(state),
}))(App)
