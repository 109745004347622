import React from 'react'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import { FORM_NAMES } from 'redux/modules/onboarding'
import { If } from 'libs/formats'

import UserDetailsCard from './components/UserDetailsCard'
import UploadIDDocumentType from './components/UploadIDDocumentType'
import DocumentUpload from './components/DocumentUpload'

const SA_CITIZEN_DATA = {
  southAfricanID: true,
  southAfricanPassport: false,
  passport: false,
  otherDocument: false,
  passportNumber: undefined,
}

const NON_SA_CITIZEN_DATA = {
  southAfricanID: false,
  southAfricanPassport: true,
  passport: false,
  otherDocument: false,
  idNumber: undefined,
}

const renderReadOnlyField = (name, label) => (
  <forms.ReadOnlyField name={name} label={label} disabled />
)

const renderOwnerInfoField = (name, label) => <forms.TextField name={name} label={label} required />

function UploadIDDocumentForm({
  setCitizenShip,
  southAfricanID,
  southAfricanPassport,
  ficaForms,
  isIDUploadPopupRendered,
  ficaVerificationRequest,
  businessDetails: { businessOwnerInfoIsMissing },
  validateIDNumber,
  isCitizenshipSelected,
}) {
  const onSetSACitizenship = () => setCitizenShip(FORM_NAMES.FICA_VERIFICATION, SA_CITIZEN_DATA)
  const onSetNonSACitizenship = () =>
    setCitizenShip(FORM_NAMES.FICA_VERIFICATION, NON_SA_CITIZEN_DATA)
  const cardTitle = southAfricanID
    ? 'Please enter your details as they are on your ID'
    : 'Please enter your details as they appear on your passport'

  return (
    <forms.Form name={FORM_NAMES.FICA_VERIFICATION}>
      <If condition={!isCitizenshipSelected && businessOwnerInfoIsMissing}>
        <UploadIDDocumentType
          setSACitizenShip={onSetSACitizenship}
          isSACitizen={southAfricanID}
          setForeignCitizenship={onSetNonSACitizenship}
          isForeignCitizen={southAfricanPassport}
          businessOwnerInfoIsMissing={businessOwnerInfoIsMissing}
        />
      </If>

      {businessOwnerInfoIsMissing ? (
        <UserDetailsCard cardTitle={cardTitle}>
          {renderOwnerInfoField('firstName', 'First name')}

          {renderOwnerInfoField('lastName', 'Surname')}

          {southAfricanID ? (
            <forms.TextField
              name='idNumber'
              label='Id number'
              maxLength={13}
              showRemaining={false}
              required
              validators={[
                new forms.RequiredValidator('This field is required'),
                new forms.NumberValidator('You must provide a number'),
              ]}
              onChange={validateIDNumber}
            />
          ) : (
            <forms.TextField
              name='passportNumber'
              label='Passport'
              showRemaining={false}
              required
            />
          )}
        </UserDetailsCard>
      ) : (
        <UserDetailsCard cardTitle={cardTitle}>
          {renderReadOnlyField('firstName', 'First name')}

          {renderReadOnlyField('lastName', 'Surname')}

          {ficaForms.getIn([FORM_NAMES.FICA_VERIFICATION, 'data', 'idNumber'])
            ? renderReadOnlyField('idNumber', 'Id number')
            : renderReadOnlyField('passportNumber', 'Passport')}
        </UserDetailsCard>
      )}
      <DocumentUpload
        ficaVerificationRequest={ficaVerificationRequest}
        ficaForms={ficaForms}
        isIDUploadPopupRendered={isIDUploadPopupRendered}
        southAfricanID={southAfricanID}
      />
    </forms.Form>
  )
}

UploadIDDocumentForm.propTypes = {
  setCitizenShip: PropTypes.func,
  southAfricanID: PropTypes.bool,
  southAfricanPassport: PropTypes.bool,
  ficaVerificationRequest: PropTypes.object,
  ficaForms: PropTypes.object,
  isIDUploadPopupRendered: PropTypes.bool,
  businessDetails: PropTypes.object,
  validateIDNumber: PropTypes.func,
  isCitizenshipSelected: PropTypes.bool,
}

export default UploadIDDocumentForm
