import * as React from 'react'

import classes from './flagship-error.module.scss'

function FlagShipBankStatementMessage() {
  return (
    <div className={classes.bankStatementMessageContainer}>
      <h2 className={classes.bankStatementMessageHeading}>Unable to take a photo?</h2>
      <div className={classes.bankStatementMessageInstructions}>
        <ol>
          <li>
            Go to <strong>portal.yoco.co.za </strong> on your mobile phone, tablet or desktop
          </li>
          <li>Type in the email and password you used to sign up for your Yoco account</li>
          <li>Upload outstanding documents in the bank details section</li>
        </ol>
      </div>
    </div>
  )
}

export default FlagShipBankStatementMessage
