import React from 'react'
import PropTypes from 'prop-types'

import SubHeading from 'ui/components/SubHeading'
import TaskCard from 'components/onboarding/TaskCard'
import filterTasks from 'components/onboarding/OnboardingTasks/utils'
import classes from 'components/onboarding/onboarding.module.scss'

function OnboardingTasks({
  title,
  tasks,
  isInApp,
  localisation,
  dispatch,
  forms,
  hasDigitalFica,
  onboardingPopupProps,
  hasPersonalisedAppHomeScreen,
}) {
  return (
    <div>
      <SubHeading>{title}</SubHeading>
      <div className={classes.taskCardsContainer}>
        {filterTasks({ tasks, isInApp, hasPersonalisedAppHomeScreen }).map((task) => {
          if (task && task.taskType) {
            return (
              <TaskCard
                task={task}
                forms={forms}
                key={task.taskType}
                /* eslint-disable-next-line react/forbid-component-props */
                dispatch={dispatch}
                localisation={localisation}
                hasDigitalFica={hasDigitalFica}
                onboardingPopupProps={onboardingPopupProps}
              />
            )
          }
          return null
        })}
      </div>
    </div>
  )
}

OnboardingTasks.propTypes = {
  title: PropTypes.string,
  tasks: PropTypes.object.isRequired,
  forms: PropTypes.object,
  localisation: PropTypes.object,
  isInApp: PropTypes.bool,
  hasDigitalFica: PropTypes.bool,
  onboardingPopupProps: PropTypes.object,
  dispatch: PropTypes.func,
  hasPersonalisedAppHomeScreen: PropTypes.bool,
}

export default OnboardingTasks
