/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-restricted-globals */
import numeral from 'numeral'
import $ from 'jquery'
import { Map } from 'immutable'
import moment from 'moment'
import { upperFirst, lowerCase } from 'lodash'

import CONSTANTS from 'libs/constants'
import { callCommonJSONAPI } from 'libs/api'
import { getQueryParamByName, getQueryParams } from 'libs/queryParams'

/**
 * First check if we have this environment variable set in a local file and return that
 */
export const getENV = (varName) =>
  process.env[`REACT_APP_${varName}`] ? process.env[`REACT_APP_${varName}`] : process.env[varName]

export const getCoreAPI = () => `${getENV('CORE_URL')}${getENV('API_ROOT_PATH')}`

export const isNewTheme = () => getENV('IS_NEW_THEME') || false

// eslint-disable-next-line
export let mouseIsDown = false

export function mouseDownHandler() {
  mouseIsDown = true
}

export function mouseUpHandler() {
  mouseIsDown = false
}

/**
 * Is this device smaller than $menu-toggle-width
 */
export function isSmallDevice() {
  return screen.width < 1020
}

export function isPhoneWidth() {
  return screen.width < 736
}

export function checkIfMobileDevice() {
  const lowerAgent = navigator.userAgent.toLowerCase()

  return (
    lowerAgent.indexOf('iphone') > 0 ||
    lowerAgent.indexOf('ipod') > 0 ||
    lowerAgent.indexOf('ipad') > 0 ||
    lowerAgent.indexOf('android') > 0
  )
}

export function checkIfWindowIsResponsive() {
  if (window) {
    return window.innerWidth < CONSTANTS.TOGGLE_WIDTH
  }
  return false
}

export function checkIfAppleDevice() {
  const lowerAgent = navigator.userAgent.toLowerCase()

  return (
    lowerAgent.indexOf('iphone') > 0 ||
    lowerAgent.indexOf('ipod') > 0 ||
    lowerAgent.indexOf('ipad') > 0
  )
}

export function scrollToTop(objectIDs = []) {
  objectIDs.forEach((id) => {
    $(`#${id}`).animate({ scrollTop: '0' }, 'fast')
  })
}

export function trackEvent(name, category) {
  const data = {
    name,
    category,
  }

  callCommonJSONAPI(
    '/tracking/event/',
    'POST',
    data,
    () => {},
    (prettyError) => {
      console.log(`Error tracking ${name}: ${prettyError}`)
    },
    true
  )
}

export function getProvinces(countryCode, onSuccess) {
  callCommonJSONAPI(
    `/provinces/?countryCode=${countryCode}`,
    'GET',
    {},
    onSuccess,
    (prettyError) => {
      console.log(`Error fetching provinces: ${prettyError}`)
    },
    true
  )
}

export function trackFeature(name, category) {
  const data = { name, category }

  callCommonJSONAPI(
    '/tracking/feature/',
    'POST',
    data,
    () => {},
    (prettyError) => {
      console.log(`Error tracking ${name}: ${prettyError}`)
    }
  )
}

/* eslint-disable */
export function generateUUID() {
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}
/* eslint-enable */

/*
 Strip any sensitive information from a dictionary so that we can log it
 */
export function sanitiseData(immutableData) {
  return immutableData.remove('password').remove('confirmPassword').remove('session')
}

export function friendlyDate(inputDate) {
  const m = moment(inputDate)
  return m.format('LL')
}

export function getPercentage(percentFor, percentOf) {
  return Math.floor((percentFor / percentOf) * 100) || 0
}

export function friendlyPercentage(percentage) {
  let formattedPercentage = percentage.toFixed(1)
  if (percentage < 0.1 && percentage >= 0.01) {
    formattedPercentage = percentage.toFixed(2)
  } else if (percentage < 0.01 && percentage > 0) {
    // Even if it is less than 0.01 we should round up and display it
    formattedPercentage = '<0.01'
  }

  return `${formattedPercentage} %`
}

export function dateDifference(firstDate, secondDate, format, precision = 2) {
  let diff = firstDate - secondDate
  switch (format) {
    case 'months':
      // NOTE: A month is assumed to have 30 days.
      diff = Math.round(diff / 1000 / 60 / 60 / 24 / 30, precision)
      break
    case 'days':
      diff = Math.round(diff / 1000 / 60 / 60 / 24, precision)
      break
    case 'hours':
      diff = Math.round(diff / 1000 / 60 / 60, precision)
      break
    case 'minutes':
      diff = Math.round(diff / 1000 / 60, precision)
      break
    case 'seconds':
      diff = Math.round(diff / 1000, precision)
      break
    default:
      break
  }
  if (getENV('NODE_ENV') === 'development') {
    console.log(
      'dateDifference: firstDate = ',
      firstDate,
      ' secondDate = ',
      secondDate,
      ' format = ',
      format,
      ' precision = ',
      precision,
      ' answer = ',
      diff
    )
  }
  return diff
}

export function formatCurrencyNoDecimals(value, currency = 'ZAR') {
  return formatCurrency(value, currency, false, 0)
}

export function formatCurrency(
  value = 0,
  currency = 'ZAR',
  makeFriendlyAmount = false,
  decimalPlaces = 2
) {
  /*
   When we move into other countries we shold do this better.
   Unfortunately toLocaleString is not implemented in React native and the
   Globalize module wouldn't install easily
   */

  if (!currency) {
    console.error('No currency passed in to formatCurrency')
  }

  if (currency === 'RWF') {
    decimalPlaces = 0 // eslint-disable-line
  }

  let v = round(value)
    .toFixed(decimalPlaces)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  if (v === 'NaN') {
    v = '0'
  }

  if (makeFriendlyAmount) {
    v = numeral(value).format('0.00a')
  }

  switch (currency) {
    case 'TZS':
      return `TSh ${v.replace(/ /g, ',')}`
    case 'RWF':
      return `${v} RWF`
    case 'USD':
      return `$ ${v}`
    case 'GBP':
      return `£ ${v}`
    case 'EUR':
      return `€ ${v}`
    case 'ZAR':
    default:
      return `R ${v}`
  }
}

export function round(value) {
  return Math.round(value * 100.0) / 100.0
}

export function orEmptyMap(something) {
  return something || Map()
}

export function objectKeysToLowerCase(obj) {
  if (typeof obj === 'undefined') {
    return {}
  }
  // eslint-disable-next-line one-var,one-var-declaration-per-line,prefer-const
  let key
  const keys = Object.keys(obj)
  let n = keys.length
  const newobj = {}
  // eslint-disable-next-line no-plusplus
  while (n--) {
    key = keys[n]
    newobj[key.toLowerCase()] = obj[key]
  }
  return newobj
}

export function prettyPrintNumber(number) {
  return number ? number.toLocaleString('en-UK').replace(',', ' ') : '0'
}

export function toTitleCase(str) {
  return str.replace(/\b\w/g, (s) => s.toUpperCase())
}

/**
 * Get a pretty settlement string e.g 7am Wednesday, 17 Sep - 7pm Thursday, 18 Sep for current date
 */
export function getCurrentSettlementRange() {
  const settlementPeriod = moment()
  let startDate
  let endDate
  if (isTimeBetween7And12pm()) {
    startDate = settlementPeriod.format('dddd, DD MMM')
    endDate = settlementPeriod.add(1, 'days').format('dddd, DD MMM')
  } else {
    startDate = moment().subtract(1, 'days').format('dddd, DD MMM')
    endDate = moment().format('dddd, DD MMM')
  }
  return `7pm ${startDate} - 7pm ${endDate}`
}

/**
 *
 * Check if time current time is between 7pm and 12pm
 */
export function isTimeBetween7And12pm() {
  const format = 'HH:mm:ss'
  const date = moment()
  const currentTime = moment(date.format(format), format)
  const cutOffTime = moment('19:00:00', format)
  const midnight = moment('23:59:59', format)
  return currentTime.isBetween(cutOffTime, midnight)
}

/**
 * Create a test identifier, with multiple optional nested IDs.
 *
 * The suggested convention is to name test IDs in a hierarchical fashion that
 * roughly conforms to `<coarseNavigation>-<fineNavigation>-<intent>`, some examples:
 *
 * - menu-profile-email: "Email" field on the "My Account" page
 * - menu-logout: "Logout" button in the menu.
 * - menu-manageStore-products: "Products" sub-item in the "Manage
 *   My Store" menu.
 *
 * @param {String} parentTestID - Parent test ID.
 * @param {Array<String>} ...subIDs - Optional additional sub-level test IDs.
 * @return {String} New test ID. If `parentTestID` is false-y then `undefined`
 * is returned; this is a convenience to avoid having to include conditional
 * logic at the site of creating the testID.
 */
export const makeTestID = (parentTestID, ...subIDs) => {
  const normalizeID = (id) => id.replace(/\s/g, '_')
  return parentTestID
    ? subIDs.reduce(
        (testID, subID) => (subID ? `${testID}-${normalizeID(subID)}` : testID),
        parentTestID
      )
    : undefined
}

/**
 * Check if a query param `flavour` is present,
 * we use use this to determine if a flavour has been set so we can give user a
 * different experience
 */
export const flavour = getQueryParamByName('flavour')

/**
 * Used to let the Portal know it`s running from the flagship/Khumo device
 * */
export const isFlagship = flavour?.toLowerCase() === 'flagship'

/**
 * Check if a query param `shouldRemoveLoader` is present,
 * we use this to know if we should post a `removeLoader` message to
 * RN web view.
 */
export const shouldRemoveLoader = getQueryParamByName('shouldRemoveLoader') === 'true'

/**
 * Make a reasonable attempt to mask an email address for display purposes.
 */
export function maskEmailAddress(email) {
  return email.replace(/([^@]{1,2})(.*?)@([^.]{1,2})(.*?)\.(.*)/, '$1**@$3**.$5')
}

const shopifyUrlPath = 'shopify/install'

export const isShopify = () => {
  const params = getQueryParams(true)
  const next = params.next || ''
  return next.includes(shopifyUrlPath)
}

/**
 * Takes a camel case string such as "mobileNumber" and returns "Mobile number"
 */
export const formatCamelCaseToSentenceCase = (string) =>
  upperFirst(lowerCase(string.replace(/([A-Z])/g, ' $1')))
