/* eslint-disable react/no-array-index-key */
import React from 'react'

import ClipboardFriendlyLabel from 'components/labels/ClipboardFriendlyLabel'
import { TASK_STEPS } from 'redux/modules/onboarding'
import classes from 'components/onboarding/onboarding.module.scss'

import EftUploadComponent from './eftUpload/EftUploadComponent'

export default function EftPaymentInlineActionContent(task, localisation, dispatch) {
  const displayFlex = {
    display: 'flex',
    flexGrow: 1,
  }

  switch (task.step) {
    case TASK_STEPS.IN_PROGRESS: {
      return (
        <div className={classes.inProgressHeader}>
          <div className={classes.progressBlockContainer}>
            <p className={classes.progressBlockText}>
              Our team is processing your proof of payment. This could take up to{' '}
              <strong>48 hours.</strong>
            </p>
          </div>
        </div>
      )
    }
    case TASK_STEPS.TODO: {
      // given a string, inserts right before the uppercase a replacer
      const replaceUpperCase = (string, replacer) => string.replace(/([A-Z])/g, `${replacer}$1`)

      // list of labels that would be displayed when displaying yoco's eft info.
      // Given a bunch of keys, we generate the name and value,
      // generating it into an array of objects
      const clipboardLabelData = [
        'AccountName',
        'AccountNumber',
        'Bank',
        'BranchCode',
        'ReferenceNumber',
        'Amount',
      ].map((key) => ({
        key,
        name: replaceUpperCase(key, ' ').trim(),
        value: task.payload[replaceUpperCase(key, '_').substr(1).toLowerCase()],
      }))

      return (
        <div>
          <div className={classes.paymentActionHeading}>
            <p className={classes.ordersHeader}>
              A <span>proof of payment</span> should clearly show that you have made a payment into
              the following account:
            </p>
          </div>
          <div className={classes.inlineActionDetailsContainer}>
            <table className={classes.paymentActionDataContainer}>
              <tbody>
                {clipboardLabelData.map((clipboardProps, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <ClipboardFriendlyLabel containerStyle={displayFlex} {...clipboardProps} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {renderEftUploadComponent(dispatch, task.payload.reference_number, task.step)}
        </div>
      )
    }
    default:
      return <div />
  }
}

function renderEftUploadComponent(dispatch, referenceNumber) {
  return (
    <EftUploadComponent
      // eslint-disable-next-line react/forbid-component-props
      dispatch={dispatch}
      referenceNumber={referenceNumber}
    />
  )
}
