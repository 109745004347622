import React from 'react'
import PropTypes from 'prop-types'

import LoadingView from 'components/loaders/LoadingView'

import classes from './styles.module.scss'

function IidentifiiIFrame({ iFrameUrl, iFrameIsLoading, title }) {
  const viewportWidth = window.innerWidth
  const mobileHeight = window.innerHeight
  // 800 is the height of iiDENTIFii content
  const desktopHeight = 800

  // '736' comes from $phone-width in variables.scss
  const isMobileView = viewportWidth < 736

  const iframeHeight = isMobileView ? mobileHeight : desktopHeight

  const iframe = {
    __html: `<iframe src='${iFrameUrl}' id='iidentifiiIframe' allow='camera; fullscreen' title='${title}' style='width:100%;height:${iframeHeight}px; border: none;' />`,
  }

  // Using dangerouslySetInnerHTML because without it the allow='camera'
  // gets stripped and React 15 does not see 'allow' as a prop
  return (
    <div>
      <div className={classes.iframeContainer} dangerouslySetInnerHTML={iframe} />
      {iFrameIsLoading && <LoadingView message='Loading, Please wait.' />}
    </div>
  )
}

IidentifiiIFrame.propTypes = {
  iFrameUrl: PropTypes.string,
  iFrameIsLoading: PropTypes.bool,
  title: PropTypes.string,
}

export default IidentifiiIFrame
