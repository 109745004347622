import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { TASK_TYPES } from 'redux/modules/onboarding'
import { FirebaseHandler, features } from 'libs/firebase'
import { isDeliveryMethodCcdSelector } from 'redux/modules/onboarding/selectors'
import { businessDetails } from 'redux/modules/session/selectors'
import DatecsSetupTaskInlineAction from 'components/onboarding/inlineActionContent/datecssetup/DatecsSetupTaskInlineAction'
import { hasFeature } from 'libs/features'

import PosDownloadInlineAction from './inlineActionContent/PosDownloadTaskInlineAction'
import EftPaymentInlineActionContent from './inlineActionContent/EftPaymentTaskInlineAction'
import IdentityConfirmationInlineActionContent from './inlineActionContent/IdentityConfirmationTaskInlineAction'
import classes from './onboarding.module.scss'

class TasksInlineContent extends Component {
  inlineContent() {
    switch (this.props.task.taskType) {
      case TASK_TYPES.PAYMENT:
        return EftPaymentInlineActionContent(
          this.props.task,
          this.props.localisation,
          this.props.dispatch
        )
      case TASK_TYPES.FICA:
        return IdentityConfirmationInlineActionContent(
          this.props.task,
          this.props.isDeliveryMethodCcd,
          this.props.hasDigitalFica,
          this.props.forms
        )
      case TASK_TYPES.APP_SETUP:
        return PosDownloadInlineAction(this.props.task)
      case TASK_TYPES.DATECS_SETUP:
        return DatecsSetupTaskInlineAction(this.props.task)
      default:
        return null
    }
  }

  render() {
    return <div className={classes.expandedContainer}>{this.inlineContent()}</div>
  }
}

TasksInlineContent.propTypes = {
  localisation: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  forms: PropTypes.object,
  dispatch: PropTypes.func,
  isDeliveryMethodCcd: PropTypes.bool,
  hasDigitalFica: PropTypes.bool,
}

const ReduxTasksInlineContent = connect((state) => ({
  businessDetails: businessDetails(state),
  isDeliveryMethodCcd: isDeliveryMethodCcdSelector(state),
  hasDigitalFica: hasFeature(state, 'digitalFICA'),
}))(TasksInlineContent)

export default FirebaseHandler.connect(
  ReduxTasksInlineContent,
  Map({
    features,
  })
)
