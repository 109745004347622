import * as React from 'react'
import PropTypes from 'prop-types'

import { DELIVERY_STATUS, PAYMENT_STATE } from 'redux/modules/onboarding'

import classes from './onboarding.module.scss'
import DeliveryStatusContent from './DeliveryStatusContent'

const STATUS = {
  AWAITING_PAYMENT: 'awaitingPayment',
  SCHEDULING_DELIVERY: 'schedulingDelivery',
  DELIVERY_SCHEDULED: 'deliveryScheduled',
  DELIVERED: 'delivered',
  PENDING: 'pending',
}

const LINE_TYPE = {
  DOTTED: 'DOTTED',
  HALF_DOTTED: 'HALF_DOTTED',
  FULL: 'FULL',
}

function Line({ type }) {
  if (type === LINE_TYPE.DOTTED) {
    return (
      <div className={classes.line}>
        <div className={classes.fullDotted} />
      </div>
    )
  }

  if (type === LINE_TYPE.HALF_DOTTED) {
    return (
      <div className={classes.line}>
        <div className={classes.full} />
        <div className={classes.dotted} />
      </div>
    )
  }

  if (type === LINE_TYPE.FULL) {
    return (
      <div className={classes.line}>
        <div className={classes.full} />
        <div className={classes.full} />
      </div>
    )
  }

  return undefined
}

const titles = {
  awaitingPayment: 'Awaiting payment',
  schedulingDelivery: 'Proof of Payment Pending',
  deliveryScheduled: 'Appointment scheduled',
  delivered: 'Delivered!',
  pending: 'Pending',
}

function Content({ status }) {
  if (status === STATUS.AWAITING_PAYMENT) {
    return (
      <div>
        <p>
          Your courier will contact you to schedule an appointment for delivery, once your proof of
          payment is verified.
        </p>
      </div>
    )
  }

  if (status === STATUS.SCHEDULING_DELIVERY) {
    return (
      <div>
        <p>Your payment has been verified!</p>
        <p>Expect a phone call from CCD Couriers to schedule a time and date for your delivery.</p>
      </div>
    )
  }

  if (status === STATUS.DELIVERY_SCHEDULED) {
    return (
      <div>
        <p>Thank you for scheduling an appointment with CCD Couriers.</p>
      </div>
    )
  }

  if (status === STATUS.DELIVERED) {
    return (
      <div>
        <p>We are so happy that you received your card machine.</p>
        <p>Download the app and you&apos;ll be able to start accepting card payments!</p>
      </div>
    )
  }

  return (
    <div>
      <p>Didn&#39;t receive your card machine yet?</p>
      <br />
      <p>Start a live chat below or contact our support line:</p>
      <p>087 550 9626 (09h00 - 22h00)</p>
    </div>
  )
}

function DeliveryStatusCard({ fulfillmentState, paymentState }) {
  const deliveryStatus = React.useMemo(() => {
    if (paymentState === PAYMENT_STATE.PENDING || paymentState === PAYMENT_STATE.INCOMPLETE) {
      return STATUS.AWAITING_PAYMENT
    }
    if (
      fulfillmentState === DELIVERY_STATUS.ASSIGNING_READERS ||
      fulfillmentState === DELIVERY_STATUS.ORDER_PLACED ||
      fulfillmentState === DELIVERY_STATUS.AWAITING_VETTING_APPROVAL ||
      fulfillmentState === DELIVERY_STATUS.PICKING_AND_PACKING ||
      fulfillmentState === DELIVERY_STATUS.ON_HOLD
    ) {
      return STATUS.SCHEDULING_DELIVERY
    }
    if (
      fulfillmentState === DELIVERY_STATUS.DISPATCHED ||
      fulfillmentState === DELIVERY_STATUS.DISPATCHED_NO_EMAIL
    ) {
      return STATUS.DELIVERY_SCHEDULED
    }
    if (fulfillmentState === DELIVERY_STATUS.DELIVERED) {
      return STATUS.DELIVERED
    }
    return STATUS.PENDING
  }, [fulfillmentState, paymentState])

  return (
    <DeliveryStatusContent
      title={titles[deliveryStatus] ?? 'Pending'}
      content={<Content status={deliveryStatus} />}
    />
  )
}

Line.propTypes = {
  type: PropTypes.string,
}

Content.propTypes = {
  status: PropTypes.string,
}

DeliveryStatusCard.propTypes = {
  fulfillmentState: PropTypes.string,
  paymentState: PropTypes.string,
}

export default DeliveryStatusCard
