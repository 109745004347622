import React from 'react'
import PropTypes from 'prop-types'

import { track } from 'libs/analytics'

import classes from './styles.module.scss'

function Content({ firstName, lastName, idNumber }) {
  const onClickSupport = () => track('portal_fica_iidentifii_click_button_contact_support')
  return (
    <div>
      <p>We&apos;re verifying your details and will respond soon.</p>

      <div className={classes.iidentifiiInfo}>
        <div>
          <p className={classes.infoLabel}>First name</p>
          <p className={classes.infoValue}>{firstName}</p>
        </div>
        <div>
          <p className={classes.infoLabel}>Last name</p>
          <p className={classes.infoValue}>{lastName}</p>
        </div>
        <div>
          <p className={classes.infoLabel}>ID Number</p>
          <p className={classes.infoValue}>{idNumber}</p>
        </div>
      </div>
      <p>
        Something on your details not right? Contact support at{' '}
        <a
          href='mailto:support@yoco.co.za'
          target='_blank'
          rel='noopener noreferrer'
          onClick={onClickSupport}
        >
          support@yoco.co.za
        </a>
      </p>
    </div>
  )
}

Content.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  idNumber: PropTypes.string,
}

export default Content
