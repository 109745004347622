import React from 'react'

import classes from 'components/onboarding/onboarding.module.scss'
import captureIcon from 'assets/images/Capture.png'
import idIcon from 'assets/images/ID.png'

export default function CCDPrepareYourIdComponent() {
  return (
    <div>
      <div>
        <div className={classes.ficaBlockContainer}>
          <div className={classes.ficaInnerBlock}>
            <div className={classes.ficaInnerImageBlock}>
              <img src={captureIcon} alt='' />
            </div>
            <div className={classes.ficaInnerContentBlock}>
              <div className={classes.ficaInnerBlockTitle}>
                <p>CCD Couriers will take a picture of your ID</p>
              </div>
              <div className={classes.ficaInnerBlockDescription}>
                <p>CCD Couriers will capture your ID when they deliver your card machine.</p>
              </div>
            </div>
          </div>
          <div className={classes.ficaInnerBlock}>
            <div className={classes.ficaInnerImageBlock}>
              <img src={idIcon} alt='' />
            </div>
            <div className={classes.ficaInnerContentBlock}>
              <div className={classes.ficaInnerBlockTitle}>
                <p>Accepted Identity Documents</p>
              </div>
              <div className={classes.ficaInnerBlockDescription}>
                <ul>
                  <li>South African ID</li>
                  <li>Passport</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.bottomCTA}>
        <p className={classes.helperTextCopy}>
          <a
            href='https://get.yoco.help/hc/en-za/articles/115005970129-FICA-Requirements'
            rel='noopener noreferrer'
            target='_blank'
          >
            Click here
          </a>
          {' for more information.'}
        </p>
      </div>
    </div>
  )
}
