import { fromJS } from 'immutable'

import { callJSONApi } from 'libs/api'
import { initializeForm } from 'redux/modules/forms'

import { FORM_NAMES } from '../index'

import {
  BUSINESS_ADDRESS_ACTIONS,
  updateBusinessTradingAddress,
  launchAddressPopup,
  updateBusinessAddressSubmitErrorMessage,
} from './actions'

const fetchBusinessDetails = () => (dispatch) => {
  callJSONApi(
    '/business/',
    'GET',
    {},
    (response) => {
      const { tradingAddress, deliveryAddress } = response.data.business
      if (tradingAddress) {
        dispatch(updateBusinessTradingAddress(fromJS(tradingAddress)))
      } else if (deliveryAddress) {
        dispatch(initializeForm(FORM_NAMES.BUSINESS_ADDRESS, deliveryAddress))
      }
    },
    (error) => console.log('Failed to fetch business address ', error)
  )
}

export {
  BUSINESS_ADDRESS_ACTIONS,
  updateBusinessTradingAddress,
  launchAddressPopup,
  updateBusinessAddressSubmitErrorMessage,
  fetchBusinessDetails,
}
