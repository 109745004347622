import React from 'react'
import S from 'string'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Immutable, { Map, List } from 'immutable'

import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import { FirebaseTable } from 'libs/tables'
import { FirebaseHandler } from 'libs/firebase'
import AddItemsPopup from 'components/popups/AddItemsPopup'
import TileCheckboxRow from 'components/rows/TileCheckboxRow'
import { callJSONApi } from 'libs/api'
import TileEditorFormField from 'components/tiles/TileEditorFormField'
import { addItem, removeItem, refreshTable, itemUpdated } from 'redux/modules/tables'
import { initializeForm, showError } from 'redux/modules/forms'
import { addToLayout, removeFromLayout } from 'libs/layouts/pos'
import CONSTANTS from 'libs/constants'
import FormCard from 'components/cards/FormCard'
import ConnectedObjectComponent from 'libs/firebase/ConnectedObjectComponent'
import { makeTestID } from 'libs/utils'

import classes from './store.module.scss'
import { COLLECTIONS_TABLE_NAME } from './CollectionsPage'

class EditCollectionForm extends ConnectedObjectComponent {
  constructor(props) {
    super(props)

    this.removedItemsList = List()
    this.hidePopup = this.hidePopup.bind(this)
    this.onAddItemsToCollection = this.onAddItemsToCollection.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps)
    if (nextProps.localObject !== this.props.localObject) {
      this.props.dispatch(refreshTable(COLLECTIONS_TABLE_NAME))
      this.removedItemsList = List()
    }
    if (nextProps.collectionType !== this.props.collectionType) {
      this.props.dispatch(
        initializeForm(
          nextProps.formName,
          nextProps.reduxFormData
            .setIn(['tile', 'tileType'], nextProps.collectionType)
            .set('collectionType', nextProps.collectionType)
        )
      )
    }
  }

  updateErrorMessageState(message) {
    this.props.dispatch(showError(this.props.formName, message))
  }

  onClose(response) {
    this.addItemsToCollectionInFirebase(response)

    if (this.props.closeSplitView) {
      this.props.closeSplitView()
    }
    this.props.dispatch(refreshTable(this.props.formName))
  }

  onSaveCollection(response) {
    if (response && response.data && response.data.collection) {
      if (!this.props.localObject) {
        // This was a new item we must make sure to add it to our table
        this.props.dispatch(
          addItem(this.props.formName, Immutable.fromJS(response.data.collection))
        )
      } else {
        // We need to update our item in our table
        this.props.dispatch(
          itemUpdated(this.props.formName, Immutable.fromJS(response.data.collection))
        )
      }

      /**
       * Update the products and/or categories that are associated
       */
      const collectionItemsData = this.props.collectionItemsTable
        .get('data')
        .concat(this.props.collectionItemsTable.get('addedItems'))

      if (collectionItemsData.size > 0 || this.removedItemsList.size > 0) {
        let api
        let fieldName

        switch (response.data.collection.collectionType) {
          case 'brand':
            api = 'updateBrandOnItems'
            fieldName = 'brandUUID'
            break
          case 'product_category':
            api = 'updateCategoryOnItems'
            fieldName = 'productCategoryUUID'
            break
          default:
            break
        }
        const { businessUUID } = response.data.collection
        const itemUUIDs = collectionItemsData
          .map((item) => item.get('targetUUID'))
          .toSetSeq()
          .toList()

        const data = {
          businessUUID,
          itemUUIDs: itemUUIDs.toJS(),
        }
        data[fieldName] = response.data.collection.uuid

        if (
          response.data.collection.collectionType === 'brand' ||
          response.data.collection.collectionType === 'product_category'
        ) {
          this.updateCollectionOnItems(api, data, response)
          this.removeItemsFromCollection(api, businessUUID, fieldName)
        }
      }
    }
  }

  removeItemsFromCollection(api, businessUUID, fieldName) {
    /*
      This methods unsets products from a brand or category and updates firebase
     */
    if (this.removedItemsList.size > 0) {
      const data = {
        businessUUID,
        itemUUIDs: this.removedItemsList.map((item) => item.get('targetUUID')).toJS(),
      }
      data[fieldName] = null
      this.updateCollectionOnItems(api, data)
    }
  }

  updateCollectionOnItems(api, data, responseData) {
    /*
      Posting this brands and categories update to core can can sometimes cause a race condition 
       syncing to Firebase
      We need to delay our post to core to give other updates to Firebase time to complete first
     */
    setTimeout(() => {
      callJSONApi(
        `/collections/${api}`,
        'POST',
        data,
        (response) => {
          if (response && response.status === 200) {
            console.info(api, 'succeeded', data)
            this.onClose(responseData)
          }
        },
        (prettyError) => {
          if (prettyError) {
            console.error(api, 'failed', prettyError)
            this.updateErrorMessageState(prettyError)
          }
        }
      )
    }, 300)
  }

  addItemsToCollectionInFirebase(response) {
    if (response.data.collection.collectionType === 'layout') {
      const collection = this.getConnectedObject() || Map()
      const collectionUUID = response.data.collection.uuid
      let updatedLayout = collection.getIn(['layouts', 'universal'], Map())

      const collectionItems = this.props.collectionItemsTable
      if (collection && this.removedItemsList.size > 0) {
        // Replace the items that have been removed with placeholders
        this.removedItemsList.forEach((item) => {
          updatedLayout = removeFromLayout(updatedLayout, item)
        })
      }

      if (collectionItems && collectionItems.get('addedItems', Map()).size > 0) {
        // Add the items to the collection layout in Firebase
        collectionItems.get('addedItems').forEach((item) => {
          updatedLayout = addToLayout(updatedLayout, item)
        })
      }

      // Push our updated layout to Firebase
      if (updatedLayout) {
        FirebaseHandler.writeToPath(
          `readWrite/store/collections/${collectionUUID}/layouts/universal`,
          updatedLayout.toJS()
        )
        console.log(`Collections> FirebaseHandler> Updated ${collectionUUID} layout in Firebase`)
      }
    }
  }

  onAddItemsToCollection(selectedTiles) {
    if (selectedTiles && selectedTiles.size > 0) {
      // selectedTiles is the list of tiles selected from the Add Items popup
      selectedTiles.forEach((tile) => this.props.dispatch(addItem(COLLECTIONS_TABLE_NAME, tile)))
    }
    this.hidePopup('showAddItemsModal')
  }

  onRemoveFromCollection() {
    this.getSelectedItems().forEach((item) => {
      this.removedItemsList = this.removedItemsList.push(item)
      this.props.dispatch(removeItem(COLLECTIONS_TABLE_NAME, item.get('uuid')))
    })
  }

  showPopup() {
    this.setState({
      showAddItemsModal: true,
    })
  }

  hidePopup() {
    this.setState({
      showAddItemsModal: false,
    })
  }

  getFilterItemsData() {
    /*
      Returns the type of tile type we need to filter our list on
     */
    const tileType = this.props.reduxFormData.getIn(['tile', 'tileType'], 'collection')

    switch (tileType) {
      case 'brand':
        return 'product'
      case 'product_category':
        return `product,product_category`
      default:
        return undefined
    }
  }

  addItemsPopUp() {
    const itemUUID = (this.getConnectedObject() || Map()).get('tileUUID')
    return (
      <AddItemsPopup
        showing={this.state.showAddItemsModal}
        tableName={COLLECTIONS_TABLE_NAME}
        filter={this.getFilterItemsData()}
        itemToRemoveUUID={itemUUID}
        onCancel={this.hidePopup}
        onConfirmAddCollection={this.onAddItemsToCollection}
      />
    )
  }

  getSelectedItems() {
    if (this.props.collectionItemsTable && !this.props.collectionItemsTable.get('hasNoData')) {
      return this.props.collectionItemsTable
        .get('data', List())
        .concat(this.props.collectionItemsTable.get('addedItems', List()))
        .filter((row) => row && row.get('itemIsSelected') === true)
    }
    return List()
  }

  getCollectionItemsTableData() {
    const collection = this.getConnectedObject() || Map()
    let itemsInCollection = List()
    if (collection.get('collectionType') !== 'layout') {
      itemsInCollection = collection
        .get('tiles', List())
        .filter((tile) => tile)
        .map((tile, uuid) => {
          return tile.set('uuid', uuid).set('tileUUID', uuid)
        })
        .sort((a, b) => {
          if (a && b) {
            return b.get('tileType') - a.get('tileType') || a.get('name') - b.get('name')
          }
          return 0
        })
    } else if (collection.get('collectionType') === 'layout') {
      itemsInCollection = collection
        .getIn(['layouts', 'universal', 'layoutTiles'], List())
        .filter((layoutTile) => layoutTile.get('tile'))
        .map((layoutTile) => {
          return layoutTile.get('tile')
        })
        .sort((a, b) => {
          if (a && b) {
            return b.get('tileType') - a.get('tileType') || a.get('name') - b.get('name')
          }
          return 0
        })
    }

    // Because the removed items are sitting in a list, keep the table data updated by filtering
    //  out those removed items
    if (this.props.collectionItemsTable && this.removedItemsList.size > 0) {
      itemsInCollection = itemsInCollection.filter((tile) => {
        return (
          this.removedItemsList.filter(
            (removedItem) => removedItem.get('uuid') === tile.get('uuid')
          ).size === 0
        )
      })
    }

    return itemsInCollection.toSetSeq().toList()
  }

  getAddItemsText() {
    const collectionItemsTable = this.props.collectionItemsTable
      .get('data', List())
      .concat(this.props.collectionItemsTable.get('addedItems'))
    if (collectionItemsTable.size === 0) {
      const tileType = this.props.reduxFormData.getIn(['tile', 'tileType'], 'collection')
      const text =
        tileType === 'brand'
          ? 'There are currently no products in this brand'
          : `There are currently no items in this ${tileType.substring(tileType.indexOf('_') + 1)}`
      return <div style={{ textAlign: 'center' }}>{text}</div>
    }
    return <span />
  }

  renderAddItems() {
    const collectionItems = this.props.collectionItemsTable || Map()
    const selectedItemsSize = this.getSelectedItems().size
    const collectionItemsData = collectionItems
      .get('data', List())
      .concat(collectionItems.get('addedItems', List()))

    const collectionType = this.props.reduxFormData
      .getIn(['tile', 'tileType'], 'collection')
      .replace('_', ' ')
    const addToCollectionButton = (
      <FlatButton
        label={`Add item(s) to ${collectionType}`}
        type='button'
        className='tertiary'
        onClick={() => this.showPopup()}
      />
    )
    const removeItemsLabel =
      selectedItemsSize === 1
        ? `Remove ${selectedItemsSize} Item`
        : `Remove ${selectedItemsSize} Items`
    const showButtonsContainer = collectionItemsData.size > 0 ? {} : { display: 'none' }

    return (
      <div className={classes.addItemsToCollection}>
        <FirebaseTable
          useOffset
          name={COLLECTIONS_TABLE_NAME}
          hasCustomData
          rowsClickable
          hasSelectors
          firebaseJoin={Map({ uuid: 'tile' })}
          waitForFilterLoad={false}
          showEmptyText={false}
          emptyTable={
            <div className={`${classes.buttonsContainer} ${classes.addItems} text-center`}>
              {addToCollectionButton}
            </div>
          }
          data={this.getCollectionItemsTableData()}
          getSelectionHeader
          getRow={(immutableRow, rowIndex) => {
            let className = ''
            if (!(rowIndex % 2)) {
              className = ` row-background`
            }
            return (
              <TileCheckboxRow
                key={`row${rowIndex}-${immutableRow.get('uuid')}`}
                tableName={COLLECTIONS_TABLE_NAME}
                className={className}
                tile={immutableRow}
                onMouseDown={this.props.mouseDownHandler}
                onMouseUp={this.props.mouseUpHandler}
              />
            )
          }}
        />
        <div
          className={`${classes.buttonsContainer} ${classes.addItems}`}
          style={showButtonsContainer}
        >
          <FlatButton
            label={removeItemsLabel}
            type='button'
            disabled={selectedItemsSize === 0}
            className='destructive'
            onClick={() => this.onRemoveFromCollection()}
          />
          <span />
          {addToCollectionButton}
        </div>
      </div>
    )
  }

  getParentCategory() {
    const uuid = (this.getConnectedObject() || Map()).get('uuid')
    if (
      this.props.reduxFormData &&
      this.props.reduxFormData.getIn(['tile', 'tileType']) === 'product_category'
    ) {
      return (
        <forms.SelectField
          name='parentUUID'
          label='Parent Category'
          api='/tiles/?tileType=product_category'
          useTargetUUID
          filterOption={{ uuid }}
          placeholder='Select parent (e.g. Drinks is a parent of Whiskey)'
          isFullWidth
          searchable
          clearable
        />
      )
    }
    return <span />
  }

  inititalDataProcessor(data) {
    let newData = data

    if (!newData.get('uuid')) {
      newData = newData
        .setIn(['tile', 'tileType'], this.props.collectionType)
        .set('collectionType', this.props.collectionType)
    }

    return newData.merge(
      Map({
        name: newData.getIn(['tile', 'name']),
        imageText: newData.getIn(['tile', 'imageText']),
        imageIcon: newData.getIn(['tile', 'imageIcon']),
        imageURL: newData.getIn(['tile', 'imageURL']),
        imageFileUUID: newData.getIn(['tile', 'imageFileUUID']),
        backgroundColor: newData.getIn(['tile', 'backgroundColor']) || CONSTANTS.YOCO_DEFAULT_COLOR,
        collectionUUID: newData.get('uuid'),
        addBlankCollection: false,
        addToStorefront: !newData.get('uuid'),
      })
    )
  }

  render() {
    const shownRow = this.getConnectedObject()
    const tileType = this.props.reduxFormData.getIn(['tile', 'tileType'], 'collection')
    const collectionType =
      tileType.indexOf('_') < 0 ? tileType : tileType.substring(tileType.indexOf('_') + 1)

    return (
      <div className={classes.viewPanel}>
        <forms.Form
          action='/layout/collection/'
          name={this.props.formName}
          initialData={shownRow}
          initialDataProcessor={(data) => this.inititalDataProcessor(data, tileType)}
          onSuccess={(response) => this.onSaveCollection(response)}
        >
          <FormCard header='Basic Info'>
            <div className='clearfix'>
              <forms.TextField
                name='name'
                label='Name'
                placeholder='Name'
                required
                isFullWidth
                validators={[new forms.RequiredValidator('You must provide a name')]}
                testID={makeTestID('menu', 'brandsPage', 'name')}
              />
              {this.getParentCategory()}
            </div>
          </FormCard>
          <FormCard header='Product Image' style={{ minHeight: '250px' }}>
            <TileEditorFormField
              tile={this.props.reduxFormData}
              formName={this.props.formName}
              mouseDownHandler={this.props.mouseDownHandler}
              mouseUpHandler={this.props.mouseUpHandler}
            />
          </FormCard>
          <FormCard header={`${S(collectionType).titleCase()} Items`}>
            {this.getAddItemsText()}
            {this.renderAddItems()}
          </FormCard>
        </forms.Form>
        {this.addItemsPopUp()}
      </div>
    )
  }
}

export default connect((state, props) => ({
  reduxFormData: state.forms.getIn([props.formName, 'data'], Map({})),
  errorMessage: state.forms.getIn([props.formName, 'errorMessage']),
  collectionItemsTable: state.tables.get(COLLECTIONS_TABLE_NAME, Map({})),
}))(EditCollectionForm)

EditCollectionForm.propTypes = {
  shownRow: PropTypes.object,
  dispatch: PropTypes.func,
  closeSplitView: PropTypes.func,
}
