import React from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.scss'

function UserDetailsCard({ cardTitle, children }) {
  return (
    <div className='clearfix'>
      <h2 className={classes.cardTitle}>{cardTitle}</h2>
      {children}
    </div>
  )
}

UserDetailsCard.propTypes = {
  cardTitle: PropTypes.node,
  children: PropTypes.node,
}

export default UserDetailsCard
