import { createSelector } from 'reselect'
import { Map } from 'immutable'

import { FORM_NAMES } from 'redux/modules/onboarding'

const formsNode = (state) => state.forms || Map()
const onboardingNode = (state) => state.onboarding

export const businessAddress = createSelector(
  onboardingNode,
  (onBoardingData) =>
    (onBoardingData && onBoardingData.getIn(['businessAddress', 'address'])) || Map()
)

export const disableBusinessAddress = createSelector(formsNode, (formsData) => {
  const { firstLine, secondLine, city, postalCode } = (
    formsData.getIn([FORM_NAMES.BUSINESS_ADDRESS, 'data']) || Map()
  ).toJS()
  const { avgTotalMonthlyRevenue } = (
    formsData.getIn([FORM_NAMES.BUSINESS_REVENUE, 'data']) || Map()
  ).toJS()

  return !(firstLine && secondLine && city && postalCode && avgTotalMonthlyRevenue >= 0)
})

export const shouldLaunchAddressPopup = createSelector(onboardingNode, (onBoardingData) =>
  onBoardingData.getIn(['businessAddress', 'showAddressPopup'])
)
