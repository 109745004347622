import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { FORM_NAMES, fetchOnboardingTasks } from 'redux/modules/onboarding'
import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'

import Content from './components/Content'

function IidentifiiCompletePopup({
  headerTitle,
  buttonLabel,
  onSetIidentifiiCompletePopupVisible,
  isIidentifiiCompletePopupVisible,
  forms,
  onFetchOnboardingTasks,
}) {
  const closePopup = () => {
    onSetIidentifiiCompletePopupVisible(false)
    onFetchOnboardingTasks()
  }

  const firstName =
    forms.getIn([FORM_NAMES.IIDENTIFII, 'data', 'personal', 'firstName']) ||
    'Could not find first name'
  const lastName =
    forms.getIn([FORM_NAMES.IIDENTIFII, 'data', 'personal', 'lastName']) ||
    'Could not find last name'
  const idNumber =
    forms.getIn([FORM_NAMES.IIDENTIFII, 'data', 'personal', 'idNumber']) ||
    'Could not find ID number'
  return (
    <PopupContentLayout
      headerTitle={headerTitle}
      buttonLabel={buttonLabel}
      onClosePopup={closePopup}
      showing={isIidentifiiCompletePopupVisible}
      popupBodyContent={<Content firstName={firstName} lastName={lastName} idNumber={idNumber} />}
      onFooterButtonClick={closePopup}
    />
  )
}

IidentifiiCompletePopup.propTypes = {
  headerTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  onSetIidentifiiCompletePopupVisible: PropTypes.func,
  isIidentifiiCompletePopupVisible: PropTypes.bool,
  forms: PropTypes.object,
  onFetchOnboardingTasks: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  onFetchOnboardingTasks: () => dispatch(fetchOnboardingTasks()),
})

export default connect(undefined, mapDispatchToProps)(IidentifiiCompletePopup)
