import React from 'react'

import { TASK_STEPS, FICA_METHODS, FICA_STATUSES } from 'redux/modules/onboarding'
import classes from 'components/onboarding/onboarding.module.scss'

import CCDPrepareYourIdComponent from './CCDPrepareYourIdComponent'

export const ficaVerification = {
  path: 'readOnly/store/ficaVerification',
}

function InProgressNote() {
  return (
    <div className={classes.inProgressHeader}>
      <div className={classes.progressBlockContainer}>
        <p className={classes.progressBlockText}>
          Our team is processing your identity document. This could take up to
          <strong> 48 hours.</strong>
        </p>
      </div>
    </div>
  )
}

export default function todoFica(task, isDeliveryMethodCcd) {
  if (!isDeliveryMethodCcd) {
    switch (task.step) {
      case TASK_STEPS.IN_PROGRESS: {
        return <InProgressNote />
      }
      default:
        if (
          task.payload &&
          task.payload.ficaVerification &&
          task.payload.ficaVerification.status === FICA_STATUSES.BEING_REVIEWED
        ) {
          return <InProgressNote />
        }
        return null
    }
  }

  return nonDigitalFica(task)
}

function nonDigitalFica(task) {
  switch (task.payload.fica_type) {
    case FICA_METHODS.EMAIL_CERTIFIED:
      return (
        <div>
          <p className={classes.helperTextCopy}>
            Please email a clear certified copy of your ID, and a valid proof of address to&nbsp;
            <a href='mailto:fica@yoco.co.za'>fica@yoco.co.za</a>.
          </p>
          <p className={classes.helperTextCopy}>
            Check out&nbsp;
            <a
              href='https://get.yoco.help/hc/en-za/articles/115005970129-FICA-Requirements'
              rel='noopener noreferrer'
              target='_blank'
            >
              this help centre article
            </a>
            &nbsp;for more information on the documents we can accept.
          </p>
        </div>
      )
    case FICA_METHODS.IN_PERSON:
      return (
        <div>
          <p className={classes.helperTextCopy}>
            {'Please provide a clear certified copy of your ID, and a valid ' +
              'proof of address when receiving your card machine. Or if it’s easier, ' +
              'you’re more than welcome to email your documents to '}
            <a href='mailto:fica@yoco.co.za'>fica@yoco.co.za</a>
          </p>
          <p className={classes.helperTextCopy}>
            Check out&nbsp;
            <a
              href='https://get.yoco.help/hc/en-za/articles/115005970129-FICA-Requirements'
              rel='noopener noreferrer'
              target='_blank'
            >
              this help centre article
            </a>
            &nbsp;for more information on the documents we can accept.
          </p>
        </div>
      )
    case FICA_METHODS.COURIER_ID:
      return (
        <div>
          <p>
            Our courier company, Wumdrop, will capture a photo of your ID when they deliver your
            card machine.
          </p>
          <p className={classes.helperTextCopy}>
            Please also send us a valid proof of address to&nbsp;
            <a href='mailto:fica@yoco.co.za'>fica@yoco.co.za</a>.
          </p>
          <p className={classes.helperTextCopy}>
            Check out&nbsp;
            <a
              href='https://get.yoco.help/hc/en-za/articles/115005970129-FICA-Requirements'
              rel='noopener noreferrer'
              target='_blank'
            >
              this help centre article
            </a>
            &nbsp;for more information on the documents we can accept.
          </p>
        </div>
      )
    case FICA_METHODS.COURIER_ID_ADDRESS:
      return <CCDPrepareYourIdComponent />
    default:
      return undefined
  }
}
