import React from 'react'
import PropTypes from 'prop-types'

import progressBarStep2 from 'assets/images/onboarding/progress_bar_step_2.svg'
import progressBarStepAllCompleted from 'assets/images/onboarding/progress_bar_all_completed.svg'

import classes from './onboarding.module.scss'

export default function OnboardingProgressBar({ isOnboardingComplete }) {
  if (isOnboardingComplete) {
    return (
      <img
        src={progressBarStepAllCompleted}
        alt='All steps completed'
        className={classes.progressBar}
      />
    )
  }
  return (
    <img src={progressBarStep2} alt='Complete the steps below' className={classes.progressBar} />
  )
}

OnboardingProgressBar.propTypes = {
  isOnboardingComplete: PropTypes.bool,
}
