import React, { useCallback, useState } from 'react'
import { Map } from 'immutable'

import { track } from 'libs/analytics'
import { makeTestID } from 'libs/utils'
import { FilterBatch, Filter } from 'libs/filters'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import FiltersContainer from 'components/filters/FiltersContainer'
import SearchBar from 'components/filters/SearchBar'
import DateFilter from 'components/filters/DateFilter'
import FlatButton from 'components/buttons/FlatButton'

import { InvoiceSingle } from '../Single'

import InvoicesExport from './Export'
import {
  TableName,
  InvoiceFilterNames,
  filterStatusOptions,
  filterPaymentMethodOptions,
  filterDateDefaultValue,
} from './constants'
import InvoicesTable from './Table'
import FilterCustomer from './FilterCustomer'
import classes from './List.module.scss'

function InvoicesList() {
  const [singleRowOpened, setSingleRowOpened] = useState()

  const handleNewInvoiceClick = useCallback(() => {
    track('invoices_invoice_new_invoice_button_clicked', {
      name: 'invoices_invoice_new_invoice_button_clicked',
    })
    setSingleRowOpened(new Map())
  }, [])

  const handleRowClick = useCallback((row) => {
    setSingleRowOpened(row)
  }, [])

  return (
    <InvoiceSingle tableName={TableName} invoice={singleRowOpened} setInvoice={setSingleRowOpened}>
      <MaxWidthBlock>
        <BlockHeader
          title='Invoices'
          actions={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FlatButton
                className='blueBackground'
                label='New Invoice'
                onClick={handleNewInvoiceClick}
                testID={makeTestID(TableName, 'newInvoice')}
              />
              <InvoicesExport />
            </div>
          }
        />
        <FiltersContainer>
          <FilterBatch name={TableName} showMobileInline>
            <div className={classes.filterBatchInner}>
              <FilterCustomer
                filterKey={InvoiceFilterNames.Customer}
                filterIcon='staff'
                emptySelectedString='All Customers'
                testID={makeTestID(TableName, 'filterCustomer')}
              />
              <Filter
                filterKey={InvoiceFilterNames.Status}
                emptySelectedString='All Statuses'
                filterIcon='credit-card-lock'
                testID={makeTestID(TableName, 'filterStatus')}
                loadAvailableOptions={() => filterStatusOptions}
              />
              <Filter
                filterKey={InvoiceFilterNames.PaymentMethod}
                emptySelectedString='All Payment Methods'
                filterIcon='credit-card-lock'
                testID={makeTestID(TableName, 'filterPaymentMethod')}
                loadAvailableOptions={() => filterPaymentMethodOptions}
              />
              <div className='filler' />
              <DateFilter defaults={filterDateDefaultValue} />
              <SearchBar
                placeholder='Search by Customer name or Invoice no.'
                testID={makeTestID(TableName, 'searchBar')}
              />
            </div>
          </FilterBatch>
        </FiltersContainer>
        <InvoicesTable onRowClick={handleRowClick} />
      </MaxWidthBlock>
    </InvoiceSingle>
  )
}

export default InvoicesList
