// FICA : ACTION CREATORS
export const FICA_ACTIONS = {
  FICA_SUBMITTING: 'FICA_SUBMITTING',
  FICA_ERROR: 'FICA_ERROR',
  FICA_FETCHED: 'FICA_FETCHED',
  FICA_FETCHING: 'FICA_FETCHING',
  FICA_SUBMITTED: 'FICA_SUBMITTED',
  FICA_FILE_ATTACHING: 'FICA_FILE_ATTACHING',
  FICA_FILE_ATTACHED: 'FICA_FILE_ATTACHED',
  FICA_SUBMITTING_COMMENTS: 'FICA_SUBMITTING_COMMENTS',
  FICA_SUBMITTED_COMMENTS: 'FICA_SUBMITTED_COMMENTS',
  FICA_SHOW_UPLOAD_POPUP: 'FICA_SHOW_UPLOAD_POPUP',
  FICA_SHOW_PASSPORT_ID_POPUP: 'FICA_SHOW_PASSPORT_ID_POPUP',
  FICA_SHOW_ID_DETAILS_POPUP: 'FICA_SHOW_ID_DETAILS_POPUP',
  FICA_SHOW_IIDENTIFII_POPUP: 'FICA_SHOW_IIDENTIFII_POPUP',
  FICA_UPDATE_IIDENTIFII_IFRAME_URL: 'FICA_UPDATE_IIDENTIFII_IFRAME_URL',
  FICA_UPDATE_IIDENTIFII_ERROR_MESSAGES: 'FICA_UPDATE_IIDENTIFII_ERROR_MESSAGES',
  FICA_SHOW_IIDENTIFII_COMPLETE: 'FICA_SHOW_IIDENTIFII_COMPLETE',
  FICA_SHOW_IIDENTIFII_FAILED_POPUP: 'FICA_SHOW_IIDENTIFII_FAILED_POPUP',
  FICA_IIDENTIFII_ID_VERIFICATION: 'FICA_IIDENTIFII_ID_VERIFICATION',
  FICA_UPDATE_IIDENTIFII_IFRAME_IS_LOADING: 'FICA_UPDATE_IIDENTIFII_IFRAME_IS_LOADING',
  FICA_VERIFICATION_MODAL: 'FICA_VERIFICATION_MODAL',
  FICA_VERIFICATION_ENABLE_KYC: 'FICA_VERIFICATION_ENABLE_KYC',
}

export function ficaSubmitted() {
  return { type: FICA_ACTIONS.FICA_SUBMITTED }
}

export function ficaError(payload) {
  return {
    type: FICA_ACTIONS.FICA_ERROR,
    payload,
  }
}

export function ficaSubmitting() {
  return { type: FICA_ACTIONS.FICA_SUBMITTING }
}

export function ficaFetching() {
  return { type: FICA_ACTIONS.FICA_FETCHING }
}

export function ficaFetched({ identityDocumentFileSubmitted, ficaVerificationRequest }) {
  return {
    type: FICA_ACTIONS.FICA_FETCHED,
    payload: {
      identityDocumentFileSubmitted,
      ficaVerificationRequest,
    },
  }
}

export function ficaFileAttaching() {
  return { type: FICA_ACTIONS.FICA_FILE_ATTACHING }
}

export function ficaFileAttached() {
  return { type: FICA_ACTIONS.FICA_FILE_ATTACHED }
}

export function ficaSubmittingComments() {
  return { type: FICA_ACTIONS.FICA_SUBMITTING_COMMENTS }
}

export function ficaSubmittedComments() {
  return { type: FICA_ACTIONS.FICA_SUBMITTED_COMMENTS }
}

export const launchUploadPopup = ({
  showUploadIDPopup,
  showCCDDetailsPopup,
  isCitizenshipSelected,
}) => ({
  type: FICA_ACTIONS.FICA_SHOW_UPLOAD_POPUP,
  payload: {
    showUploadIDPopup,
    showCCDDetailsPopup,
    isCitizenshipSelected,
  },
})

export const onSetIdDetailsPopupVisible = (payload) => ({
  type: FICA_ACTIONS.FICA_SHOW_ID_DETAILS_POPUP,
  payload,
})

export const onSetPassportIdPopupVisible = (payload) => ({
  type: FICA_ACTIONS.FICA_SHOW_PASSPORT_ID_POPUP,
  payload,
})

export const setKYCPopupVisible = (payload) => ({
  type: FICA_ACTIONS.FICA_VERIFICATION_MODAL,
  payload,
})

export const launchFicaIidentifiiPopup = (payload) => ({
  type: FICA_ACTIONS.FICA_SHOW_IIDENTIFII_POPUP,
  payload,
})

export const updateIidentifiiIFrameUrl = (payload) => ({
  type: FICA_ACTIONS.FICA_UPDATE_IIDENTIFII_IFRAME_URL,
  payload,
})

export const updateIidentifiiIFrameIsLoading = (payload) => ({
  type: FICA_ACTIONS.FICA_UPDATE_IIDENTIFII_IFRAME_IS_LOADING,
  payload,
})

export const updateIidentifiiErrorMessage = ({ scanIdPassportError, iidentifiiCompleteError }) => ({
  type: FICA_ACTIONS.FICA_UPDATE_IIDENTIFII_ERROR_MESSAGES,
  payload: {
    scanIdPassportError,
    iidentifiiCompleteError,
  },
})

export const onSetIidentifiiCompletePopupVisible = (payload) => ({
  type: FICA_ACTIONS.FICA_SHOW_IIDENTIFII_COMPLETE,
  payload,
})

export const launchFicaIidentifiiFailedPopup = ({ isVisible, errorType }) => ({
  type: FICA_ACTIONS.FICA_SHOW_IIDENTIFII_FAILED_POPUP,
  payload: { isVisible, errorType },
})

export const verifyIdentityNumber = (payload) => ({
  type: FICA_ACTIONS.FICA_IIDENTIFII_ID_VERIFICATION,
  payload,
})
