import { createSelector } from 'reselect'
import { BUSINESS_TYPES } from 'redux/modules/onboarding'

const sessionNode = (state) => state.session

export const selectBusiness = createSelector(sessionNode, (session) => session.get('business'))

export const selectIsDemoAccount = createSelector(selectBusiness, (session) =>
  session.get('isDemoAccount')
)

export const businessName = createSelector(selectBusiness, (business) => {
  return business.get('displayName')
})

export const businessUUID = createSelector(selectBusiness, (session) => session.get('uuid'))

export const settlementActivated = createSelector(sessionNode, (sessionData) => {
  return sessionData.getIn(['business', 'settlementActivated'])
})

export const userDetails = createSelector(sessionNode, (user) => {
  return user.getIn(['user'])
})

export const userUUID = createSelector(userDetails, (user) => user?.get('uuid') ?? '')

export const userEmail = createSelector(userDetails, (user) => user?.get('email') ?? '')

export const businessInfoSelector = createSelector(sessionNode, (businessData) => {
  return businessData.getIn(['business', 'info'])
})

export const businessDetails = createSelector(sessionNode, (sessionData) => {
  const businessType = sessionData.getIn(['business', 'info', 'businessType'])
  const registrationNumber = sessionData.getIn(['business', 'info', 'registrationNumber'])
  const businessHasNotBeenVetted = sessionData.getIn([
    'business',
    'info',
    'businessHasNotBeenVetted',
  ])
  const businessOwnerInfoIsMissing = sessionData.getIn([
    'business',
    'info',
    'businessOwnerInfoIsMissing',
  ])
  const name = sessionData.getIn(['business', 'displayName'])
  const isRegisteredBusiness =
    businessType === BUSINESS_TYPES.REGISTERED_BUSINESS ||
    businessType === BUSINESS_TYPES.CLOSE_CORPORATION ||
    businessType === BUSINESS_TYPES.NPC

  const firstName = sessionData.getIn(['user', 'info', 'firstName'])
  const lastName = sessionData.getIn(['user', 'info', 'lastName'])
  const idNumber = sessionData.getIn(['business', 'info', 'ownerIdNumber'])
  const passport = sessionData.getIn(['business', 'info', 'ownerPassport'])

  return {
    businessDetails: {
      businessType,
      registrationNumber,
      businessName: name,
      isRegisteredBusiness,
      businessHasNotBeenVetted,
      businessOwnerInfoIsMissing,
    },
    userDetails: {
      firstName,
      lastName,
      idNumber,
      passport,
    },
  }
})

export const isSessionUserOwner = createSelector(sessionNode, (session) => {
  const sessionUserUUID = session.getIn(['user', 'uuid'])
  const ownerUUID = session.getIn(['business', 'ownerUUID'])

  return sessionUserUUID && sessionUserUUID === ownerUUID
})
