import React from 'react'

import { TASK_STEPS } from 'redux/modules/onboarding'
import { track } from 'libs/analytics'
import classes from 'components/onboarding/onboarding.module.scss'
import playStore from 'assets/images/google_play_logo.png'
import appStore from 'assets/images/app_store_logo.png'
import appGallery from 'assets/images/huawei_app_gallery.png'

export default function DatecsSetupTaskInlineAction(task) {
  if (task.step !== TASK_STEPS.TODO) {
    return <div />
  }

  const trackIOS = () => {
    track('portal_onboarding_download_app_ios_button_clicked', { task })
  }

  const trackAndroid = () => {
    track('portal_onboarding_download_app_android_button_clicked', { task })
  }

  const trackHuawei = () => {
    track('portal_onboarding_download_app_huawei_button_clicked', { task })
  }

  return (
    <div className={classes.taskCardRow}>
      <div className={classes.taskCardRowText}>
        <p className={classes.selectYourIdentity}>Download the App to view your sales</p>
        <p className={classes.helperTextCopy}>
          <ul>
            <li>Login using your user details</li>
            <li>View your detailed sales review</li>
            <li>
              Access to more features: Online payments tools, Yoco Capital, real-time dashboards,
              your next payout information and more!
            </li>
          </ul>
        </p>
      </div>
      <div className={classes.taskCardColumn}>
        <a
          className={classes.appDownloadLink}
          rel='noopener noreferrer'
          target='_blank'
          href='https://play.google.com/store/apps/details?id=za.co.yoco&hl=en_ZA'
          onClick={trackAndroid}
        >
          <img className={classes.appDownloadImage} src={playStore} alt='Google Play' />
        </a>
        <a
          className={classes.appDownloadLink}
          rel='noopener noreferrer'
          target='_blank'
          href='https://itunes.apple.com/za/app/yoco-point-of-sale/id892816357?mt=8'
          onClick={trackIOS}
        >
          <img className={classes.appDownloadImage} src={appStore} alt='Apple App Store' />
        </a>
        <a
          className={classes.appDownloadLink}
          rel='noopener noreferrer'
          target='_blank'
          href='https://appgallery.huawei.com/#/app/C102766851'
          onClick={trackHuawei}
        >
          <img src={appGallery} alt='Huawei App Gallery' />
        </a>
      </div>
    </div>
  )
}
