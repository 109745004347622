import React from 'react'
import PropTypes from 'prop-types'

import SplitBlock from 'components/blocks/SplitBlock'
import { CreateInvoicesSettings, FormNames } from 'containers/invoices/components/Settings'

import { fetchInvoiceOnboardingData } from '../utils'

function TaskSetup({ onClose, children }) {
  return (
    <SplitBlock
      showingSplit
      header='Invoices setup'
      formName={FormNames.Create}
      renderSplitContent={() => (
        <CreateInvoicesSettings
          onCreated={() => {
            fetchInvoiceOnboardingData({ showLoader: true })
          }}
        />
      )}
      onDismiss={onClose}
    >
      {children}
    </SplitBlock>
  )
}

TaskSetup.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default TaskSetup
