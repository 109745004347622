import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'
import { isFlagship } from 'libs/utils'
import { selectBankAccountUpdateError } from 'redux/modules/onboarding/bankDetails/selectors'
import {
  closeBankDetailsPopup,
  openBankDetailsFlagshipFeedbackPopup,
} from 'redux/modules/onboarding/bankDetails/actions'
import { businessDetails } from 'redux/modules/session/selectors'
import EditBankDetailsContent from 'containers/business/EditBankDetailsPage/components/EditBankDetailsContent'
import { wasRoutedFromApp } from 'redux/modules/routes'
import { fetchBankUpdateRequest } from '../../../redux/modules/onboarding/bankDetails'
import LoadingView from '../../loaders/LoadingView'

function BankDetailsPopup({
  show,
  businessDetails: { businessName },
  bankAccountUpdateError,
  dismissPopup,
  refreshBankUpdateRequest,
  openFlagshipPopup,
  isInApp,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const dismissPopupAndWebView = useCallback(() => {
    if (isInApp && !isFlagship && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('success')
    }
    dismissPopup()
  }, [dismissPopup, isInApp])

  useEffect(() => {
    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      setIsReady(true)
    }

    const onError = () => {
      setIsLoading(false)
      dismissPopupAndWebView()
    }

    refreshBankUpdateRequest(onSuccess, onError)
  }, [refreshBankUpdateRequest, setIsLoading, setIsReady, dismissPopupAndWebView])

  if (isLoading) {
    return <LoadingView />
  }

  const onSuccess = () => {
    if (isFlagship) {
      openFlagshipPopup()
    } else {
      dismissPopupAndWebView()
    }
  }

  if (!isReady) {
    return null
  }

  return (
    <PopupContentLayout
      headerTitle={isFlagship ? 'Bank account' : `Add ${businessName}'s bank account`}
      onClosePopup={!isInApp ? dismissPopupAndWebView : undefined}
      showing={show}
      popupErrorMessage={bankAccountUpdateError}
      popupBodyContent={<EditBankDetailsContent onSuccess={onSuccess} />}
      hideFooterContent
    />
  )
}

BankDetailsPopup.propTypes = {
  show: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  businessDetails: PropTypes.object.isRequired,
  bankAccountUpdateError: PropTypes.string,
  dismissPopup: PropTypes.func.isRequired,
  refreshBankUpdateRequest: PropTypes.func.isRequired,
  openFlagshipPopup: PropTypes.func.isRequired,
  isInApp: PropTypes.bool.isRequired,
}

BankDetailsPopup.defaultProps = {
  show: true,
  bankAccountUpdateError: undefined,
}

const mapStateToProps = (state) => ({
  businessDetails: businessDetails(state).businessDetails,
  bankAccountUpdateError: selectBankAccountUpdateError(state),
  isInApp: wasRoutedFromApp(state),
})

const mapDispatchToProps = (dispatch) => ({
  dismissPopup: () => dispatch(closeBankDetailsPopup()),
  openFlagshipPopup: () => dispatch(openBankDetailsFlagshipFeedbackPopup()),
  refreshBankUpdateRequest: (onSuccess, onError) =>
    dispatch(fetchBankUpdateRequest(onSuccess, onError)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsPopup)
