import ROLES from 'libs/roles'
import { labelType } from 'components/navbars/RouteLabel.constants'

import InvoicesSettingsPage from './InvoicesSettingsPage'
import InvoicesPage from './InvoicesPage'

export default function invoiceRoutes() {
  return {
    path: 'invoice',
    text: `Invoices`,
    icon: 'invoice',
    hideInMenu: false,
    requiresOneRole: [ROLES.MANAGE_BUSINESS],
    requiresAllOfFeatures: ['onlineInvoices'],
    label: labelType.NEW,
    routes: [
      {
        path: 'invoices',
        text: 'Invoices Dashboard',
        component: InvoicesPage,
      },
      {
        path: 'invoices-settings',
        text: 'Invoice Settings',
        component: InvoicesSettingsPage,
      },
    ],
  }
}
