/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'

import { maskEmailAddress } from 'libs/utils'
import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import { userEmail } from 'redux/modules/session/selectors'

import classes from './flagship.module.scss'

export function FlagshipBankDetailsErrorInfoBox({ ownerEmail }) {
  const [mobileNumber, setMobileNumber] = React.useState('')

  const updateMobileNumber = React.useCallback((e) => {
    setMobileNumber(e.target.value)
  }, [])

  // the below API's haven't been implemented yet
  const sendSms = React.useCallback(() => {}, [])

  const sendEmail = React.useCallback(() => {}, [])

  const onSuccess = React.useCallback(() => {}, [])

  return (
    <>
      <h2 className={classes.bankingDetailsErrorSubHeading}>Login to the Yoco portal</h2>

      <div className={classes.bankingDetailsErrorInstructions}>
        <h2>On your mobile, tablet or laptop</h2>
        <ol>
          <li>
            Go to <strong>portal.yoco.co.za </strong>
            on your browser and sign in with your Yoco email and password
          </li>
          <li>Click on “Bank account” to resolve any issues.</li>
        </ol>
      </div>

      <div className={`${classes.bankingDetailsErrorInstructions} ${classes.borderRound}`}>
        <h2 className={classes.bankingDetailsErrorSubHeading}>Get instructions via SMS</h2>
        <div className={classes.flexContainer} style={{ alignItems: 'flex-end' }}>
          <forms.Form action={sendSms} name='SEND_SMS' onSuccess={onSuccess}>
            <forms.TextField
              name='mobileNumber'
              label='Mobile number'
              placeholder='080 000 000'
              validators={[new forms.MobileValidator()]}
              onChange={updateMobileNumber}
            />
            <FlatButton label='Send SMS' className='blueBackground' disabled={!mobileNumber} />
          </forms.Form>
        </div>
      </div>

      <div className={`${classes.bankingDetailsErrorInstructions} ${classes.borderRound}`}>
        <div className={classes.flexContainer}>
          <div>
            <h2 className={classes.bankingDetailsErrorSubHeading}>Login to the Yoco portal</h2>
            <div>We’ll send a link to: {maskEmailAddress(ownerEmail)}</div>
          </div>
          <div>
            <FlatButton label='Send email' className='blueBackground' onClick={sendEmail} />
          </div>
        </div>
      </div>
    </>
  )
}

function FlagshipBankDetailsError({ ownerEmail }) {
  return (
    <div className={classes.bankingDetailsErrorContainer}>
      <div className={classes.bankingDetailsErrorMsg}>
        Unfortunately we were not able to verify your bank account. Please{' '}
        <strong>check your email</strong> or <strong>login to portal.yoco.co.za</strong> to resolve
        issues.
      </div>

      <FlagshipBankDetailsErrorInfoBox ownerEmail={ownerEmail} />
    </div>
  )
}

FlagshipBankDetailsError.propTypes = {
  ownerEmail: propTypes.string,
}

FlagshipBankDetailsErrorInfoBox.propTypes = {
  ownerEmail: propTypes.string,
}

const mapStateToProps = (state) => ({
  ownerEmail: userEmail(state),
})
export default connect(mapStateToProps)(FlagshipBankDetailsError)
