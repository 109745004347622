import React from 'react'
import PropTypes from 'prop-types'

import { track } from 'libs/analytics'
import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'

import IidentifiiIFrame from './components/IidentifiiIFrame'

function IidentifiiPopup({
  popupHeaderTitle,
  buttonLabel,
  iframeTitle,
  iiDentifiiIFrameUrl,
  iiDentifiiIFrameIsLoading,
  showIidentifiiPopup,
  setPopupVisible,
  popupErrorMessage,
}) {
  const closeFicaPopup = () => {
    track('portal_fica_iidentifii_click_button_cancel')
    setPopupVisible(false)
  }
  return (
    <PopupContentLayout
      headerTitle={popupHeaderTitle}
      onClosePopup={closeFicaPopup}
      showing={showIidentifiiPopup}
      popupErrorMessage={popupErrorMessage}
      popupBodyContent={
        <IidentifiiIFrame
          iFrameUrl={iiDentifiiIFrameUrl}
          iFrameIsLoading={iiDentifiiIFrameIsLoading}
          title={iframeTitle}
        />
      }
      buttonLabel={buttonLabel}
      onFooterButtonClick={closeFicaPopup}
      hideFooterContent
      wider
    />
  )
}

IidentifiiPopup.propTypes = {
  popupHeaderTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  iframeTitle: PropTypes.string,
  iiDentifiiIFrameUrl: PropTypes.string,
  iiDentifiiIFrameIsLoading: PropTypes.bool,
  showIidentifiiPopup: PropTypes.bool,
  setPopupVisible: PropTypes.func,
  popupErrorMessage: PropTypes.string,
}

export default IidentifiiPopup
