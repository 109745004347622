import React from 'react'
import PropTypes from 'prop-types'

import faceIdIcon from './images/face-id-4.svg'
import classes from './styles.module.scss'

function FailedIidentifiiContent({ errorType }) {
  let header = `We couldn't confirm your identity`
  let body = (
    <p>
      Please upload your ID documents.
      <br />
      We will update you once your identity has been confirmed.
    </p>
  )
  switch (errorType) {
    case 'timeout': {
      header = (
        <p>
          Something went wrong.
          <br />
          We were unable to scan your documents
        </p>
      )
      body = 'Please upload your documents instead'
      break
    }
    case 'camera': {
      header = `Something went wrong.`
      body = (
        <p>
          We can&apos;t access your camera.
          <br />
          Please upload your documents instead.
        </p>
      )
      break
    }
    default: {
      break
    }
  }

  return (
    <div>
      <div>
        <img src={faceIdIcon} alt='scan face' />
      </div>
      <p className={classes.subText}>{header}</p>

      <p className={classes.contentText}>{body}</p>
    </div>
  )
}

FailedIidentifiiContent.propTypes = {
  errorType: PropTypes.string,
}

export default FailedIidentifiiContent
