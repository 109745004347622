/* eslint-disable no-new-wrappers */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line max-classes-per-file
import luhn from 'fast-luhn'

export class RequiredValidator {
  constructor(emptyMessage) {
    this.emptyMessage = emptyMessage || 'This field is required'
  }

  validate(value) {
    if (!value) {
      return this.emptyMessage
    }

    return null
  }
}

/* eslint-disable-next-line */
const emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/

export class EmailValidator {
  constructor(invalidMessage) {
    this.invalidMessage = invalidMessage || 'Not a valid email address'
  }

  validate(value) {
    if (!value || value.length === 0) {
      return null
    }

    if (value.length < 5) {
      return this.invalidMessage
    }

    const lastIndex = value.lastIndexOf('.')

    if (value.lastIndexOf('@') === -1 || lastIndex === -1) {
      return this.invalidMessage
    }

    if (!emailRegex.test(value)) {
      return this.invalidMessage
    }

    // One letter domains don't exist and the appropriate validation error should be shown
    const domainLessExtension = value.replace(/.*@/, '').substr(0, lastIndex - 2)
    if (domainLessExtension.length < 2) {
      return 'Email address domain is too short'
    }

    return null
  }
}

export class MobileValidator {
  constructor(invalidMessage) {
    this.invalidMessage = invalidMessage || 'Not a valid mobile number'
  }

  validate(value) {
    if (!value || value.length === 0) {
      return null
    }

    const mobileRegex = /^[0-9()+ ]{10,18}$/

    if (!mobileRegex.test(value)) {
      return this.invalidMessage
    }
    return null
  }
}

export class EmailOrMobileValidator {
  constructor(invalidMessage) {
    this.invalidMessage = invalidMessage || 'This is not a valid email address or mobile number'
  }

  validate(value) {
    const mobileValidation = new MobileValidator().validate(value)
    if (!mobileValidation) {
      return null
    }

    const emailValidation = new EmailValidator().validate(value)
    if (!emailValidation) {
      return null
    }

    return this.invalidMessage
  }
}

export class IDNumberValidator {
  constructor(invalidMessage) {
    this.invalidMessage = invalidMessage || 'Please enter a valid ID number with 13 characters'
  }

  validate(value) {
    if (!value || value.length === 0) {
      return null
    }

    if (value.length < 13 && value.length !== 13) {
      return this.invalidMessage
    }
    return null
  }
}

export class IDNumberPassportValidator {
  constructor(invalidMessage) {
    this.invalidMessage = invalidMessage || 'Please enter a valid ID number or Passport number'
  }

  validate(value) {
    if (!value || value.length === 0 || value.length < 5) {
      return this.invalidMessage
    }
    return null
  }
}

export class RequiredLengthValidator {
  constructor(requiredLength, tooShortMessage) {
    this.requiredLength = requiredLength
    this.tooShortMessage = tooShortMessage || 'This field is required'
  }

  validate(value) {
    if (!value) {
      return this.tooShortMessage
    }

    const trimmedValue = value.replace(/^[ \t]+/g, '')

    if (!trimmedValue || trimmedValue.length < this.requiredLength) {
      return this.tooShortMessage
    }
    return null
  }
}

export class LengthValidator {
  constructor(requiredLength, incorrectLengthMessage) {
    this.requiredLength = requiredLength
    this.incorrectLengthMessage =
      incorrectLengthMessage || `This field must be ${this.requiredLength} characters long`
  }

  validate(value) {
    if (value && value.length !== this.requiredLength) {
      return this.incorrectLengthMessage
    }
    return null
  }
}

export class EitherOrValidator {
  constructor(orFieldName, failedMessage) {
    this.orFieldName = orFieldName
    this.failedMessage = failedMessage
  }

  validate(value, formData) {
    const valueDefined = !(value === undefined || value.length === 0)

    const orValue = formData.getIn(this.orFieldName.split('.'))
    const orFieldDefined = !(orValue === undefined || orValue.length === 0)

    if (!(valueDefined || orFieldDefined)) {
      return this.failedMessage
    }
    return null
  }
}

export class BothOrNoneValidator {
  constructor(orFieldName, failedMessage) {
    this.orFieldName = orFieldName
    this.failedMessage = failedMessage
  }

  validate(value, formData) {
    const valueDefined = !(value === undefined || value.length === 0)

    const orValue = formData.getIn(this.orFieldName.split('.'))
    const orFieldDefined = !(orValue === undefined || orValue.length === 0)

    if (valueDefined !== orFieldDefined) {
      return this.failedMessage
    }
    return null
  }
}

export class NumberValidator {
  constructor(
    allowDecimal = true,
    minimumValue = undefined,
    maximumValue = undefined,
    messages = {},
    strictFormat = false
  ) {
    this.allowDecimal = allowDecimal
    this.minimumValue = minimumValue
    this.maximumValue = maximumValue
    this.messages = messages
    this.strictFormat = strictFormat
  }

  validate(value) {
    if (value) {
      if (value === '+' || value === '-') {
        return 'Please provide a number value'
      }

      const integerRegex = this.strictFormat ? /^[+-]?[\d]+$/ : /^[\d-+]+$/
      if (!integerRegex.test(value)) {
        if (this.allowDecimal) {
          const decimalRegex = this.strictFormat ? /^[+-]?[\d]*.[\d]+$/ : /^[\d.-]+$/

          if (!decimalRegex.test(value)) {
            return this.messages.notNumeric || 'Only number values allowed'
          }
        } else {
          return this.messages.notNumeric || 'Only integer number values allowed'
        }
      }

      const parsed = parseFloat(value)

      if (this.minimumValue !== undefined) {
        if (parsed < this.minimumValue) {
          return this.messages.belowMinimum || `Number must be > ${this.minimumValue}`
        }
      }

      if (this.maximumValue !== undefined) {
        if (parsed > this.maximumValue) {
          return this.messages.aboveMaximum || `Number must be < ${this.maximumValue}`
        }
      }
    }
    return null
  }
}

export class ConfirmPasswordValidator {
  constructor(password, invalidMessage) {
    this.password = password
    this.invalidMessage = invalidMessage || 'Password does not match'
  }

  validate(value) {
    if (value && value !== this.password) {
      return this.invalidMessage
    }
    return null
  }
}

export class UniqueValidator {
  constructor(list, errorString) {
    this.list = list
    this.errorString = errorString
  }

  validate(value) {
    for (const item of this.list) {
      if (item === value) {
        return this.errorString
      }
    }

    return null
  }
}

export class WebURLValidator {
  constructor(invalidMessage = 'Not a valid web address URL') {
    this.invalidMessage = invalidMessage
    /* eslint-disable-next-line */
    this.URLRegex = /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/
  }

  validate(value) {
    if (!value || value.length === 0) {
      return 'Web address URL must be entered.'
    }

    if (!this.URLRegex.test(value)) {
      return this.invalidMessage
    }
    return null
  }
}

export class LuhnIDValidator {
  constructor(invalidMessage) {
    this.invalidMessage = invalidMessage || 'Please enter a valid ID number'
  }

  validate(value) {
    if (value && (value.length !== 13 || !luhn(value))) {
      return this.invalidMessage
    }
    return null
  }
}

export class VATNumberValidator {
  constructor(invalidMessage) {
    this.invalidMessage = invalidMessage || 'Please enter a valid VAT number'
  }

  validate(value) {
    if (value) {
      const firstDigitInNumber = parseInt(new String(value)[0], 10)

      if (value.length === 10 && firstDigitInNumber === 4) {
        return null
      }
      return this.invalidMessage
    }
    return null
  }
}

export class RegisteredBusinessNumberValidator {
  constructor(invalidMessage) {
    this.invalidMessage = invalidMessage || 'Please enter a valid company registration number'
  }

  validate(value) {
    if (value) {
      const firstTwoDigits = parseInt(value.substring(0, 2), 10)
      if (value.length === 14 && (firstTwoDigits === 19 || firstTwoDigits === 20)) {
        return null
      }
      return this.invalidMessage
    }
    return null
  }
}
