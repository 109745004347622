/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'

import classes from './labels.module.scss'

export default class ClipboardFriendlyLabel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPopup: false,
    }
  }

  copyToClipboard() {
    if (this.props.value && this.props.copiedNotify) {
      this.showToolTipPopup(copy(this.props.value))
    } else {
      console.log('Copy to clipboard failed, value prop is not set.')
    }
  }

  showToolTipPopup(isCopySuccessful) {
    this.setState({ isCopySuccessful, showPopup: true })
    setTimeout(() => this.dismissToolTipPopup(), this.props.copiedAlertDelay || 2000)
  }

  dismissToolTipPopup() {
    this.setState({ isCopySuccessful: undefined, showPopup: false })
  }

  renderCopiedToolTip() {
    if (this.state.showPopup) {
      const popupStatus = this.state.isCopySuccessful ? 'label-success' : 'label-warning'
      const successfullyCopied =
        this.props.value && this.props.value.length < 15
          ? `${this.props.value} copied`
          : 'copied successfully'

      return (
        <div className={classes.toolTip} style={this.props.tooltipContainerStyle}>
          <span
            className={`${classes.toolTipText} label ${popupStatus}`}
            style={this.props.tooltipStyle}
          >
            {this.state.isCopySuccessful ? successfullyCopied : 'Copy failed'}
          </span>
        </div>
      )
    }
    return null
  }

  renderCopyIcon(paddingClass) {
    return (
      <div
        title={this.props.value ? `Copy '${this.props.value}' to clipboard` : 'copy to clipboard'}
        className={`${classes.copyToClipboardIcon} ${
          this.props.copyIconName || 'icon2-copy'
        } ${paddingClass}`}
        onClick={() => this.copyToClipboard()}
        style={this.props.copyIconStyle}
      />
    )
  }

  renderLabel() {
    if (this.props.children) {
      return this.props.children
    }
    if (this.props.name) {
      return (
        <span className={classes.labelClass} style={this.props.labelStyle}>
          {`${this.props.name}: ${this.props.value}`}
        </span>
      )
    }
    return (
      <span className={classes.labelClass} style={this.props.labelStyle}>
        {this.props.value}
      </span>
    )
  }

  render() {
    return (
      <div className={classes.container} style={this.props.containerStyle}>
        {this.props.rightIcon ? undefined : this.renderCopyIcon(classes.leftIcon)}
        <div className={classes.labelContainer} style={this.props.childrenContainerStyle}>
          {this.renderLabel()}
        </div>
        {this.props.rightIcon ? this.renderCopyIcon(classes.rightIcon) : undefined}
        {this.renderCopiedToolTip()}
      </div>
    )
  }
}

ClipboardFriendlyLabel.propTypes = {
  childrenContainerStyle: PropTypes.object,
  tooltipContainerStyle: PropTypes.object,
  copiedAlertDelay: PropTypes.number,
  containerStyle: PropTypes.object,
  tooltipStyle: PropTypes.object,
  copyIconStyle: PropTypes.object,
  copyIconName: PropTypes.string,
  labelStyle: PropTypes.object,
  copiedNotify: PropTypes.bool,
  rightIcon: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
}

ClipboardFriendlyLabel.defaultProps = {
  copiedNotify: true,
}
