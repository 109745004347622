/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/sort-comp */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { Icon } from '@yoco/design-system-icons/dist/react'

import AbbreviationEditor from 'components/items/AbbreviationEditor'
import { fieldUpdated } from 'redux/modules/forms'
import { getAbbreviation } from 'libs/pos/tiles'
import SelectIconPopup from 'components/popups/SelectIconPopup'
import ItemImageUpload from 'components/items/ItemImageUpload'

import classes from './tile.module.scss'
import Tile from './Tile'

const colors = ['#005b95', '#68b2ff', '#56C0C9', '#AE9EF6', '#FFBC44', '#C1718A', '#E8503C']

class TileEditorFormField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentOption: this.getCurrentOption(props.formData),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.formData !== this.props.formData) {
      this.setState({
        currentOption: this.getCurrentOption(nextProps.formData),
      })
    }
  }

  getCurrentOption(data) {
    if (data) {
      return data.get('imageURL') ? 'image' : data.get('imageIcon') ? 'icon' : 'abbreviation'
    }

    return 'abbreviation'
  }

  imageTextChanged(event) {
    this.props.dispatch(fieldUpdated(this.props.formName, 'tile.imageText', event.target.value))
  }

  getAbbreviation() {
    const formData = this.props.formData.get('tile', this.props.formData)
    return getAbbreviation(formData.get('name'), formData.get('imageText'))
  }

  removeIcon() {
    this.props.dispatch(fieldUpdated(this.props.formName, 'imageIcon', null))
  }

  removeImage() {
    this.props.dispatch(fieldUpdated(this.props.formName, 'imageFileUUID', null))
    this.props.dispatch(fieldUpdated(this.props.formName, 'imageURL', null))
  }

  onIconSelected(icon) {
    this.removeImage()
    this.props.dispatch(fieldUpdated(this.props.formName, 'imageIcon', icon))
    this.setState({
      showIconModal: false,
    })
  }

  onOptionClicked(option) {
    switch (option) {
      case 'icon': {
        this.setState((state) => ({
          showIconModal: !state.showIconModal,
        }))
        break
      }
      case 'abbreviation': {
        this.removeIcon()
        this.removeImage()
        this.props.dispatch(fieldUpdated(this.props.formName, 'imageText', this.getAbbreviation()))
        break
      }
      default:
    }
  }

  renderAbbreviationOption() {
    let className = classes.option

    if (this.state.currentOption === 'abbreviation') {
      className += ` ${classes.active}`
    }

    return (
      <AbbreviationEditor
        className={className}
        onChange={(event) => this.imageTextChanged(event)}
        onClick={() => {
          this.removeIcon()
          this.removeImage()
          this.props.dispatch(
            fieldUpdated(this.props.formName, 'imageText', this.getAbbreviation())
          )
        }}
        abbreviation={this.getAbbreviation()}
        mouseDownHandler={this.props.mouseDownHandler}
        mouseUpHandler={this.props.mouseUpHandler}
      />
    )
  }

  renderIconOption() {
    let className = classes.option

    if (this.state.currentOption === 'icon') {
      className += ` ${classes.active}`
    }

    return (
      <div>
        <div
          className={className}
          onClick={() => {
            this.setState((state) => ({
              showIconModal: !state.showIconModal,
            }))
          }}
        >
          <Icon name='photo' size={24} />
        </div>
        <span>icon</span>
      </div>
    )
  }

  renderImageOption() {
    const style = { height: '50px' }
    let className = classes.option

    if (this.state.currentOption === 'image') {
      className += ` ${classes.active}`
    }

    return (
      <div className={className}>
        <Icon name='camera' size={24} />
        <ItemImageUpload
          isTile
          style={style}
          loaderStyle={classes.loaderStyle}
          noLoadingView='Image'
          completeCallback={(data) => {
            this.props.dispatch(fieldUpdated(this.props.formName, 'imageFileUUID', data.data.uuid))
            this.props.dispatch(fieldUpdated(this.props.formName, 'imageURL', data.data.url))
            this.props.dispatch(fieldUpdated(this.props.formName, 'removeImage', false))
          }}
          maxHeight={960}
          format='image/jpeg'
          encoderOptions={0.8}
          grouping='product-images'
          documentType='image/jpeg'
        />
      </div>
    )
  }

  iconModal() {
    return (
      <SelectIconPopup
        showing={this.state.showIconModal}
        shownRow={this.props.formData}
        onCancel={() => {
          this.setState({
            showIconModal: false,
          })
        }}
        title='Select an Icon'
        onIconClicked={(icon) => {
          this.onIconSelected(icon)
        }}
      />
    )
  }

  render() {
    return (
      <div className={classes.tileAppearance}>
        {this.iconModal()}
        <div className={classes.tileDisplay}>
          <Tile tile={this.props.formData} />
        </div>
        <div className={classes.editItemIcon}>
          <div className={classes.tileColorPicker}>
            {colors.map((color) => {
              const className = classes.choice

              return (
                <a
                  key={color}
                  onClick={() => {
                    this.props.dispatch(
                      fieldUpdated(this.props.formName, 'backgroundColor', color.substr(1))
                    )
                    this.props.dispatch(
                      fieldUpdated(this.props.formName, 'tile.backgroundColor', color.substr(1))
                    )
                  }}
                  className={className}
                  style={{ backgroundColor: color }}
                />
              )
            })}
          </div>
          <div className={classes.designOptions}>
            {this.renderAbbreviationOption()}
            {this.renderIconOption()}
            {this.renderImageOption()}
          </div>
        </div>
      </div>
    )
  }
}

TileEditorFormField.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formData: PropTypes.object,
  formName: PropTypes.string,
  mouseDownHandler: PropTypes.func,
  mouseUpHandler: PropTypes.func,
}

export default connect((state, props) => ({
  formData: state.forms.getIn([props.formName, 'data'], Map({})),
}))(TileEditorFormField)
