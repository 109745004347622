import { Map, List, fromJS } from 'immutable'

import { callJSONApi } from 'libs/api'
import { updateOwnerIDNumber, updateOwnerPassport } from 'redux/modules/session'
import { setRouteBadge } from 'redux/modules/routeBadges'

import {
  FICA_STATUSES,
  FICA_METHODS,
  fetchLatestFICADetails,
  submitFICADetails,
  attachFICAStoredFile,
  submitFICAComments,
  FICA_ACTIONS,
  ficaError,
  launchUploadPopup,
  onSetIdDetailsPopupVisible,
  onSetPassportIdPopupVisible,
  setKYCPopupVisible,
  launchFicaIidentifiiPopup,
  updateIidentifiiIFrameUrl,
  updateIidentifiiIFrameIsLoading,
  updateIidentifiiErrorMessage,
  onSetIidentifiiCompletePopupVisible,
  launchFicaIidentifiiFailedPopup,
  verifyIdentityNumber,
  iidentifiiComplete,
  postIidentifiiIdNumber,
  ficaFetching,
  ficaFetched,
  ficaFileAttaching,
  ficaFileAttached,
  ficaSubmittingComments,
  ficaSubmittedComments,
  ficaSubmitting,
  ficaSubmitted,
} from './fica'
import {
  EFT_ACTIONS,
  fetchLatestProofOfPayment,
  submitEFTProof,
  eftProofFetching,
  eftFetchedProof,
  eftProofSubmitting,
  eftProofSubmitted,
  eftProofError,
} from './eft'
import {
  BUSINESS_ADDRESS_ACTIONS,
  updateBusinessTradingAddress,
  launchAddressPopup,
  updateBusinessAddressSubmitErrorMessage,
  fetchBusinessDetails,
} from './businessDetails'
import { BANK_REQUEST_STATUSES } from './bankDetails'
import {
  BUSINESS_VETTING_ACTIONS,
  updateVettingFetchErrorMessage,
  updateFailedUserInfoFetchErrorMessage,
  shouldInitiateVetting,
  isOwnerInfoFound,
  updateBuyCardMachineUserInfoFromVettingInformation,
  updateUserInfoFromVettingInformation,
  fetchBusinessVetting,
} from './vetting'
import { progressSelector } from './selectors'
import {
  finishedTasksCount,
  isTaskCompleted,
  remainingTasksCount,
  totalTasksCount,
} from './vetting/utils'
import { BANK_ACTIONS } from './bankDetails/actions'

// ENUMS
const TASK_TYPES = {
  PAYMENT: 'payment',
  BANK_DETAILS: 'banking_details',
  FICA: 'fica',
  APP_SETUP: 'app_setup',
  DATECS_SETUP: 'datecs_setup',
  ONLINE_SETUP: 'online_setup',
  FIRST_TRANSACTION: 'first_transaction',
  BUSINESS_INFO_SETUP: 'business_info_setup',
}

const BUSINESS_TYPES = {
  SOLE_PROPRIETOR: 'sole_proprietor',
  REGISTERED_BUSINESS: 'company',
  CLOSE_CORPORATION: 'cc',
  TRUST: 'trust',
  NOT_REGISTERED: 'not_registered',
  NPO: 'npo',
  NPC: 'npc',
}

const PROGRESS_TYPES = {
  VETTING: 'vetting',
  DELIVERY: 'delivery',
  FIRST_SETTLEMENT: 'first_settlement',
}

const TASK_STEPS = {
  TODO: 'todo',
  IN_PROGRESS: 'in_progress',
  CHANGE_REQUIRED: 'change_required',
  COMPLETED: 'complete',
  FAILED: 'failed',
}

const TASK_ICONS = {
  UNKNOWN_TASK_ICON: 'alert-2',
  COMPLETE_TASK_ICON: 'check-circle-2',
  EFT_PAYMENT_TASK_ICON: 'eftstep',
  BANK_DETAILS_TASK_ICON: 'bankstep',
  IDENTITY_CONFIRMATION_TASK_ICON: 'ficastep',
  POS_DOWNLOAD_TASK_ICON: 'downloadstep',
  FIRST_TRANSACTION_TASK_ICON: 'transactionstep',
}

const PROGRESS_STATUS = {
  WAIT: 'wait',
  PROCESS: 'process',
  FINISH: 'finish',
  ERROR: 'error',
}

const FIRST_SETTLEMENT_STATE = {
  NO_VALID_TRANSACTION: 'no_valid_transaction',
  REFUNDED: 'refunded',
  AWAITING_PAYOUT: 'awaiting_payout',
  PAYOUT_PROCESSED: 'payout_processed',
  SETTLED: 'settled',
}

const DELIVERY_STATUS = {
  PICKING_AND_PACKING: 'picking_and_packing',
  DISPATCHED: 'dispatched',
  DELIVERED: 'delivered',
  ON_HOLD: 'on_hold',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  ORDER_PLACED: 'order_placed',
  ASSIGNING_READERS: 'assigning_readers',
  DISPATCHED_NO_EMAIL: 'dispatched_no_email',
  AWAITING_VETTING_APPROVAL: 'awaiting_vetting_approval',
  AWAITING_PAYMENT: 'awaiting_payment',
}

const PAYMENT_STATE = {
  PENDING: 'pending',
  APPROVED: 'approved',
  CREATED: 'created',
  INCOMPLETE: 'incomplete',
  PROVISIONALLY_APPROVED: 'provisionally_approved',
}

const FORM_NAMES = {
  BANK_DETAILS: 'bankDetails',
  FICA_VERIFICATION: 'ficaVerification',
  BUY_CARD_MACHINE: 'buyCardMachine',
  BUSINESS_ADDRESS: 'businessAddress',
  BUSINESS_REVENUE: 'businessRevenue',
  COMPANY_REGISTRATION_NUMBER: 'companyRegistrationNumber',
  IIDENTIFII: 'iidentifii',
}

// ACTION CREATORS
const ACTIONS = {
  SETUP_COMPLETE: 'SETUP_COMPLETE',
  FETCH: 'ONBOARDING_DATA_FETCH',
  UPDATE: 'ONBOARDING_UPDATE',
  ERROR: 'ONBOARDING_ERROR',
  CLEAR: 'CLEAR_ONBOARDING',
}

const PAYMENT_METHOD = {
  CARD_ON_DELIVERY: 'card_on_delivery',
}

const fetchOnboardingTasks = () => (dispatch) => {
  dispatch({ type: ACTIONS.FETCH })

  callJSONApi(
    '/business/onboarding',
    'GET',
    {},
    (response) => {
      const { data } = response
      const finishedTasks = finishedTasksCount(data.tasks)
      const totalTasks = totalTasksCount(data.tasks)

      data.finishedTasks = finishedTasks
      data.totalTasks = totalTasks

      dispatch({
        type: ACTIONS.UPDATE,
        data,
      })
      dispatch(setRouteBadge('complete setup', `${finishedTasks}/${totalTasks}`))
    },
    (error) =>
      dispatch({
        type: ACTIONS.ERROR,
        error,
      })
  )
}

const onboardingSetupComplete = () => (dispatch) => {
  callJSONApi(
    '/business/onboarding',
    'POST',
    {},
    (response) =>
      dispatch({
        type: ACTIONS.SETUP_COMPLETE,
        response,
      }),
    (error) =>
      dispatch({
        type: ACTIONS.ERROR,
        error,
      })
  )
}

const firstSettlementPayoutStatus = (status) => {
  switch (status) {
    case FIRST_SETTLEMENT_STATE.AWAITING_PAYOUT:
    case FIRST_SETTLEMENT_STATE.NO_VALID_TRANSACTION:
      return PROGRESS_STATUS.WAIT
    case FIRST_SETTLEMENT_STATE.PAYOUT_PROCESSED:
      return PROGRESS_STATUS.PROCESS
    case FIRST_SETTLEMENT_STATE.SETTLED:
      return PROGRESS_STATUS.FINISH
    default:
      return PROGRESS_STATUS.ERROR
  }
}

export const isProgressStepCompleted = (progressCard) => {
  switch (progressCard.cardType) {
    case PROGRESS_TYPES.VETTING:
      return progressCard.status === 'complete'
    case PROGRESS_TYPES.DELIVERY:
      return progressCard.status === 'delivered'
    case PROGRESS_TYPES.FIRST_SETTLEMENT:
      return PROGRESS_STATUS.FINISH === firstSettlementPayoutStatus(progressCard.status)
    default:
      return false
  }
}

const fetchBusinessOwnerDetails = () => (dispatch) => {
  callJSONApi(
    '/user/account',
    'GET',
    {},
    (response) => {
      const { data } = response
      if (data.user.info.idNumber) {
        dispatch(updateOwnerIDNumber(data.user.info.idNumber))
      } else {
        dispatch(updateOwnerPassport(data.user.info.passportNumber))
      }
      dispatch(updateFailedUserInfoFetchErrorMessage(undefined))
    },
    () => {
      dispatch(
        updateFailedUserInfoFetchErrorMessage(
          'Failed fetching user information, please check internet connection and reload the page'
        )
      )
    }
  )
}

const postBusinessDetails = ({ vettingInformation, actionFlag, onBusinessDetailsPostSuccess }) => (
  dispatch
) => {
  callJSONApi(
    '/onboarding/business/details',
    'POST',
    vettingInformation,
    () => {
      if (onBusinessDetailsPostSuccess) {
        onBusinessDetailsPostSuccess()
      }
    },
    () => {
      switch (actionFlag) {
        default:
          dispatch(
            ficaError(
              'Failed uploading fica documents, please check internet connection and submit again.'
            )
          )
      }
    }
  )
}

export function clearOnboarding() {
  return {
    type: ACTIONS.CLEAR,
  }
}

// REDUCER
const initialState = Map({
  totalTasks: 0,
  finishedTasks: 0,
  tasks: List(),
  progress: List(),
  firstBusinessOrder: Map(),
  eft: Map({
    isFetchingEFT: false,
    isSubmittingEFT: false,
    isSubmittedSuccessfully: false,
    eftErrorMessage: null,
    uploadedEFTProof: null,
  }),
  fica: Map({
    isAttachingFICAFile: false,
    isSubmittingFICAComments: false,
    isFetchingFICA: false,
    isSubmittingFICA: false,
    isSubmittedSuccessfully: false,
    isAttachedSuccessfully: false,
    isSubmittedCommentsSuccessfully: false,
    ficaErrorMessage: undefined,
    uploadedIdentityDocument: null,
    ficaVerificationRequest: null,
    ficaUploadPopup: Map({
      showUploadIDPopup: false,
      showCCDDetailsPopup: false,
      isCitizenshipSelected: false,
    }),
    ficaPassportIdPopup: Map({
      isPassportIdPopupVisible: false,
    }),
    ficaIdDetailsPopup: Map({
      isIdDetailsPopupVisible: false,
    }),
    isKYCPopupVisible: false,
    iidentifiiPopup: Map({
      isVerifyingIdNumber: false,
      showIidentifiiPopup: false,
      failedIidentifiiPopup: Map({
        isVisible: false,
        errorType: undefined,
      }),
      iFrameUrl: undefined,
      iFrameIsLoading: undefined,
      iidentifiiErrorMessages: Map({
        scanIdPassportError: undefined,
        iidentifiiCompleteError: undefined,
      }),
    }),
    iidentifiiCompletePopup: Map({
      isIidentifiiCompletePopupVisible: false,
    }),
  }),
  bank: Map({
    bankAccount: Map(),
    bankAccountUpdateRequest: Map(),
    bankAccountUpdateError: undefined,
    showBankDetailsPopup: false,
    showBankDetailsFlagshipFeedbackPopup: false,
  }),
  businessAddress: Map({
    address: undefined,
    failedAddressSubmitError: undefined,
    failedAddressFetchError: undefined,
    showAddressPopup: false,
  }),
  businessVetting: Map({
    vettingInfoFetchError: undefined,
    failedUserInfoFetchError: undefined,
  }),
})

export const onboardingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.CLEAR:
      return initialState
    case ACTIONS.SETUP_COMPLETE:
      return state.withMutations((map) => {
        map
          .set('completionAcknowledged', true)
          .set('setupComplete', true)
          .remove('error')
          .remove('loading')
      })
    case ACTIONS.FETCH:
      return state.withMutations((map) => {
        map.set('loading', true).remove('error').remove('setupComplete')
      })
    case ACTIONS.ERROR:
      return state.withMutations((map) => {
        map.set('error', action.error).remove('loading').remove('setupComplete')
      })
    case ACTIONS.UPDATE:
      return state.withMutations((map) => {
        const allTasksComplete = remainingTasksCount(action.data.tasks) === 0
        const allProgressStepComplete =
          action.data.progress.filter((entry) => !isProgressStepCompleted(entry)).length === 0
        const setupComplete = allTasksComplete && allProgressStepComplete

        map
          .set('totalTasks', action.data.totalTasks)
          .set('finishedTasks', action.data.finishedTasks)
          .set('tasks', new List(action.data.tasks))
          .set('progress', new List(action.data.progress))
          .set('setupComplete', setupComplete)
          .set('firstBusinessOrder', fromJS(action.data.firstBusinessOrder))
          .remove('error')
          .remove('loading')
      })
    case EFT_ACTIONS.EFT_SUBMITTING:
      return state.mergeIn(
        ['eft'],
        Map({
          isSubmittingEFT: true,
          isSubmittedSuccessfully: false,
          eftErrorMessage: null,
        })
      )
    case EFT_ACTIONS.EFT_PROOF_SUBMITTED:
      return state.mergeIn(
        ['eft'],
        Map({
          isSubmittingEFT: false,
          isSubmittedSuccessfully: true,
          eftErrorMessage: null,
        })
      )
    case EFT_ACTIONS.EFT_FETCHING:
      return state.mergeIn(
        ['eft'],
        Map({
          isFetchingEFT: true,
          uploadedEFTProof: null,
          eftErrorMessage: null,
        })
      )
    case EFT_ACTIONS.EFT_FETCHED:
      return state.mergeIn(
        ['eft'],
        Map({
          isFetchingEFT: false,
          uploadedEFTProof: action.storedFile,
          eftErrorMessage: null,
        })
      )
    case EFT_ACTIONS.EFT_PROOF_ERROR:
      return state.mergeIn(
        ['eft'],
        Map({
          isSubmittingEFT: false,
          isFetchingEFT: false,
          isSubmittedSuccessfully: false,
          eftErrorMessage: action.errorMessage,
        })
      )

    case FICA_ACTIONS.FICA_SUBMITTING:
      return state.mergeIn(
        ['fica'],
        Map({
          isSubmittingFICA: true,
          isSubmittedSuccessfully: false,
          ficaErrorMessage: undefined,
        })
      )
    case FICA_ACTIONS.FICA_SUBMITTED:
      return state.mergeIn(
        ['fica'],
        Map({
          isSubmittingFICA: false,
          isSubmittedSuccessfully: true,
          ficaErrorMessage: undefined,
        })
      )
    case FICA_ACTIONS.FICA_FETCHING:
      return state.mergeIn(
        ['fica'],
        Map({
          isFetchingFICA: true,
          uploadedIdentityDocument: null,
          ficaVerificationRequest: null,
          ficaErrorMessage: undefined,
        })
      )
    case FICA_ACTIONS.FICA_FETCHED:
      return state.mergeIn(
        ['fica'],
        Map({
          isFetchingFICA: false,
          uploadedIdentityDocument: action.payload.identityDocumentFileSubmitted,
          ficaVerificationRequest: action.payload.ficaVerificationRequest,
          ficaErrorMessage: undefined,
        })
      )
    case FICA_ACTIONS.FICA_FILE_ATTACHING:
      return state.mergeIn(
        ['fica'],
        Map({
          isAttachingFICAFile: true,
          isAttachedSuccessfully: false,
          ficaErrorMessage: undefined,
        })
      )
    case FICA_ACTIONS.FICA_FILE_ATTACHED:
      return state.mergeIn(
        ['fica'],
        Map({
          isAttachingFICAFile: false,
          isAttachedSuccessfully: true,
          ficaErrorMessage: undefined,
        })
      )
    case FICA_ACTIONS.FICA_SUBMITTING_COMMENTS:
      return state.mergeIn(
        ['fica'],
        Map({
          isSubmittingFICAComments: true,
          isSubmittedCommentsSuccessfully: false,
          ficaErrorMessage: undefined,
        })
      )
    case FICA_ACTIONS.FICA_SUBMITTED_COMMENTS:
      return state.mergeIn(
        ['fica'],
        Map({
          isSubmittingFICAComments: false,
          isSubmittedCommentsSuccessfully: true,
          ficaErrorMessage: undefined,
        })
      )
    case FICA_ACTIONS.FICA_ERROR:
      return state.mergeIn(
        ['fica'],
        Map({
          isSubmittingFICA: false,
          isFetchingFICA: false,
          isAttachingFICAFile: false,
          isSubmittingFICAComments: false,
          isAttachedSuccessfully: false,
          isSubmittedCommentsSuccessfully: false,
          isSubmittedSuccessfully: false,
          ficaErrorMessage: action.payload,
        })
      )
    case FICA_ACTIONS.FICA_SHOW_UPLOAD_POPUP:
      return state
        .setIn(['fica', 'ficaUploadPopup', 'showUploadIDPopup'], action.payload.showUploadIDPopup)
        .setIn(
          ['fica', 'ficaUploadPopup', 'showCCDDetailsPopup'],
          action.payload.showCCDDetailsPopup
        )
        .setIn(
          ['fica', 'ficaUploadPopup', 'isCitizenshipSelected'],
          action.payload.isCitizenshipSelected
        )
    case FICA_ACTIONS.FICA_SHOW_PASSPORT_ID_POPUP:
      return state.setIn(
        ['fica', 'ficaPassportIdPopup', 'isPassportIdPopupVisible'],
        action.payload
      )
    case FICA_ACTIONS.FICA_SHOW_ID_DETAILS_POPUP:
      return state.setIn(['fica', 'ficaIdDetailsPopup', 'isIdDetailsPopupVisible'], action.payload)
    case FICA_ACTIONS.FICA_VERIFICATION_MODAL:
      return state.setIn(['fica', 'isKYCPopupVisible'], action.payload)
    case FICA_ACTIONS.FICA_SHOW_IIDENTIFII_POPUP:
      return state.setIn(['fica', 'iidentifiiPopup', 'showIidentifiiPopup'], action.payload)
    case FICA_ACTIONS.FICA_IIDENTIFII_ID_VERIFICATION:
      return state.setIn(['fica', 'iidentifiiPopup', 'isVerifyingIdNumber'], action.payload)
    case FICA_ACTIONS.FICA_SHOW_IIDENTIFII_FAILED_POPUP:
      return state
        .setIn(
          ['fica', 'iidentifiiPopup', 'failedIidentifiiPopup', 'isVisible'],
          action.payload.isVisible
        )
        .setIn(
          ['fica', 'iidentifiiPopup', 'failedIidentifiiPopup', 'errorType'],
          action.payload.errorType
        )
    case FICA_ACTIONS.FICA_UPDATE_IIDENTIFII_ERROR_MESSAGES:
      return state
        .setIn(
          ['fica', 'iidentifiiPopup', 'iidentifiiErrorMessages', 'scanIdPassportError'],
          action.payload.scanIdPassportError
        )
        .setIn(
          ['fica', 'iidentifiiPopup', 'iidentifiiErrorMessages', 'iidentifiiCompleteError'],
          action.payload.iidentifiiCompleteError
        )
    case FICA_ACTIONS.FICA_UPDATE_IIDENTIFII_IFRAME_URL:
      return state.setIn(['fica', 'iidentifiiPopup', 'iFrameUrl'], action.payload)
    case FICA_ACTIONS.FICA_UPDATE_IIDENTIFII_IFRAME_IS_LOADING:
      return state.setIn(['fica', 'iidentifiiPopup', 'iFrameIsLoading'], action.payload)
    case FICA_ACTIONS.FICA_SHOW_IIDENTIFII_COMPLETE:
      return state.setIn(
        ['fica', 'iidentifiiCompletePopup', 'isIidentifiiCompletePopupVisible'],
        action.payload
      )
    case BANK_ACTIONS.UPDATE_UPLOADED_BANK_UPDATE_REQUEST_DOCUMENTS:
      return state.setIn(['bank', 'uploadedDocuments'], action.payload)
    case BANK_ACTIONS.DISPLAY_BANK_DETAILS_POPUP:
      return state.setIn(['bank', 'showBankDetailsPopup'], action.payload)
    case BANK_ACTIONS.DISPLAY_BANK_DETAILS_FLAGSHIP_FEEDBACK_POPUP:
      return state.setIn(['bank', 'showBankDetailsFlagshipFeedbackPopup'], action.payload)
    case BANK_ACTIONS.UPDATE_BANK_ACCOUNT:
      return state.setIn(['bank', 'bankAccount'], Map(action.payload))
    case BANK_ACTIONS.UPDATE_BANK_ACCOUNT_UPDATE_REQUEST:
      return state.setIn(['bank', 'bankAccountUpdateRequest'], Map(action.payload))
    case BANK_ACTIONS.UPDATE_BANK_ACCOUNT_UPDATE_ERROR:
      return state.setIn(['bank', 'bankAccountUpdateError'], action.payload)
    case BUSINESS_ADDRESS_ACTIONS.TRADING_ADDRESS:
      return state.setIn(['businessAddress', 'address'], action.payload)
    case BUSINESS_ADDRESS_ACTIONS.BUSINESS_ADDRESS_SUBMIT_ERROR_MESSAGE:
      return state.setIn(['businessAddress', 'failedAddressSubmitError'], action.payload)
    case BUSINESS_ADDRESS_ACTIONS.BUSINESS_ADDRESS_FETCH_ERROR_MESSAGE:
      return state.setIn(['businessAddress', 'failedAddressFetchError'], action.payload)
    case BUSINESS_ADDRESS_ACTIONS.SHOW_ADDRESS_POPUP:
      return state.setIn(['businessAddress', 'showAddressPopup'], action.payload)
    case BUSINESS_VETTING_ACTIONS.VETTING_INFO_FETCH_ERROR_MESSAGE:
      return state.setIn(['businessVetting', 'vettingInfoFetchError'], action.payload)
    case BUSINESS_VETTING_ACTIONS.FAILED_FETCHING_USER_INFO:
      return state.setIn(['businessVetting', 'failedUserInfoFetchError'], action.payload)
    default:
      return state
  }
}

export {
  submitFICAComments,
  attachFICAStoredFile,
  fetchLatestFICADetails,
  submitFICADetails,
  fetchLatestProofOfPayment,
  submitEFTProof,
  fetchOnboardingTasks,
  onboardingSetupComplete,
  isTaskCompleted,
  fetchBusinessOwnerDetails,
  fetchBusinessVetting,
  fetchBusinessDetails,
  postBusinessDetails,
  updateBusinessAddressSubmitErrorMessage,
  updateBusinessTradingAddress,
  updateVettingFetchErrorMessage,
  launchAddressPopup,
  launchUploadPopup,
  onSetPassportIdPopupVisible,
  onSetIdDetailsPopupVisible,
  launchFicaIidentifiiPopup,
  updateIidentifiiIFrameUrl,
  updateIidentifiiIFrameIsLoading,
  updateIidentifiiErrorMessage,
  onSetIidentifiiCompletePopupVisible,
  iidentifiiComplete,
  postIidentifiiIdNumber,
  launchFicaIidentifiiFailedPopup,
  verifyIdentityNumber,
  setKYCPopupVisible,
  firstSettlementPayoutStatus,
  shouldInitiateVetting,
  isOwnerInfoFound,
  updateBuyCardMachineUserInfoFromVettingInformation,
  updateUserInfoFromVettingInformation,
  eftProofFetching,
  eftFetchedProof,
  eftProofSubmitting,
  eftProofSubmitted,
  eftProofError,
  ficaFetching,
  ficaFetched,
  ficaFileAttaching,
  ficaFileAttached,
  ficaSubmittingComments,
  ficaSubmittedComments,
  ficaSubmitting,
  ficaSubmitted,
  ficaError,
  BUSINESS_VETTING_ACTIONS,
  progressSelector,
  FIRST_SETTLEMENT_STATE,
  FICA_STATUSES,
  BANK_REQUEST_STATUSES,
  BUSINESS_TYPES,
  TASK_TYPES,
  TASK_STEPS,
  TASK_ICONS,
  FICA_METHODS,
  PROGRESS_STATUS,
  PROGRESS_TYPES,
  DELIVERY_STATUS,
  PAYMENT_STATE,
  ACTIONS,
  FORM_NAMES,
  PAYMENT_METHOD,
}
