import { callJSONApi } from 'libs/api'
import { initializeForm, multipleFieldsUpdated } from 'redux/modules/forms'

import { fetchOnboardingTasks, FORM_NAMES } from '../index'

import {
  FICA_ACTIONS,
  ficaSubmitted,
  ficaError,
  ficaSubmitting,
  ficaFetching,
  ficaFetched,
  ficaFileAttaching,
  ficaFileAttached,
  ficaSubmittingComments,
  ficaSubmittedComments,
  launchUploadPopup,
  onSetIdDetailsPopupVisible,
  onSetPassportIdPopupVisible,
  setKYCPopupVisible,
  launchFicaIidentifiiPopup,
  updateIidentifiiIFrameUrl,
  updateIidentifiiIFrameIsLoading,
  updateIidentifiiErrorMessage,
  onSetIidentifiiCompletePopupVisible,
  launchFicaIidentifiiFailedPopup,
  verifyIdentityNumber,
} from './actions'

// Enums
export const FICA_STATUSES = {
  MANUAL_DECISION_REQUIRED: 'manual_decision_required',
  IN_PROGRESS: 'in_progress',
  BEING_REVIEWED: 'being_reviewed',
  INCOMPLETE: 'incomplete',
  FAILED: 'failed',
  REQUESTED: 'requested',
}

export const FICA_METHODS = {
  EMAIL_CERTIFIED: 'email_certified',
  IN_PERSON: 'in_person',
  COURIER_ID: 'courier_id',
  COURIER_ID_ADDRESS: 'courier_id_address',
}

const initializeFicaVerificationForm = (dispatch, vettingInformation) => {
  dispatch(
    initializeForm(FORM_NAMES.FICA_VERIFICATION, {
      idNumber: vettingInformation.personal && vettingInformation.personal.idNumber,
      firstName: vettingInformation.personal && vettingInformation.personal.firstName,
      lastName: vettingInformation.personal && vettingInformation.personal.lastName,
      passport: vettingInformation.personal && vettingInformation.personal.passportNumber,
    })
  )
}

export function fetchLatestFICADetails() {
  return (dispatch) => {
    dispatch(ficaFetching())
    callJSONApi(
      `/onboarding/fica/`,
      'GET',
      {},
      (response) => {
        console.log('Response from Core [fetchFICA]')
        if (response.status === 200) {
          const { ficaVerification } = response.data
          const { storedFiles } = ficaVerification

          const identityDocumentFileSubmitted = storedFiles.find(
            (storedFile) => storedFile.grouping === 'fica-id'
          )

          dispatch(
            ficaFetched({
              identityDocumentFileSubmitted,
              ficaVerificationRequest: ficaVerification,
            })
          )
        }
      },
      (prettyError, jqXHR, textStatus, errorThrown) => {
        console.error(
          'Error response from Core [fetchFICA] :::',
          prettyError,
          jqXHR,
          textStatus,
          errorThrown
        )
        dispatch(ficaError(prettyError))
      }
    )
  }
}

export function submitFICADetails(dismissUploadIDPopup) {
  return (dispatch) => {
    dispatch(ficaSubmitting())
    callJSONApi(
      `/onboarding/fica/`,
      'POST',
      {},
      (response) => {
        console.log('Response from Core [submitFICA]')
        if (response.status === 200) {
          // we have successfully submitted to core
          // this should refresh the current tasks with the updated state
          dispatch(ficaSubmitted())
          dispatch(fetchOnboardingTasks())
          dispatch(fetchLatestFICADetails())
        }
        if (dismissUploadIDPopup) {
          dismissUploadIDPopup()
        }
      },
      () => {
        dispatch(
          ficaError(
            'Failed uploading fica documents, please check internet connection and submit again'
          )
        )
      }
    )
  }
}

export function attachFICAStoredFile(storedFileData) {
  return (dispatch) => {
    dispatch(ficaFileAttaching())
    callJSONApi(
      `/onboarding/fica/attach-stored-file`,
      'POST',
      storedFileData,
      (response) => {
        console.log('Response from Core [attachFileFICA]')
        if (response.status === 200) {
          // we have successfully submitted to core
          // this should refresh the current tasks with the updated state
          dispatch(ficaFileAttached())
          dispatch(fetchLatestFICADetails())
        }
      },
      (prettyError, jqXHR, textStatus, errorThrown) => {
        console.error(
          'Error response from Core [attachFileFICA]',
          prettyError,
          jqXHR,
          textStatus,
          errorThrown
        )
        dispatch(ficaError(prettyError))
      }
    )
  }
}

export function submitFICAComments(data) {
  return (dispatch) => {
    dispatch(ficaSubmittingComments())
    callJSONApi(
      '/comments/',
      'POST',
      data,
      (response) => {
        console.log('Response from Core [submitFICAComments]')
        if (response.status === 200) {
          // we have successfully submitted to core
          // this should refresh the current tasks with the updated state
          dispatch(ficaSubmittedComments())
          dispatch(fetchLatestFICADetails())
        }
      },
      (prettyError, jqXHR, textStatus, errorThrown) => {
        console.error(
          'Error response from Core [submitFICAComments] :::',
          prettyError,
          jqXHR,
          textStatus,
          errorThrown
        )
        dispatch(ficaError(prettyError))
      }
    )
  }
}

const iidentifiiComplete = () => (dispatch) => {
  callJSONApi(
    '/onboarding/fica/iidentifiiVerficationComplete',
    'POST',
    {},
    () => null,
    () =>
      dispatch(
        updateIidentifiiErrorMessage({
          iidentifiiCompleteError: `Something went wrong verifying your ID, please try again.`,
        })
      )
  )
}

const updateFicaVerificationFromVettingInformation = (dispatch, vettingInformation) => {
  dispatch(
    multipleFieldsUpdated(FORM_NAMES.FICA_VERIFICATION, {
      firstName: vettingInformation.personal && vettingInformation.personal.firstName,
      lastName: vettingInformation.personal && vettingInformation.personal.lastName,
      idNumber: vettingInformation.personal && vettingInformation.personal.idNumber,
      passportNumber: vettingInformation.personal && vettingInformation.personal.passportNumber,
      southAfricanID: !!(vettingInformation.personal && vettingInformation.personal.idNumber),
      southAfricanPassport: !!(
        vettingInformation.personal && vettingInformation.personal.passportNumber
      ),
    })
  )
}

const updateIidentifiiFromVettingInformation = (dispatch, vettingInformation) => {
  dispatch(
    multipleFieldsUpdated(FORM_NAMES.IIDENTIFII, {
      personal: {
        firstName: vettingInformation.personal && vettingInformation.personal.firstName,
        lastName: vettingInformation.personal && vettingInformation.personal.lastName,
        idNumber: vettingInformation.personal && vettingInformation.personal.idNumber,
      },
    })
  )
}

const postIidentifiiIdNumber = (idNumber, onSuccessfulCallback) => (dispatch) => {
  callJSONApi(
    `/onboarding/fica/iidentifiiSessionToken?idNumber=${idNumber}`,
    'POST',
    {},
    (response) => {
      if (response.status === 200) {
        const { url } = response.data
        dispatch(updateIidentifiiIFrameUrl(url))
        dispatch(updateIidentifiiIFrameIsLoading(true))
        dispatch(updateIidentifiiErrorMessage({}))
        onSuccessfulCallback()
      }
    },
    () => {
      dispatch(
        updateIidentifiiErrorMessage({
          scanIdPassportError: `Failed id verification, please try again`,
        })
      )
      dispatch(verifyIdentityNumber(false))
    }
  )
}

export {
  FICA_ACTIONS,
  ficaSubmitted,
  ficaError,
  ficaSubmitting,
  ficaFetching,
  ficaFetched,
  ficaFileAttaching,
  ficaFileAttached,
  ficaSubmittingComments,
  ficaSubmittedComments,
  launchUploadPopup,
  onSetIdDetailsPopupVisible,
  onSetPassportIdPopupVisible,
  setKYCPopupVisible,
  launchFicaIidentifiiPopup,
  updateIidentifiiIFrameUrl,
  updateIidentifiiIFrameIsLoading,
  updateIidentifiiErrorMessage,
  onSetIidentifiiCompletePopupVisible,
  launchFicaIidentifiiFailedPopup,
  verifyIdentityNumber,
  iidentifiiComplete,
  updateFicaVerificationFromVettingInformation,
  updateIidentifiiFromVettingInformation,
  initializeFicaVerificationForm,
  postIidentifiiIdNumber,
}
