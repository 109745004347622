import React from 'react'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import { fieldUpdated } from 'redux/modules/forms'
import { FORM_NAMES } from 'redux/modules/onboarding'
import { isFlagship, shouldRemoveLoader } from 'libs/utils'

import { connect } from 'react-redux'
import classes from './styles.module.scss'
import TradingAddressForm from './components/TradingAddressForm'

const sanitizeRegistrationNumber = (event, updateRegistrationNumber) => {
  let registrationNumber = event.target.value.replace(/[^0-9]+/g, '')
  if (registrationNumber.length > 10) {
    registrationNumber = `${registrationNumber.substring(0, 4)}/${registrationNumber.substring(
      4,
      registrationNumber.length
    )}`
    registrationNumber = `${registrationNumber.substring(0, 11)}/${registrationNumber.substring(
      11,
      registrationNumber.length + 1
    )}`
  } else if (registrationNumber.length > 4) {
    registrationNumber = `${registrationNumber.substring(0, 4)}/${registrationNumber.substring(
      4,
      registrationNumber.length
    )}`
  }
  updateRegistrationNumber(registrationNumber)
}

function BusinessInfoForm({
  onFailedSubmit,
  firstLine,
  updateFirstLine,
  updateAddressFields,
  updateRegistrationNumber,
  onSuccess,
  showForms,
  provinces,
}) {
  React.useEffect(() => {
    if (window.ReactNativeWebView && shouldRemoveLoader) {
      window.ReactNativeWebView.postMessage('removeLoader')
    }
  }, [])
  return (
    <div>
      {showForms.addressForm && (
        <>
          <h4 className={isFlagship ? classes.flagshipSubheading : classes.subheading}>
            {isFlagship ? 'Trading address' : 'Business address'}
          </h4>
          <TradingAddressForm
            firstLine={firstLine}
            updateFirstLine={updateFirstLine}
            updateAddressFields={updateAddressFields}
            onFailedAddressSubmit={onFailedSubmit}
            onSuccess={onSuccess}
            provinces={provinces}
          />
        </>
      )}

      {showForms.companyRegistrationForm && (
        <forms.Form
          action='/business/companyRegistrationNumber'
          name={FORM_NAMES.COMPANY_REGISTRATION_NUMBER}
          onFail={onFailedSubmit}
          onSuccess={onSuccess}
        >
          <forms.FormBlock>
            <h4 className={classes.subheading}>Company Registration Number</h4>
            <forms.TextField
              name='companyRegistrationNumber'
              label='Company Registration Number'
              required
              isFullWidth
              onChange={(event) => sanitizeRegistrationNumber(event, updateRegistrationNumber)}
              maxLength={14}
              showRemaining={false}
              subTitle='Please use the format XXXX/XXXXXX/XX'
              validators={[new forms.RegisteredBusinessNumberValidator()]}
            />
          </forms.FormBlock>
        </forms.Form>
      )}
    </div>
  )
}

BusinessInfoForm.propTypes = {
  firstLine: PropTypes.string,
  updateFirstLine: PropTypes.func,
  updateAddressFields: PropTypes.func,
  onFailedSubmit: PropTypes.func,
  updateRegistrationNumber: PropTypes.func,
  onSuccess: PropTypes.func,
  showForms: PropTypes.object,
  provinces: PropTypes.array,
}

const mapDispatchToProps = (dispatch) => ({
  updateRegistrationNumber: (registrationNumber) => {
    dispatch(
      fieldUpdated(
        FORM_NAMES.COMPANY_REGISTRATION_NUMBER,
        'companyRegistrationNumber',
        registrationNumber
      )
    )
  },
})

export default connect(null, mapDispatchToProps)(BusinessInfoForm)
