import React from 'react'
import PropTypes from 'prop-types'

import Spinner from './Spinner'
import classes from './LoadingView.module.scss'

const getLoaderProps = (test, fixedPosition, loaderClassName, loaderOnTable) => {
  let show = true
  if (test !== undefined) {
    show = test
  }

  let style = {}
  if (fixedPosition) {
    style = { position: 'fixed' }
  }

  let className = classes.loadingView
  if (loaderClassName) {
    className = `${className} ${loaderClassName}`
  }

  let spinnerClass = `${classes.spinnerContainer} spinner-container`
  if (loaderOnTable) {
    spinnerClass += ` ${classes.spinnerOnTable}`
    className += ` ${classes.loaderOnTable}`
  }

  return {
    show,
    className,
    spinnerClass,
    style,
  }
}

function LoadingView({ test, fixedPosition, className: loaderClassName, loaderOnTable, message }) {
  const { show, className, spinnerClass, style } = getLoaderProps(
    test,
    fixedPosition,
    loaderClassName,
    loaderOnTable
  )

  if (show) {
    return (
      <div className={className} style={style}>
        <div className={spinnerClass}>
          <Spinner blue />
          <div className={classes.message}>{message}</div>
        </div>
      </div>
    )
  }
  return null
}

LoadingView.propTypes = {
  test: PropTypes.bool,
  fixedPosition: PropTypes.bool,
  className: PropTypes.string,
  loaderOnTable: PropTypes.bool,
  message: PropTypes.string,
}

export default LoadingView
