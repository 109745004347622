/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getProvinces } from 'libs/utils'
import localisation from 'libs/localisation/localisation'

import {
  onExpandClick,
  getTaskStepClass,
  getTaskCardContainerClass,
  renderTaskCardIcon,
  getTaskTitle,
  getTaskStepLabel,
  renderTaskCardButtonOrExpansionIcon,
  renderTaskCardPopupOrInlineContent,
} from 'components/onboarding/TaskCard/utils'
import classes from 'components/onboarding/onboarding.module.scss'

class TaskCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  componentDidMount() {
    getProvinces(this.props.localisation.get('countryCode'), (data) =>
      this.setState({ provinces: data.data.map((p) => ({ label: p.name, value: p.name })) })
    )
  }

  render() {
    const { task, message = '', hideStepLabel = false } = this.props

    const onExpandClickProps = {
      task,
      expanded: this.state.expanded,
      handleExpandClick: (expanded) => this.setState({ expanded: !expanded }),
    }
    return (
      <div className={getTaskCardContainerClass(this.state.expanded)}>
        <div
          className={classNames(classes.taskCard, 'card-shadow')}
          onClick={() => onExpandClick(onExpandClickProps)}
        >
          {renderTaskCardIcon(task)}
          <div className={classes.taskDescriptionContainer}>
            <div className={classes.taskHeading}>{getTaskTitle(task)}</div>

            {!hideStepLabel && (
              <div className={getTaskStepClass(this.props)}>{getTaskStepLabel(this.props)}</div>
            )}

            {message && <p className={classes.taskMessage}>{message}</p>}
          </div>
          {renderTaskCardButtonOrExpansionIcon({ ...this.props, ...this.state })}
        </div>
        {renderTaskCardPopupOrInlineContent({ ...this.props, ...this.state })}
      </div>
    )
  }
}

TaskCard.propTypes = {
  message: PropTypes.string,
  hideStepLabel: PropTypes.bool,
  task: PropTypes.object.isRequired,
  localisation: PropTypes.objectOf(Map),
}

export default connect((state) => ({
  localisation: localisation(state),
}))(TaskCard)
