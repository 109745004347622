import { List, Map } from 'immutable'

const eftSelector = (state) => state.onboarding.get('eft', Map())

const eftFlag = (name) => (state) => !!eftSelector(state).get(name)

export const isSubmittingEFTSelector = eftFlag('isSubmittingEFT')
export const isEFTSubmittedSelector = eftFlag('isSubmittedSuccessfully')
export const isFetchingEFTSelector = eftFlag('isFetchingEFT')

export const errorMessageEFTSelector = (state) => eftSelector(state).get('eftErrorMessage') || null
export const uploadedEFTProofSelector = (state) =>
  eftSelector(state).get('uploadedEFTProof') || null

const onboardingSelector = (state) => state.onboarding || Map()

export const onboardingTasksSelector = (state) => onboardingSelector(state).get('tasks', List())

export const shouldLoadOnboardingSelector = (state) =>
  onboardingTasksSelector(state).get('tasks', List()).isEmpty()

const orderSelector = (state) => onboardingSelector(state).get('firstBusinessOrder', Map())

export const isDeliveryMethodCcdSelector = (state) => {
  return orderSelector(state)
    ? orderSelector(state).getIn(['deliveryDetails', 'deliveryMethod']) === 'ccd'
    : false
}

export const fulfillmentSelector = (state) => {
  return orderSelector(state) && orderSelector(state).get('fulfilmentState')
}

export const paymentStateSelector = (state) => {
  return orderSelector(state) && orderSelector(state).get('paymentState')
}

export const isDeliveryMethodCourierSelector = (state) => {
  return orderSelector(state)
    ? orderSelector(state).getIn(['deliveryDetails', 'deliveryMethod']) === 'courier'
    : false
}

export const paymentMethodSelector = (state) => {
  return orderSelector(state) && orderSelector(state).get('paymentMethod')
}
