import { Map } from 'immutable'
import { createSelector } from 'reselect'

import { FORM_NAMES } from 'redux/modules/onboarding'

const formsNode = (state) => state.forms || Map()
const onboardingNode = (state) => state.onboarding
const sessionNode = (state) => state.session

const ficaSelector = (state) => state.onboarding.get('fica', Map())

const ficaFlag = (name) => (state) => !!ficaSelector(state).get(name)

export const isFetchingFICASelector = ficaFlag('isFetchingFICA')
export const isSubmittingFICACommentsSelector = ficaFlag('isSubmittingFICAComments')
export const isAttachingFICAFileSelector = ficaFlag('isAttachingFICAFile')
export const isSubmittingFICASelector = ficaFlag('isSubmittingFICA')
export const isFICASubmittedSelector = ficaFlag('isSubmittedSuccessfully')
export const isFICAAttachedSelector = ficaFlag('isAttachedSuccessfully')
export const isFICACommentsSubmittedSelector = ficaFlag('isSubmittedCommentsSuccessfully')

export const errorMessageFICASelector = (state) =>
  ficaSelector(state).get('ficaErrorMessage') || null
export const uploadedIdentityDocumentSelector = (state) =>
  ficaSelector(state).get('uploadedIdentityDocument') || null
export const ficaVerificationRequestSelector = (state) =>
  ficaSelector(state).get('ficaVerificationRequest') || null

export const isUploadFicaSubmitButtonDisabled = createSelector(
  [formsNode, onboardingNode, sessionNode],
  (formData, onBoardingData, sessionData) => {
    const ficaDocumentIsUploaded = onBoardingData.getIn(['fica', 'uploadedIdentityDocument'])
    const isTheBusinessOwnerInfoMissing = sessionData.getIn([
      'business',
      'info',
      'businessOwnerInfoIsMissing',
    ])
    const { firstName, lastName, idNumber, passportNumber } = (
      formData.getIn([FORM_NAMES.FICA_VERIFICATION, 'data']) || Map()
    ).toJS()

    const firstNameIsNotEmpty = firstName && firstName.length > 0
    const lastNameIsNotEmpty = lastName && lastName.length > 0
    const idOrPassportNumberIsNotEmpty =
      (idNumber && idNumber.length > 0 && idNumber.length === 13) ||
      (passportNumber && passportNumber.length > 0)

    const FicaFormIsNotComplete = !(
      ficaDocumentIsUploaded &&
      firstNameIsNotEmpty &&
      lastNameIsNotEmpty &&
      idOrPassportNumberIsNotEmpty
    )

    switch (isTheBusinessOwnerInfoMissing) {
      case true:
        return FicaFormIsNotComplete
      default:
        return !ficaDocumentIsUploaded
    }
  }
)

export const ficaUploadPopupInfo = createSelector(onboardingNode, (onBoardingData) => ({
  showUploadIDPopup: onBoardingData.getIn(['fica', 'ficaUploadPopup', 'showUploadIDPopup']),
  showCCDDetailsPopup: onBoardingData.getIn(['fica', 'ficaUploadPopup', 'showCCDDetailsPopup']),
  isCitizenshipSelected: onBoardingData.getIn(['fica', 'ficaUploadPopup', 'isCitizenshipSelected']),
}))

export const shouldLaunchFicaPassportIdPopup = createSelector(onboardingNode, (onboardingData) =>
  onboardingData.getIn(['fica', 'ficaPassportIdPopup', 'isPassportIdPopupVisible'])
)

export const shouldLaunchFicaVerificationModal = createSelector(onboardingNode, (onboardingData) =>
  onboardingData.getIn(['fica', 'isKYCPopupVisible'])
)

export const shouldLaunchFicaIdDetailsPopup = createSelector(onboardingNode, (onboardingData) =>
  onboardingData.getIn(['fica', 'ficaIdDetailsPopup', 'isIdDetailsPopupVisible'])
)

export const shouldLaunchFicaIidentifiiPopup = createSelector(onboardingNode, (onBoardingData) =>
  onBoardingData.getIn(['fica', 'iidentifiiPopup', 'showIidentifiiPopup'])
)

export const isVerifyingIidentifiiIdNumber = createSelector(onboardingNode, (onBoardingData) =>
  onBoardingData.getIn(['fica', 'iidentifiiPopup', 'isVerifyingIdNumber'])
)

export const iFrameUrl = createSelector(onboardingNode, (onBoardingData) =>
  onBoardingData.getIn(['fica', 'iidentifiiPopup', 'iFrameUrl'])
)

export const iFrameIsLoading = createSelector(onboardingNode, (onBoardingData) =>
  onBoardingData.getIn(['fica', 'iidentifiiPopup', 'iFrameIsLoading'])
)

export const shouldLaunchFicaFailedIidentifiiPopup = createSelector(
  onboardingNode,
  (onBoardingData) => ({
    isVisible: onBoardingData.getIn([
      'fica',
      'iidentifiiPopup',
      'failedIidentifiiPopup',
      'isVisible',
    ]),
    errorType: onBoardingData.getIn([
      'fica',
      'iidentifiiPopup',
      'failedIidentifiiPopup',
      'errorType',
    ]),
  })
)

export const shouldLaunchFicaIidentifiiCompletePopup = createSelector(
  onboardingNode,
  (onboardingData) =>
    onboardingData.getIn(['fica', 'iidentifiiCompletePopup', 'isIidentifiiCompletePopupVisible'])
)
