import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import { InvoiceSingle } from 'containers/invoices/components/Invoices'

import { fetchInvoiceOnboardingData } from '../utils'

function TaskFirstInvoice({ onClose, children }) {
  const [invoice, setInvoice] = useState(new Map())

  return (
    <InvoiceSingle
      invoice={invoice}
      setInvoice={(nextInvoice) => {
        if (!nextInvoice) {
          onClose()
        }
        fetchInvoiceOnboardingData({ showLoader: true })
        setInvoice(nextInvoice)
      }}
    >
      {children}
    </InvoiceSingle>
  )
}

TaskFirstInvoice.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default TaskFirstInvoice
