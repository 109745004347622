/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import { FORM_NAMES, fetchOnboardingTasks } from 'redux/modules/onboarding'
import { isFlagship, shouldRemoveLoader } from 'libs/utils'

import { connect } from 'react-redux'
import {
  bankUpdateRequestUploadedDocuments,
  selectCanEditBankAccount,
  selectIsBankStatementRequired,
} from 'redux/modules/onboarding/bankDetails/selectors'
import FlatButton from 'components/buttons/FlatButton'
import { track } from 'libs/analytics'
import { wasRoutedFromApp } from 'redux/modules/routes'
import { updateBankAccountUpdateError } from 'redux/modules/onboarding/bankDetails/actions'
import {
  fetchBankUpdateRequest,
  onFetchBankAccountUpdateRequest,
} from 'redux/modules/onboarding/bankDetails'
import UploadedDocumentField from './components/UploadDocumentField'
import FlagShipBankStatementMessage from './components/FlagShipBankStatementMessage'
import { sanitizeAccountNumber } from '../../utils'

function EditBankDetailsForm({
  onSuccess: onSuccessCallback,
  onLoaded,
  onCancelButtonClick,
  isEnabled,
  inApp,
  canEditBankAccount,
  storedFiles,
  isBankStatementRequired,
  validateAccountNumber,
  refreshOnboardingTasks,
  clearBankAccountUpdateError,
  onFetchCallback,
  refreshBankUpdateRequest,
}) {
  React.useEffect(() => {
    if (window.ReactNativeWebView && shouldRemoveLoader) {
      window.ReactNativeWebView.postMessage('removeLoader')
    }
  }, [])
  const [hasFormChanged, setHasFormChanged] = React.useState(false)
  const [successfulUpload, setSuccessfulUpload] = React.useState(false)

  const isEditable = canEditBankAccount && isEnabled

  const hasDocumentsIfRequired = !isBankStatementRequired || successfulUpload
  const canSubmitForm = canEditBankAccount && hasFormChanged && hasDocumentsIfRequired

  const onFetch = (response) => {
    if (onLoaded) {
      onLoaded(response)
    }

    if (onFetchCallback) {
      onFetchCallback(response)
    }
  }

  const onSuccess = (response) => {
    // TODO: Let's clarify these event names with data
    track('complete_account_bank_details_submit_button_clicked', { inApp, flagship: isFlagship })
    track('add_bank_account_submit_button_clicked')
    track('portal_bank_details_submit_button_clicked')

    clearBankAccountUpdateError()

    // A bank account update request has now been captured for this business. We therefore need to a trigger a
    // refresh in the onboarding tasks to reflect a change in the "bank details" task's state.
    refreshOnboardingTasks()

    refreshBankUpdateRequest()

    if (onSuccessCallback) {
      onSuccessCallback(response)
    }
  }

  return (
    <forms.Form
      name={FORM_NAMES.BANK_DETAILS}
      fetchAPI='/business/bank-account'
      action='/business/bank-account'
      callback={onFetch}
      onSuccess={onSuccess}
      showSuccess
      onChange={() => setHasFormChanged(true)}
    >
      <forms.FormBlock>
        <div className='clearfix'>
          <forms.TextField
            name='accountNumber'
            label='Account number'
            maxLength={13}
            showRemaining={false}
            validators={[
              new forms.RequiredValidator('This field is required'),
              new forms.NumberValidator(false, undefined, undefined, {
                notNumeric: 'You must provide a bank account number - numbers only',
              }),
            ]}
            disabled={!isEditable}
            onChange={validateAccountNumber}
          />

          <forms.SelectField
            name='accountType'
            label='Account type'
            placeholder='Select...'
            options={[
              {
                value: 'Cheque',
                label: 'Cheque',
              },
              {
                value: 'Savings',
                label: 'Savings',
              },
            ]}
            clearable={false}
            validators={[new forms.RequiredValidator('You must select an account type')]}
            disabled={!isEditable}
          />

          <forms.SelectField
            name='bank'
            label='Bank'
            placeholder='Select...'
            propertyGroup='banks'
            validators={[new forms.RequiredValidator('You must select a bank')]}
            clearable={false}
            disabled={!isEditable}
          />
        </div>

        {canEditBankAccount && isBankStatementRequired && (
          <UploadedDocumentField
            documentTitle='Documents required'
            documentSubtext={
              isFlagship
                ? 'Bank statement'
                : 'Please upload a bank statement or account confirmation letter.'
            }
            fileUploadID='bankStatementID'
            grouping='ahv_bank_statement'
            uploadedDocument={storedFiles.bankStatement}
            onFileUploadSuccess={() => setSuccessfulUpload(true)}
          />
        )}
      </forms.FormBlock>

      {isBankStatementRequired && isFlagship ? <FlagShipBankStatementMessage /> : null}

      {isEditable ? (
        <>
          <FlatButton
            label='Cancel'
            type='reset'
            className='secondary'
            onClick={() => {
              onCancelButtonClick()
            }}
          />
          <FlatButton
            label='Submit'
            type='submit'
            className='blueBackground'
            disabled={!canSubmitForm}
          />
        </>
      ) : null}
    </forms.Form>
  )
}

EditBankDetailsForm.propTypes = {
  onSuccess: PropTypes.func,
  onLoaded: PropTypes.func,
  onCancelButtonClick: PropTypes.func,
  isEnabled: PropTypes.bool,
  inApp: PropTypes.bool.isRequired,
  canEditBankAccount: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  storedFiles: PropTypes.object.isRequired,
  isBankStatementRequired: PropTypes.bool.isRequired,
  validateAccountNumber: PropTypes.func.isRequired,
  refreshOnboardingTasks: PropTypes.func.isRequired,
  clearBankAccountUpdateError: PropTypes.func.isRequired,
  onFetchCallback: PropTypes.func.isRequired,
  refreshBankUpdateRequest: PropTypes.func.isRequired,
}

EditBankDetailsForm.defaultProps = {
  onSuccess: undefined,
  isEnabled: false,
}

const mapStateToProps = (state) => ({
  inApp: wasRoutedFromApp(state),
  canEditBankAccount: selectCanEditBankAccount(state),
  storedFiles: bankUpdateRequestUploadedDocuments(state),
  isBankStatementRequired: selectIsBankStatementRequired(state),
})

const mapDispatchToProps = (dispatch) => ({
  validateAccountNumber: (event) => {
    return sanitizeAccountNumber(FORM_NAMES.BANK_DETAILS, event, dispatch)
  },
  refreshOnboardingTasks: () => dispatch(fetchOnboardingTasks()),
  clearBankAccountUpdateError: () => dispatch(updateBankAccountUpdateError()),
  onFetchCallback: (response) => {
    // This callback is triggered when the form component has finished fetching data from the 'fetchAPI' prop.
    // We call the exported onFetchBankAccountUpdateRequest function which handles processing of this data.
    // This is shared by both this component and the dispatchable fetchBankUpdateRequest used by the
    // BankDetailsPopup component.
    onFetchBankAccountUpdateRequest(dispatch, response)
  },
  refreshBankUpdateRequest: () => dispatch(fetchBankUpdateRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditBankDetailsForm)
