import React from 'react'

import classes from './styles.module.scss'

function InstructionsNote() {
  return (
    <div className={classes.noteWrapper}>
      <p className={classes.noteContainer}>
        <span className={classes.note}>Please note: </span>
        If someone else is accepting your delivery, they will need a copy of the business
        owner&apos;s ID as well as a letter of authority.
      </p>
    </div>
  )
}

export default InstructionsNote
