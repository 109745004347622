/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { businessName, businessUUID } from 'redux/modules/session/selectors'
import { doApi } from 'libs/api'
import { getQueryParams } from 'libs/queryParams'
import { getENV } from 'libs/utils'
import yocoImage from 'assets/images/yoco.png'
import errorSVG from 'assets/images/error.svg'

import LoadingScreen from '../components/LoadingScreen'
import FailedScreen from '../components/FailedScreen'

function ShopifyInstallationPage({ businessUUID = '', businessName = '' }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isRequesting, setIsRequesting] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState(null)

  const params = getQueryParams(true)
  const { hmac } = params
  const authorizationCode = params.code
  const hostName = params.host
  const { shop } = params
  const installationUUID = params.state
  const { timestamp } = params

  const data = {
    hmac,
    parameters: window.location.search.substr(1),
    authorizationCode,
    hostName,
    businessName,
    businessUUID,
    shop,
    installationUUID,
    timestamp,
  }

  const handleSuccess = (response) => {
    setRedirectUrl(response.redirect_url)
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && redirectUrl) {
      setTimeout(() => (window.location.href = redirectUrl), 3000)
    }
  }, [redirectUrl])

  useEffect(() => {
    if (!isRequesting && !redirectUrl) {
      setIsRequesting(true)
      doApi(
        'shopify/install/complete',
        'POST',
        data,
        (response) => {
          handleSuccess(response)
        },
        (error) => {
          console.log('error', error)
          setIsLoading(false)
        },
        false,
        getENV('PLUGIN_URL')
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequesting, redirectUrl])

  return redirectUrl || isLoading ? (
    <LoadingScreen
      image={yocoImage}
      title={redirectUrl ? 'Redirecting' : "Okay, we're configuring Yoco App"}
      subtitle={redirectUrl ? "In a few seconds you'll be redirected to:" : ''}
      url={redirectUrl || ''}
      urlText='Your Shopify Account'
    />
  ) : (
    <FailedScreen
      image={errorSVG}
      title='Installation failed'
      subtitle='Please contact support'
      url={shop ? `https://${shop}/admin` : ''}
      urlText="We'll redirect you back to your Shopify store"
    />
  )
}

ShopifyInstallationPage.propTypes = {
  businessName: PropTypes.string.isRequired,
  businessUUID: PropTypes.string.isRequired,
}

export default connect((state) => ({
  businessName: businessName(state),
  businessUUID: businessUUID(state),
}))(ShopifyInstallationPage)
