/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-globals */
import KYCWebSDK, { FICAVerificationModal } from '@yoco/kyc-web-sdk'
import React, { useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { track } from 'libs/analytics'
import { getENV } from 'libs/utils'
import { businessInfoSelector, userDetails } from 'redux/modules/session/selectors'
import { fetchOnboardingTasks } from 'redux/modules/onboarding'
import { getToken } from 'libs/api'

function KYCPopup({
  setKYCPopupVisible,
  isKYCPopupVisible,
  businessInfo,
  onFetchOnboardingTasks,
  onError,
  userDetails,
}) {
  useEffect(() => {
    KYCWebSDK.overrideApiURL(`${getENV('KYC_API_URL')}api/client/v1/kyc`)
    KYCWebSDK.setApiAuthToken(getToken())

    const onfidoEventTracking = (event) => {
      if (event.detail) {
        const onfidoEvent = event.detail.eventName ?? 'unknown_event'
        const eventName = `portal_KYC_${onfidoEvent}`
        track(eventName, { onfido: event.detail })
      }
    }

    addEventListener('userAnalyticsEvent', onfidoEventTracking)

    return () => {
      removeEventListener('userAnalyticsEvent', onfidoEventTracking)
    }
  }, [])

  const onSuccess = () => {
    track('portal_KYC_complete')
  }

  const onDismiss = useCallback(() => {
    track('portal_KYC_closed')
    setKYCPopupVisible(false)
    onFetchOnboardingTasks()
  }, [onFetchOnboardingTasks, setKYCPopupVisible])

  const onCancel = () => {
    track('portal_KYC_cancel')
    onDismiss()
  }

  const user = useMemo(() => {
    return {
      uuid: userDetails.get('uuid'),
      first_name: userDetails.get('firstName'),
      last_name: userDetails.get('lastName'),
      id_number: businessInfo.get('ownerIdNumber'),
      passport_number: businessInfo.get('ownerPassport'),
      mobile_number: businessInfo.get('telephoneNumber'),
      business: {
        uuid: userDetails.get('businessUUID'),
      },
    }
  }, [businessInfo, userDetails])

  return (
    <FICAVerificationModal
      user={user}
      isOpen={isKYCPopupVisible}
      onSuccess={onSuccess}
      onError={onError}
      onCancel={onCancel}
      onRequestClose={onDismiss}
    />
  )
}

const mapStateToProps = (state) => ({
  businessInfo: businessInfoSelector(state),
  userDetails: userDetails(state),
})

const mapDispatchToProps = (dispatch) => ({
  onFetchOnboardingTasks: () => dispatch(fetchOnboardingTasks()),
})

KYCPopup.propTypes = {
  isKYCPopupVisible: PropTypes.bool,
  setKYCPopupVisible: PropTypes.func,
  businessInfo: PropTypes.object,
  onFetchOnboardingTasks: PropTypes.func,
  onError: PropTypes.func,
  userDetails: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(KYCPopup)
