import React from 'react'
import PropTypes from 'prop-types'

import SubHeading from 'ui/components/SubHeading'

import classes from './onboarding.module.scss'

export default function DeliveryStatusContent({ title, content }) {
  return (
    <div className={classes.deliveryStatusHeaderContainer}>
      <div className={classes.topContainer}>
        <span className={classes.deliveryStatusTopHeader}>Delivery Status</span>
        <SubHeading>{title}</SubHeading>
      </div>

      <div className={classes.bottomContainer}>{content}</div>
    </div>
  )
}

DeliveryStatusContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
}
