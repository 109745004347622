import React from 'react'
import PropTypes from 'prop-types'

import Spacer from 'ui/layout/Spacer'

import DocumentTypeCard from './components/DocumentTypeCard'
import smartCardIcon from './images/single-neutral-id-card-3.svg'
import passportIcon from './images/earth-3.svg'
import classes from './styles.module.scss'

function UploadIDDocumentType({
  title,
  setSACitizenShip,
  isSACitizen,
  setForeignCitizenship,
  isForeignCitizen,
  businessOwnerInfoIsMissing,
}) {
  return (
    <div className={classes.UploadIDDocumentTypeContainer}>
      <h2 className={classes.documentTypeHeaderText}>
        {title || 'Which identity document are you uploading?'}
      </h2>
      <div className={classes.documentTypeCardsContainer}>
        <DocumentTypeCard
          documentType={isSACitizen}
          description='South African ID'
          documentIcon={smartCardIcon}
          setDocumentType={businessOwnerInfoIsMissing && setSACitizenShip}
          disableCheckbox={!businessOwnerInfoIsMissing}
        />

        <Spacer isHorizontal size='medium' />

        <DocumentTypeCard
          documentType={isForeignCitizen}
          description='Foreign Passport'
          documentIcon={passportIcon}
          setDocumentType={businessOwnerInfoIsMissing && setForeignCitizenship}
          disableCheckbox={!businessOwnerInfoIsMissing}
        />
      </div>
    </div>
  )
}

UploadIDDocumentType.propTypes = {
  title: PropTypes.node,
  isSACitizen: PropTypes.bool,
  setSACitizenShip: PropTypes.func,
  isForeignCitizen: PropTypes.bool,
  setForeignCitizenship: PropTypes.func,
  businessOwnerInfoIsMissing: PropTypes.bool,
}

export default UploadIDDocumentType
