import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import UserAlert from 'components/notifications/UserAlert'
import LoadingView from 'components/loaders/LoadingView'
import { initializeForm } from 'redux/modules/forms'
import * as Onboarding from 'redux/modules/onboarding'
import * as Selectors from 'redux/modules/onboarding/selectors'
import { track } from 'libs/analytics'
import GenericUploadComponent from 'components/files/GenericUploadComponent'

import classes from './EftUpload.module.scss'

const formName = 'eftVerification'

const EFT_REQUIREMENTS_LINK =
  'https://get.yoco.help/hc/en-za/articles/360002184237-Where-do-I-find-my-proof-of-payment-'

class EftUploadComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    // Only setup component/form if it's in the initial state
    this.props.fetchEftProof()
    this.props.dispatch(initializeForm(formName, {}))
  }

  /* ************* CALLBACK METHODS ************ */

  handleUploadError(request, message) {
    if (request.status === 0) {
      this.setState({
        errorDisplayed: true,
        errorMessage: message,
      })
    }
  }

  onClickHere = () => {
    track('[portal] EFT_click_here_instructions')
  }

  onBrowseClicked = () => {
    track('[portal] EFT_click_to_upload')
  }

  onFileUploadSuccess = (response) => {
    track('[portal] EFT_file_uploaded', { response })
    if (response.status !== 200) {
      this.setState({
        errorDisplayed: true,
        errorMessage: response.message,
      })
    }
    // Need to fetch the latest proof of payment to update the view to the correct state
    this.props.fetchEftProof()
  }

  onFileUploadError = (error) => {
    this.handleUploadError(
      error,
      `There was a network error while trying to upload your Proof of Payment document. Please check your internet connection`
    )
  }

  onSubmitDocuments = () => {
    const { uploadedProof } = this.props
    track('[portal] EFT_click_to_submit', { uploadedProof })
    if (uploadedProof) {
      // Now we handle the case where we have a successful upload of a file
      const storedFileUUID = uploadedProof.uuid
      this.props.submitEFTProof(storedFileUUID)
    }
  }

  onDisplayError = () => {
    if (this.state.errorDisplayed) {
      return (
        <UserAlert
          userAlert={{
            htmlMessage: this.state.errorMessage,
            alertType: 'warning',
          }}
          dismissUserAlert={() => this.setState({ errorDisplayed: false })}
          style={{ top: '67px' }}
        />
      )
    }
    return null
  }

  /* ************* RENDER METHODS ************ */
  renderNewBlock() {
    const errorMessage = this.onDisplayError
    return (
      <div style={{ marginBottom: '-30px' }}>
        {this.props.eftErrorMessage ? <p>{this.props.eftErrorMessage}</p> : null}
        {errorMessage}
        {this.renderUploadBlock()}
        {this.renderSubmitEftButton()}
      </div>
    )
  }

  renderUploadBlock() {
    const uploadedDocument = this.props.uploadedProof
    const targetUUID = this.props.referenceNumber
    return (
      <div>
        <div className={classes.header}>
          <p className={classes.helperTextCopy}>
            <a
              href={EFT_REQUIREMENTS_LINK}
              target='_blank'
              rel='noopener noreferrer'
              onClick={this.onClickHere}
            >
              Click here
            </a>
            for instructions on where to find your proof of payment for your bank.
          </p>
          <div className={classes.mainContainer}>
            <GenericUploadComponent
              acceptedFileRE='image/*,application/pdf'
              acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
              acceptedFileTypeError={`
                Only JPEG, PNG, or PDF files are supported for Proof of Payment upload.
              `}
              uploadedDocument={uploadedDocument}
              grouping='pop-id'
              fileUploadID='POPUploadID'
              targetUUID={targetUUID}
              completeCallback={this.onFileUploadSuccess}
              onFileUploadError={this.onFileUploadError}
              onBrowseCallback={this.onBrowseClicked}
            />
          </div>
        </div>
      </div>
    )
  }

  renderSubmitEftButton() {
    const buttonEnabled =
      this.props.uploadedProof && !this.props.isFetchingEFT && !this.props.isSubmittingEFT
    return (
      <div className={classes.inlineActionButtonContainer}>
        <button type='submit' className={classes.inlineActionButton} disabled={!buttonEnabled}>
          Submit
        </button>
      </div>
    )
  }

  render() {
    if (this.props.isSubmittingEFT || this.props.isFetchingEFT) {
      const msg = this.props.isFetchingEFT
        ? 'Fetching Proof of Payment…'
        : 'Submitting Proof of Payment…'
      return (
        <div style={{ flex: '1' }}>
          <LoadingView message={msg} />
        </div>
      )
    }
    return (
      <forms.Form name={formName} persistData onSubmit={this.onSubmitDocuments} showLoader={false}>
        {this.renderNewBlock()}
      </forms.Form>
    )
  }
}

EftUploadComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  referenceNumber: PropTypes.string,
  isSubmittingEFT: PropTypes.bool,
  isFetchingEFT: PropTypes.bool,
  eftErrorMessage: PropTypes.string,
  uploadedProof: PropTypes.object,
  fetchEftProof: PropTypes.func.isRequired,
  submitEFTProof: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  submitEFTProof: (storedFileUUID) => dispatch(Onboarding.submitEFTProof(storedFileUUID)),
  fetchEftProof: () => dispatch(Onboarding.fetchLatestProofOfPayment()),
})

const mapStateToProps = (state) => {
  return {
    isSubmittingEFT: Selectors.isSubmittingEFTSelector(state),
    eftErrorMessage: Selectors.errorMessageEFTSelector(state),
    isFetchingEFT: Selectors.isFetchingEFTSelector(state),
    uploadedProof: Selectors.uploadedEFTProofSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EftUploadComponent)
