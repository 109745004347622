/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { bindActionCreators } from '@reduxjs/toolkit'
import classNames from 'classnames'
import { Icon } from '@yoco/design-system-icons/dist/react'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import { callJSONApi } from 'libs/api'
import { businessName } from 'redux/modules/session/selectors'
import { TransactionTypeIcon } from 'components/transactions'
import { InlineTable, EmptyTableView } from 'libs/tables'
import * as formats from 'libs/formats'
import SettlementBlock from 'components/settlements/SettlementBlock'
import { fetchSettlementSummary } from 'redux/modules/sales/settlement/actions'
import {
  balance,
  balanceRawAmount,
  processingPayout,
  processingPayoutRawAmount,
} from 'redux/modules/sales/settlement/selectors'
import Spacer from 'ui/layout/Spacer'

import classes from './home.module.scss'
import TabBlock from './TabBlock'

class UnconnectedDashboardPage extends Component {
  constructor(props) {
    super(props)

    this.oldIndex = -1
    this.state = {
      insight: undefined,
    }
  }

  UNSAFE_componentWillMount() {
    callJSONApi(
      '/dashboard/periods',
      'GET',
      null,
      (response) => {
        if (response.data) {
          this.setState({
            insight: response.data,
          })
        }
      },
      (prettyError) => {
        console.log(`Failed to fetch: ${prettyError}`)
      }
    )

    callJSONApi(
      '/dashboard/iKentoo',
      'GET',
      null,
      (response) => {
        if (response.data) {
          this.setState({
            iKentoo: response.data.iKentoo,
          })
        }
      },
      (prettyError) => {
        console.log(`Failed to fetch: ${prettyError}`)
      }
    )

    this.props.fetchSettlementSummary()
  }

  renderRecentTransactions() {
    let rowCount = 0
    return (
      <div className={classNames(classes.insightWrapper, 'card-shadow')}>
        <div className={classes.insightHeader}>
          <span>Recent Transactions</span>
          <div className={classes.headerLink}>
            <Link to='/sales/history'>
              <span className={classes.headerText}>View History</span>
              <Icon name='chevron-right' size={24} />
            </Link>
          </div>
        </div>
        <div className={classes.insightBody}>
          <InlineTable
            api='/transactions/'
            name='recentTransactions'
            pageSize={5}
            emptyTable={
              <EmptyTableView title='No recent transactions'>
                <span>
                  There are no recent transactions as you have not yet started transacting.
                </span>
              </EmptyTableView>
            }
            getRow={(immutableRow) => {
              rowCount += 1
              const style =
                rowCount === 5 ? { height: '56px', borderBottom: 'none' } : { height: '56px' }
              const transactions = immutableRow.get('subItems')
              return transactions.map((transaction, rowIndex) => (
                <tr className={classes.underlined} style={style} key={rowIndex}>
                  <td className={classes.icon}>
                    <TransactionTypeIcon transaction={transaction} />
                  </td>
                  <td className={classes.date}>
                    <formats.SimpleDateTime date={transaction.get('created')} />
                  </td>
                  <td className={classes.amount}>
                    <formats.Amount amount={transaction.get('amount')} />
                  </td>
                </tr>
              ))
            }}
          />
        </div>
      </div>
    )
  }

  renderTabArea() {
    return (
      <div className={classes.tabInsight}>
        <TabBlock insight={this.state.insight} iKentoo={this.state.iKentoo} />
      </div>
    )
  }

  render() {
    return (
      <MaxWidthBlock>
        <BlockHeader title='Dashboard' />
        <div className={classes.dashboard}>
          <div className={classes.insightsBanner}>
            <div className={classNames([classes.flexOne, classes.column])}>
              {this.props.balance ? (
                <div>
                  <SettlementBlock header='Balance' amount={this.props.balanceRawAmount} />
                </div>
              ) : null}

              {this.props.processingPayout ? (
                <div style={{ marginTop: 5 }}>
                  <Spacer size='large' />
                  <SettlementBlock
                    header='Next Payout'
                    amount={this.props.processingPayoutRawAmount}
                    text='Learn More'
                    href='https://support.yoco.help/s/article/Payout-Guidelines'
                  />
                </div>
              ) : null}
            </div>
            <div style={{ flex: 1 }}>{this.renderRecentTransactions()}</div>
          </div>
          <Spacer size='large' />
          {this.renderTabArea()}
        </div>
      </MaxWidthBlock>
    )
  }
}

UnconnectedDashboardPage.propTypes = {
  fetchSettlementSummary: PropTypes.func,
  processingPayout: PropTypes.object,
  processingPayoutRawAmount: PropTypes.number,
  balance: PropTypes.object,
  balanceRawAmount: PropTypes.number,
}

const mapStateToProps = (state) => ({
  businessName: businessName(state),
  processingPayout: processingPayout(state),
  processingPayoutRawAmount: processingPayoutRawAmount(state),
  balance: balance(state),
  balanceRawAmount: balanceRawAmount(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSettlementSummary,
    },
    dispatch
  )

const DashboardPage = connect(mapStateToProps, mapDispatchToProps)(UnconnectedDashboardPage)

export default DashboardPage
