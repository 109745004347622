/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import FlatButton from 'components/buttons/FlatButton'
import Popover from 'ui/components/Popover'
import Label from 'libs/forms/Label'
import DisplayTrigger from 'ui/interactions/DisplayTrigger'
import Spacer from 'ui/layout/Spacer'

import classes from './items.module.scss'

export default function AbbreviationEditor({ abbreviation, onClick, onChange, className }) {
  return (
    <div>
      <DisplayTrigger
        contentFactory={(setIsShowing) => (
          <Popover className={classes.abbreviationPopover}>
            <Label>Abbreviation (4 letters maximum)</Label>
            <input
              className={`form-control ${classes.nameInput}`}
              maxLength='4'
              onChange={onChange}
              value={abbreviation}
            />
            <Spacer size='medium' />
            <FlatButton
              label='Done'
              className='blueBackground'
              type='button'
              onClick={() => {
                setIsShowing(false)
              }}
            />
          </Popover>
        )}
      >
        <div className={className} onClick={onClick}>
          <Icon name='small-caps' size={24} />
        </div>
      </DisplayTrigger>
      <span>Abbreviation</span>
    </div>
  )
}

AbbreviationEditor.propTypes = {
  abbreviation: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
}
