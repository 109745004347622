import React from 'react'
import PropTypes from 'prop-types'

import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'
import { track } from 'libs/analytics'

import FailedIidentifiiContent from './components/FailedIidentifiiContent'

function FailedIidentifiiPopup({
  showFailedIidentifiiPopup,
  launchFicaIidentifiiFailedPopup,
  launchFicaPopup,
  buttonLabel,
  errorType,
}) {
  const closeFailedIidentifiiPopup = () => launchFicaIidentifiiFailedPopup({})
  const uploadIDDocument = () => {
    track('portal_fica_iidentifii_click_button_upload_id_manual')
    closeFailedIidentifiiPopup()
    launchFicaPopup({ showUploadIDPopup: true })
  }
  return (
    <PopupContentLayout
      onClosePopup={closeFailedIidentifiiPopup}
      showing={showFailedIidentifiiPopup}
      popupBodyContent={<FailedIidentifiiContent errorType={errorType} />}
      buttonLabel={buttonLabel}
      onFooterButtonClick={uploadIDDocument}
    />
  )
}

FailedIidentifiiPopup.propTypes = {
  buttonLabel: PropTypes.string,
  showFailedIidentifiiPopup: PropTypes.bool,
  launchFicaIidentifiiFailedPopup: PropTypes.func,
  launchFicaPopup: PropTypes.func,
  errorType: PropTypes.string,
}

export default FailedIidentifiiPopup
