import React from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { callJSONApi } from 'libs/api'
import Spinner from 'components/loaders/Spinner'
import CheckboxButton from 'components/buttons/CheckboxButton'
import { makeTestID } from 'libs/utils'

import PaddedContent from '../../ui/layout/PaddedContent'
import FlatButton from '../../components/buttons/FlatButton'
import {
  GoIcon,
  KhumoIcon,
  KhumoPrintIcon,
  LiteIcon,
  NeoIcon,
  ProIcon,
} from '../../assets/images/card-machines/Machines-Icons/index'

import classes from './filters.module.scss'

import { ApiFilter } from '.'

export default class DeviceFilter extends ApiFilter {
  constructor(props) {
    super(props)
    this.offsetProp = props.offsetProp
    this.state = { ...this.state, allSelected: true }
  }

  loadAvailableOptions(defaults) {
    const offset = this.offsetProp * this.pageNumber
    let url = `${this.props.api}?pageSize=5&offset=${offset}`
    if (this.props.additionalParameters) {
      url = `${url}&${this.props.additionalParameters}`
    }
    if (this.state.searchString) {
      url = `${url}&search=${this.state.searchString}`
    }
    if (this.state.defaults) {
      url = `${url}&included=${encodeURIComponent(JSON.stringify(this.state.defaults))}`
    }

    this.requestIndex += 1
    const index = this.requestIndex

    const setOptions = (data, list, newOptions, newKeys) => {
      let tempList = list
      let tempNewOption = newOptions
      if (this.props.defaultPath) {
        tempList = data[this.props.defaultPath]
      } else {
        const key = Object.keys(data)[0]
        tempList = key !== '0' ? (tempList = data[key]) : (tempList = data)
      }

      tempList.forEach((item) => {
        /* eslint-disable no-param-reassign */
        item.uuid = item.serialNumber
        tempNewOption = this.addDisplayableOption(item, newKeys, tempNewOption)
      })

      if (defaults) {
        const found = Object.keys(tempNewOption).some((optionKey) => defaults.includes(optionKey))

        if (!found && defaults[0]) {
          const dummyItem = this.generateDummyItemFromParams(defaults[0])
          if (dummyItem) {
            tempNewOption = this.addDisplayableOption(dummyItem, newKeys, tempNewOption)

            tempNewOption[defaults[0]].selected = true
          }
        }
      }

      return [tempNewOption, newKeys]
    }

    callJSONApi(
      url,
      'GET',
      undefined,
      (data) => {
        if (index === this.requestIndex) {
          this.setState({ loading: false })

          if (data.status > 299) {
            this.showError(data.message)
            return
          }

          let newKeys = []
          const list = []
          let newOptions = {}

          if (offset > 0) {
            newKeys = this.state.availableOptionKeys
            newOptions = this.state.availableOptions
          } else {
            newOptions = this.addSelected(newKeys, newOptions)
          }

          ;[newOptions, newKeys] = setOptions(data.data, list, newOptions, newKeys)

          this.setState({
            availableOptions: newOptions,
            availableOptionKeys: newKeys,
            showLoadMore: list.length === 5,
            error: null,
          })

          this.initializeFilter(this.state.defaults)
          this.setState({
            loading: false,
          })
        }
      },
      (prettyError) => {
        if (index === this.requestIndex) {
          this.setState({
            loading: false,
          })

          this.showError(prettyError)
        }
      }
    )
  }

  typeReaderName(model) {
    /**
     * Keep devices ordered by Yoco release date.
     * device `model` is used to identify the device type.
     */
    switch (model) {
      case 'SHUTTLE':
        return { name: 'Lite', icon: LiteIcon }
      case 'M010':
        return { name: 'Pro', icon: ProIcon }
      case 'QPOS_MINI':
        return { name: 'Go', icon: GoIcon }
      case 'BLUE_PAD55':
        return { name: 'Neo', icon: NeoIcon }
      case 'P5':
        return { name: 'Khumo', icon: KhumoIcon }
      case 'N86':
        return { name: 'Khumo Print', icon: KhumoPrintIcon }
      default:
        return { name: 'Unknown', icon: 'payment-error' }
    }
  }

  addDisplayableOption(option, newKeys, newOptions) {
    const optionKey = option.uuid
    const updatedNewOptions = { ...newOptions }
    if (!newKeys.includes(optionKey)) {
      newKeys.push(optionKey)
      let subtext = option.email
      if (option?.info?.staffNumber) {
        subtext = option.info.staffNumber
      }
      const { icon, name } = this.typeReaderName(option.model)
      console.log(icon)
      updatedNewOptions[optionKey] = {
        // styles require subtext being there so an empty line is better than nothing
        title: name,
        subtext: subtext || `${option[this.props.filterKey]}`,
        selected: false,
        icon,
        // ...(this.props.iconKey && { icon: `${option[this.props.iconKey].icon}` }),
      }
    }

    return updatedNewOptions
  }

  optionSelectionChanged(key) {
    if (key === null) {
      this.setState((state) => {
        return { allSelected: !state.allSelected }
      })
    } else {
      this.setState({ allSelected: false })
    }
    this.props.batch.onFilterChanged(this.props.filterKey, key, this.state.multiSelectEnabled)
  }

  getDropdownContent() {
    let load
    if (this.state.loading) {
      load = <Spinner blue />
    } else if (this.state.showLoadMore) {
      /* eslint-disable react/jsx-no-bind */
      load = (
        <div className='text-center'>
          <FlatButton label='Load more' onClick={this.loadMore.bind(this)} />
        </div>
      )
    }

    return (
      <div className={classes.devicePickerContainer}>
        {this.state.availableOptionKeys.map((key) => {
          const availableOption = this.state.availableOptions[key]

          let subtext
          if (availableOption.subtext) {
            subtext = <div className={classes.subtext}>{availableOption.subtext}</div>
          }

          return (
            <div
              className={classes.optionDevice}
              onClick={this.optionSelectionChanged.bind(this, key)}
              key={key}
              onKeyDown={this.optionSelectionChanged.bind(this, key)}
              role='button'
              tabIndex={0}
            >
              <div className={classes.deviceInformation}>
                {availableOption.icon ? (
                  <img src={availableOption.icon} alt='yoco-device-icon' />
                ) : (
                  ''
                )}
                <div>
                  <div className={classes.title}>{availableOption.title}</div>
                  {availableOption.subtext ? subtext : ''}
                </div>
              </div>
              <CheckboxButton selected={this.isSelected(key)} role='checkbox' />
            </div>
          )
        })}
        {load}
      </div>
    )
  }

  getDropdown() {
    return (
      <div className={this.getDropdownClassName()}>
        <div>
          <div
            className={classes.closeButton}
            onClick={this.toggle.bind(this)}
            role='button'
            onKeyDown={this.toggle.bind(this)}
            tabIndex={0}
          >
            <Icon name='close' size={24} />
          </div>
          {this.getSearchbox()}
          {this.getErrorContent()}
          {this.getDropdownContent()}
        </div>
        <div className={classes.optionsFooter}>
          <div
            className={`${classes.deviceOptionFooter} ${classes.emptyOption}`}
            onClick={this.optionSelectionChanged.bind(this, null)}
            onKeyDown={this.handleClick}
            role='button'
            tabIndex={0}
          >
            <CheckboxButton selected={this.state.allSelected} />
            <div className={classes.textBlock}>
              <div className={classes.title}>{this.props.emptySelectedString}</div>
            </div>
          </div>
        </div>
        <PaddedContent className='text-right'>
          <FlatButton
            className='blueBackground'
            label='Done'
            testID={makeTestID(this.props.testID, 'done')}
            onClick={() => {
              this.toggle()
            }}
          />
        </PaddedContent>
      </div>
    )
  }
}

DeviceFilter.defaultProps = {
  emptySelectedString: 'Show all',
  searchable: true,
  placeholder: 'Filter',
  filterIcon: 'icon-globe-2',
  startLoading: true,
  titleKey: 'fullName',
}
