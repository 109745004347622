import React from 'react'
import Alert from 'components/notifications/Alert'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { businessDetails } from 'redux/modules/session/selectors'
import FlatButton from 'components/buttons/FlatButton'
import { push } from 'react-router-redux'
import { BUSINESS_TYPES } from 'redux/modules/onboarding'

function StuckInRequestedAlert({ businessDetails: { businessType }, navigateToOnboarding }) {
  return (
    <Alert messageType='warning' closable={false}>
      <p>We have received your request to update your bank account information.</p>
      <br />
      {(businessType === BUSINESS_TYPES.SOLE_PROPRIETOR ||
        businessType === BUSINESS_TYPES.TRUST ||
        businessType === BUSINESS_TYPES.NOT_REGISTERED ||
        businessType === BUSINESS_TYPES.NPO) && (
        <p>
          Please note that we will only be able to process your bank account information once you
          have confirmed your identity.
        </p>
      )}
      {(businessType === BUSINESS_TYPES.REGISTERED_BUSINESS ||
        businessType === BUSINESS_TYPES.CLOSE_CORPORATION ||
        businessType === BUSINESS_TYPES.NPC) && (
        <p>
          Please note that we will only be able to process your bank account information once you
          have confirmed your identity and completed setup of your business.
        </p>
      )}
      <br />
      <FlatButton className='secondary' onClick={navigateToOnboarding}>
        Complete setup
      </FlatButton>
    </Alert>
  )
}

StuckInRequestedAlert.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  businessDetails: PropTypes.object.isRequired,
  navigateToOnboarding: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  businessDetails: businessDetails(state).businessDetails,
})

const mapDispatchToProps = (dispatch) => ({
  navigateToOnboarding: () => dispatch(push('/onboarding')),
})

export default connect(mapStateToProps, mapDispatchToProps)(StuckInRequestedAlert)
