import PropTypes from 'prop-types'
import React from 'react'

import { multipleFieldsUpdated } from 'redux/modules/forms'
import { FORM_NAMES } from 'redux/modules/onboarding'
import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'
import UploadIDDocumentForm from 'components/popups/OnboardingPopups/UploadIDPopup/components/UploadIDDocumentForm'
import uploadIdentityDocument from 'components/popups/OnboardingPopups/UploadIDPopup/utils'
import { sanitizeIDNumber } from 'containers/business/EditBankDetailsPage/utils'

function FicaIDUploadPopup({
  popupHeaderTitle,
  onClosePopup,
  showUploadIDPopup,
  dispatch,
  forms,
  task,
  isIDUploadPopupRendered,
  ficaVerificationRequest,
  onboardingPopupProps: {
    businessUserDetails: { businessDetails, userDetails },
    ficaUploadButtonIsDisabled,
    popupErrorMessages,
  },
  dismissIDUploadPopup,
  isCitizenshipSelected,
}) {
  return (
    <PopupContentLayout
      headerTitle={popupHeaderTitle}
      onClosePopup={onClosePopup}
      showing={showUploadIDPopup}
      popupErrorMessage={popupErrorMessages.failedFicaSubmitError}
      popupBodyContent={
        <UploadIDDocumentForm
          setCitizenShip={(formName, data) => dispatch(multipleFieldsUpdated(formName, data))}
          southAfricanID={forms.getIn([FORM_NAMES.FICA_VERIFICATION, 'data', 'southAfricanID'])}
          southAfricanPassport={forms.getIn([
            FORM_NAMES.FICA_VERIFICATION,
            'data',
            'southAfricanPassport',
          ])}
          task={task}
          ficaForms={forms}
          isIDUploadPopupRendered={isIDUploadPopupRendered}
          ficaVerificationRequest={ficaVerificationRequest}
          businessDetails={businessDetails}
          validateIDNumber={(event) =>
            sanitizeIDNumber(FORM_NAMES.FICA_VERIFICATION, 'idNumber', event, dispatch)
          }
          isCitizenshipSelected={isCitizenshipSelected}
        />
      }
      footerButtonIsDisabled={ficaUploadButtonIsDisabled}
      onFooterButtonClick={() =>
        uploadIdentityDocument(forms, businessDetails, userDetails, dispatch, dismissIDUploadPopup)
      }
    />
  )
}

FicaIDUploadPopup.propTypes = {
  popupHeaderTitle: PropTypes.string,
  onClosePopup: PropTypes.func,
  showUploadIDPopup: PropTypes.bool,
  dispatch: PropTypes.func,
  forms: PropTypes.object,
  task: PropTypes.object,
  isIDUploadPopupRendered: PropTypes.bool,
  onboardingPopupProps: PropTypes.object,
  dismissIDUploadPopup: PropTypes.func,
  isCitizenshipSelected: PropTypes.bool,
  ficaVerificationRequest: PropTypes.object,
}

export default FicaIDUploadPopup
