import PropTypes from 'prop-types'
import React from 'react'

import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'
import CCDInstructions from 'components/popups/OnboardingPopups/CCDDetailsPopup/components/CCDInstructions'

function CCDInformationPopup({
  popupHeaderTitle,
  onClosePopup,
  showCCDDetailsPopup,
  dismissCCDDetailsPopup,
}) {
  return (
    <PopupContentLayout
      headerTitle={popupHeaderTitle}
      onClosePopup={onClosePopup}
      showing={showCCDDetailsPopup}
      popupBodyContent={<CCDInstructions />}
      buttonLabel='Close'
      onFooterButtonClick={dismissCCDDetailsPopup}
    />
  )
}

CCDInformationPopup.propTypes = {
  popupHeaderTitle: PropTypes.string,
  onClosePopup: PropTypes.func,
  showCCDDetailsPopup: PropTypes.bool,
  dismissCCDDetailsPopup: PropTypes.func,
}

export default CCDInformationPopup
