import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import classnames from 'classnames'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import PaddedContent from 'ui/layout/PaddedContent'
import PageHeader from 'ui/components/PageHeader'
import Spacer from 'ui/layout/Spacer'
import typography from 'ui/typography/typography.module.scss'
import layout from 'ui/layout/layout.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import { callJSONApi } from 'libs/api'
import LoadingView from 'components/loaders/LoadingView'
import Alert from 'components/notifications/Alert'
import { makeTestID } from 'libs/utils'
import giftVoucher from 'assets/images/gift-voucher@2x.png'
import { If } from 'libs/formats'

export default class VouchersPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    callJSONApi(
      '/paymentPage/',
      'GET',
      null,
      (data) => {
        const paymentPages = data.data.paymentPages || []

        if (paymentPages.length > 0) {
          const paymentPage = paymentPages[0]

          if (
            paymentPage.supportedPageTypes &&
            paymentPage.supportedPageTypes.indexOf('voucher') !== -1
          ) {
            browserHistory.push('/online/vouchers/activated')
          } else {
            this.setState({
              isLoading: false,
              errorMessage: null,
            })
          }
        } else {
          this.setState({
            isLoading: false,
            errorMessage: null,
          })
        }
      },
      (prettyError) => {
        this.setState({
          isLoading: false,
          errorMessage: prettyError,
        })
      }
    )
  }

  onActivateClick() {
    callJSONApi(
      '/paymentPage/',
      'POST',
      { pageType: 'voucher' },
      () => {
        browserHistory.push('/online/vouchers/activated')
      },
      (prettyError) => {
        this.setState({
          isLoading: false,
          errorMessage: prettyError,
        })
      }
    )
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingView />
    }

    return (
      <MaxWidthBlock>
        <PaddedContent isTextCentered>
          <If condition={this.state.errorMessage}>
            <Alert
              messageType='danger'
              message={this.state.errorMessage}
              closable
              onClose={() => this.setState({ errorMessage: null })}
            />
          </If>

          <PageHeader>Gift vouchers</PageHeader>

          <img src={giftVoucher} alt='Gift voucher' height='141px' />
          <Spacer size='xl' />

          <p className={classnames([layout.maxWidth, layout.marginCentered])}>
            You can now start selling gift vouchers to your customers online. Activate your gift
            voucher page with the button below. Earn immediate sales from customers that they can
            then redeem in store at a later date. Giving you instant sales and cashflow relief.
          </p>

          <Spacer size='large' />

          <FlatButton
            label='Activate my gift vouchers page'
            className='blueBackground'
            onClick={() => this.onActivateClick()}
            testID={makeTestID('sellOnline', 'giftVouchers', 'activate')}
          />

          <Spacer size='xl' />

          <p className={typography.textSmall}>
            Need help?
            <br />
            <a href='https://get.yoco.help/hc/en-za/articles/360007134158'>
              Learn more about selling gift vouchers
            </a>
          </p>
        </PaddedContent>
      </MaxWidthBlock>
    )
  }
}
