/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'

import { onboardingSetupComplete } from 'redux/modules/onboarding'
import onboardingCongratulationImage from 'assets/images/onboarding-complete.png'

import classes from './onboarding.module.scss'

class CongratulationsPage extends Component {
  UNSAFE_componentWillMount() {
    this.props.dispatch(onboardingSetupComplete())
  }

  render() {
    return (
      <div className={classes.pageContainer}>
        <div className={`${classes.page} ${classes.setupCompleted}`}>
          <span className={classes.completedTitle}>You’re all set</span>
          <span className={classes.completedHeading}>Congratulations!</span>
          <img
            className={classes.completedImage}
            src={onboardingCongratulationImage}
            alt='setup completed'
          />
          <span className={classes.completedDescription}>
            Your business profile is all setup. You will receive your first payout within 2-3
            working days.
          </span>
          <div className={classes.goToDashboardButtonContainer}>
            <a
              className={classes.goToDashboardButton}
              onClick={() => this.props.dispatch(push('/dashboard'))}
            >
              View your dashboard
            </a>
          </div>
        </div>
      </div>
    )
  }
}

CongratulationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

// connected this component because connect will inject dispatch as
// a prop by default and there are uses of `this.props.dispatch` in here
export default connect()(CongratulationsPage)
