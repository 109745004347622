/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '@yoco/design-system-icons/dist/react'
import copy from 'copy-to-clipboard'

import { showMessage } from 'redux/modules/notifications'
import Spinner from 'components/loaders/Spinner'
import ContentCard from 'components/cards/ContentCard'

import qrCode from 'assets/images/icon-qr-code.svg'
import { businessName } from 'redux/modules/session/selectors'
import useReferralStore from '../store'

import classes from './referrals.module.scss'
import ReferralQRCodePopup from './ReferralQRCode/ReferralQRCodePopup'

const useStore = () => ({
  referralLink: useReferralStore((state) => state?.referralLink),
})

function ReferralCopyButton() {
  const { referralLink } = useStore()
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const nameOfBusiness = useSelector(businessName)

  const onCopyToClipboard = () => {
    if (referralLink) {
      copy(referralLink)
      dispatch(showMessage('Copied!', 'success', 'copy_referral_code_message'))
    }
  }

  const copyIcon = (size) => (
    <span className={classes.referralButtonIcon}>
      <Icon name='copy' size={size} />
    </span>
  )

  return (
    <div className={classes.referralButtonContainer}>
      <ContentCard>
        <p className={`${classes.referralCardTitle} ${classes.sharpGroteskMedium}`}>
          Copy your referral link:
        </p>
        <div className={classes.referralButton} onClick={onCopyToClipboard}>
          {referralLink ? (
            <span className={classes.referralButtonUrl}>{referralLink}</span>
          ) : (
            <Spinner blue className={classes.referralUrlSpinner} />
          )}
          {copyIcon(20)}
        </div>
        <div className={classes.referralQRContainer} onClick={() => setShowModal(true)}>
          <img className={classes.qrIcon} src={qrCode} alt='qr-code-icn' />
          <span className={classes.referralQrViewButton}>View Referral QR code</span>
          <ReferralQRCodePopup
            showing={showModal}
            onCancel={() => setShowModal(false)}
            businessName={nameOfBusiness}
            referralLink={referralLink}
            rounded
          />
        </div>
      </ContentCard>
    </div>
  )
}

export default ReferralCopyButton
