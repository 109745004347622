import React from 'react'
import CapitalApplicationSelectQuotesPage from 'components/capital/CapitalApplicationSelectQuotesPage'
import CapitalConfirmOfferPage from 'components/capital/CapitalConfirmOfferPage'
import CapitalAdvanceDetailsPage from 'components/capital/CapitalAdvanceDetailsPage'
import CapitalApplicationApprovedPage from 'components/capital/CapitalApplicationApprovedPage'
import CapitalErrorBlock from 'components/capital/partials/CapitalErrorBlock'
import { formatCurrencyNoDecimals } from 'libs/utils'
import {
  AcceptOfferData,
  CapitalOffer,
  CapitalOfferStates,
  DeclineOfferData,
} from 'redux/modules/capital/types'

export interface Props {
  canReApplyForAdvance: boolean
  capitalAmount: number
  capitalOffer: CapitalOffer
  currency: string
  prelimOfferUUID: string
  userEmail: string
  fetchOffers: () => void
  formatCurrency: (amount: number) => string
  getTotalAmountRepaid: (amount: number) => number
  onApplyForReAdvance: (prelimOfferUUID: string, data: any) => void
  onDeductionsLinkClicked: () => void
  onFindOutMoreClicked: () => void
  onOfferAccepted: (uuid: string, data: AcceptOfferData) => void
  onOfferDeclined: (uuid: string, data: DeclineOfferData) => void
  onSliderAmountChanged: (amount: number) => void
  onSliderMonthChanged: (term: number) => void
  onViewContractClicked: () => void
  sendApplication: (applicationUUID: string, data: any) => void
}

const CapitalApplicationPage: React.FunctionComponent<Props> = ({
  canReApplyForAdvance,
  capitalAmount,
  capitalOffer: {
    uuid: offerUUID,
    state: capitalState,
    providerName,
    offerResponseData: {
      offerQuotes,
      offerContract: {
        repayment_percentage: repaymentPercentage,
        estimated_term: estimatedTerm,
        approved_advance: approvedAdvance,
        total_payable: totalPayable,
        processing_fee: processingFee,
        outstanding_balance: outstandingBalance,
        tos_url: contractURL,
      },
      earlySettlementAmount,
      discountOnCurrentAdvanceOutstandingBalance,
    },
    bankAccount: { accountHolder, accountNumber, bank, branchCode },
    payoutDeductionPlan: {
      chargeRate: repaymentPercentageDeductionPlan,
      totalAmountPayable,
      totalAmountPaid,
      principalAmount,
      providerBusinessUUID,
      uuid: deductionPlanUUID,
    },
  },
  currency,
  prelimOfferUUID,
  userEmail,
  fetchOffers,
  formatCurrency,
  getTotalAmountRepaid,
  onApplyForReAdvance,
  onDeductionsLinkClicked,
  onFindOutMoreClicked,
  onOfferAccepted,
  onOfferDeclined,
  onSliderAmountChanged,
  onSliderMonthChanged,
  onViewContractClicked,
  sendApplication,
}) => {
  switch (capitalState) {
    case CapitalOfferStates.CapitalOfferApplicationDataCollectionErrorFromYoco:
      return (
        <CapitalErrorBlock
          title='Have things changed?'
          error={
            "We're missing some of your information. Chat to us on 087 550 9626 to get your profile up to date."
          }
          onRetryPress={() => {
            fetchOffers()
          }}
          retryButtonText='Ok'
        />
      )
    case CapitalOfferStates.ApplicationQuotesPendingFromMerchant:
      return (
        <CapitalApplicationSelectQuotesPage
          capitalOfferQuotes={offerQuotes}
          uuid={offerUUID}
          providerName={providerName}
          currency={currency}
          earlySettlementAmount={earlySettlementAmount}
          discountOnCurrentAdvanceOutstandingBalance={discountOnCurrentAdvanceOutstandingBalance}
          sendApplication={sendApplication}
          onSliderAmountChanged={onSliderAmountChanged}
          onSliderMonthChanged={onSliderMonthChanged}
          onFindOutMoreClicked={onFindOutMoreClicked}
        />
      )
    case CapitalOfferStates.OfferConfirmationPendingFromMerchant:
      return (
        <CapitalConfirmOfferPage
          offerUUID={offerUUID}
          onOfferDeclined={onOfferDeclined}
          onOfferAccepted={onOfferAccepted}
          onFindOutMoreClicked={onFindOutMoreClicked}
          repaymentPercentage={repaymentPercentage}
          estimatedTerm={estimatedTerm}
          totalPayable={totalPayable}
          providerName={providerName}
          processingFee={processingFee}
          outstandingBalance={outstandingBalance}
          approvedAdvance={formatCurrencyNoDecimals(approvedAdvance)}
          contractURL={contractURL}
        />
      )

    case CapitalOfferStates.OfferAcceptedConfirmationApprovedByProvider:
      return (
        <CapitalApplicationApprovedPage
          bankName={bank}
          bankAccountHolder={accountHolder}
          bankAccountNumber={accountNumber}
          bankBranchCode={branchCode}
          userEmail={userEmail}
          providerName={providerName}
          approvedAdvance={approvedAdvance}
          outstandingBalance={outstandingBalance}
          repaymentPercentage={repaymentPercentage}
          estimatedTerm={estimatedTerm}
          processingFee={processingFee}
          totalPayable={totalPayable}
          contractURL={contractURL}
          formatCurrency={formatCurrency}
          onDeductionsLinkClicked={onDeductionsLinkClicked}
          onViewContractClicked={onViewContractClicked}
        />
      )

    case CapitalOfferStates.CapitalOfferCollectionsActivated:
      return (
        <CapitalAdvanceDetailsPage
          canReApplyForAdvance={canReApplyForAdvance}
          deductionPlanUUID={deductionPlanUUID}
          prelimOfferUUID={prelimOfferUUID}
          providerUUID={providerBusinessUUID}
          offerUUID={offerUUID}
          totalAmountPaid={totalAmountPaid}
          totalAmountPayable={totalAmountPayable}
          principalAmount={principalAmount}
          outstandingBalance={outstandingBalance}
          estimatedTerm={estimatedTerm}
          processingFee={processingFee}
          totalPayable={totalPayable}
          repaymentPercentage={repaymentPercentageDeductionPlan}
          capitalAmount={capitalAmount}
          contractURL={contractURL}
          formatCurrency={formatCurrency}
          getTotalAmountRepaid={getTotalAmountRepaid}
          onApplyForReAdvance={onApplyForReAdvance}
        />
      )

    default:
      return <CapitalErrorBlock title="We've hit a snag..." error='Unexpected State' />
  }
}

export default CapitalApplicationPage
