import React from 'react'
import PropTypes from 'prop-types'

import { track } from 'libs/analytics'
import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'

import Content from './components/Content'

function IdDetailsPopup({
  headerTitle,
  buttonLabel,
  onSetIdDetailsPopupVisible,
  isIdDetailsPopupVisible,
  popupErrorMessage,
}) {
  const closePopup = () => {
    track('portal_fica_iidentifii_click_button_cancel')
    onSetIdDetailsPopupVisible(false)
  }
  return (
    <PopupContentLayout
      headerTitle={headerTitle}
      buttonLabel={buttonLabel}
      onClosePopup={closePopup}
      popupErrorMessage={popupErrorMessage}
      showing={isIdDetailsPopupVisible}
      popupBodyContent={<Content />}
      onFooterButtonClick={closePopup}
      hideFooterContent
    />
  )
}

IdDetailsPopup.propTypes = {
  headerTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  onSetIdDetailsPopupVisible: PropTypes.func,
  isIdDetailsPopupVisible: PropTypes.bool,
  popupErrorMessage: PropTypes.string,
}

export default IdDetailsPopup
