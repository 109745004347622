export interface Metric {
  target: number
  actual: number
  achieved: boolean
}

export interface Metrics {
  volume: Metric
  transactions: Metric
  months: Metric
}

export interface Qualification {
  businessUUID: string
  qualified: boolean
  metrics: Metrics
}

export enum RequestState {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export interface Request {
  status: RequestState
  error?: string | null
}

export enum DeclineReasons {
  CallBack = 'callBack',
  Browsing = 'browsing',
  DontNeed = 'dontNeed',
  Pricing = 'pricing',
  SystemError = 'systemError',
  Other = 'other',
}

export enum LendingPartners {
  RetailCapital = 'Retail Capital',
  Lulalend = 'Lulalend',
}

export interface PrelimOffer {
  minimumAmount: number
  uptoAmount: number
  uuid: string
  providerName: LendingPartners
}

export interface InitialApplicationData {
  capitalAmount: number
}

export interface ApplicationData {
  initialApplicationData: InitialApplicationData
}

export interface BankAccount {
  accountHolder: string
  accountNumber: string
  accountType: string
  bank: string
  branchCode: string
  businessUUID: string
  institution: string
  uuid: string
}

export interface OfferQuote {
  advance: number
  quotes: Quote[]
}

export interface Quote {
  repaymentPeriod: number
  repaymentPercentage: number
  dailyAmount: number
}

export interface OfferContract {
  contract_id: string
  total_payable: number
  processing_fee: number
  agreement_text: string
  approved_advance: number
  estimated_term: number
  user_id: string
  tos_url: string
  company_name: string
  user_name: string
  merchant_id: string
  repayment_percentage: number
  outstanding_balance: number
}

export interface OfferResponseData {
  offerQuotes: OfferQuote[]
  offerContract: OfferContract
  earlySettlementAmount?: number
  discountOnCurrentAdvanceOutstandingBalance?: number
}

/* TODO - Make enums for
    1. payoutDeductionPlanType
    2. payoutDeductionCategory
    3. payoutDeductionState
*/
export interface PayoutDeductionPlan {
  clientBusinessUUID: string
  providerBusinessUUID: string
  payoutDeductionPlanType: string
  payoutDeductionCategory: string
  chargeRate: number
  principalAmount: number
  totalAmountPayable: number
  totalAmountPaid: number
  payoutDeductionState: string
  description: string
  capitalOfferUUID: string
  uuid: string
}

export interface PayoutDeductionPlanFromTableData extends PayoutDeductionPlan {
  created: string
}

export enum CapitalOfferStates {
  ApplicationQuotesPendingFromMerchant = 'capital_offer_application_quotes_pending_from_merchant',
  OfferConfirmationPendingFromMerchant = 'capital_offer_pending_confirmation_from_merchant',
  OfferAcceptedConfirmationApprovedByProvider = 'capital_offer_accepted_confirmation_approved_by_provider',
  CapitalOfferCollectionsActivated = 'capital_offer_collections_activated',
  CapitalOfferDeclinedConfirmationApprovedByProvider = 'capital_offer_declined_confirmation_approved_by_provider',
  CapitalOfferApplicationDataCollectionErrorFromYoco = 'capital_offer_application_data_collection_error_from_yoco',
}

export interface CapitalOffer {
  uuid: string
  state: CapitalOfferStates
  providerName: LendingPartners
  offerResponseData: OfferResponseData
  applicationData: ApplicationData
  bankAccount: BankAccount
  payoutDeductionPlan: PayoutDeductionPlan
}

export interface CapitalQualifyingBusiness {
  businessUUID: string
  notifyMe: boolean
  fetchingData: boolean
}

export interface CapitalState {
  qualification: Qualification
  qualificationRequest: Request
  prelimOffer: PrelimOffer
  prelimOffersRequest: Request
  applyForReAdvance: boolean
  capitalOffer: CapitalOffer
  capitalOfferRequest: Request
  capitalQualifyingBusiness: CapitalQualifyingBusiness
}

export enum CapitalActions {
  FETCH_QUALIFICATIONS_REQUEST = '@capital/FETCH_QUALIFICATIONS_REQUEST',
  FETCH_QUALIFICATIONS_SUCCESS = '@capital/FETCH_QUALIFICATIONS_SUCCESS',
  FETCH_QUALIFICATIONS_FAILURE = '@capital/FETCH_QUALIFICATIONS_FAILURE',
  RECEIVED_QUALIFICATIONS = '@capital/RECEIVED_QUALIFICATIONS',
  FETCH_PRELIM_OFFERS_REQUEST = '@capital/FETCH_PRELIM_OFFERS_REQUEST',
  FETCH_PRELIM_OFFERS_SUCCESS = '@capital/FETCH_PRELIM_OFFERS_SUCCESS',
  FETCH_PRELIM_OFFERS_FAILURE = '@capital/FETCH_PRELIM_OFFERS_FAILURE',
  RECEIVED_PRELIM_OFFERS = '@capital/RECEIVED_PRELIM_OFFERS',
  CAPITAL_OPT_IN_REQUEST = '@capital/CAPITAL_OPT_IN_REQUEST',
  CAPITAL_OPT_IN_SUCCESS = '@capital/CAPITAL_OPT_IN_SUCCESS',
  CAPITAL_OPT_IN_FAILURE = '@capital/CAPITAL_OPT_IN_FAILURE',
  SHOW_OFFERS = '@capital/SHOW_OFFERS',
  RE_APPLY = '@capital/RE_APPLY',
  PROGRESS_OFFER_REQUEST = '@capital/PROGRESS_OFFER_REQUEST',
  PROGRESS_OFFER_SUCCESS = '@capital/PROGRESS_OFFER_SUCCESS',
  PROGRESS_OFFER_FAILURE = '@capital/PROGRESS_OFFER_FAILURE',
  RECEIVED_CAPITAL_OFFER = '@capital/RECEIVED_CAPITAL_OFFER',
  RECEIVED_CAPITAL_QUALIFYING_BUSINESS = '@capital/RECEIVED_CAPITAL_QUALIFYING_BUSINESS',
  DECLINED_CAPITAL_OFFER = '@capital/DECLINED_CAPITAL_OFFER',
  ACCEPTED_CAPITAL_OFFER = '@capital/ACCEPTED_CAPITAL_OFFER',
  CREATE_APPLICATION = '@capital/CREATE_APPLICATION',
  SEND_APPLICATION = '@capital/SEND_APPLICATION',
  SLIDER_AMOUNT_CHANGED = '@capital/SLIDER_AMOUNT_CHANGE',
  SLIDER_MONTH_CHANGED = '@capital/SLIDER_MONTH_CHANGE',
  DEDUCTIONS_VIEWED = '@capital/DEDUCTIONS_VIEWED',
  CONTRACT_VIEWED = '@capital/CONTRACT_VIEWED',
  APPLY_FOR_RE_ADVANCE = '@capital/APPLY_FOR_RE_ADVANCE',
  FIND_OUT_MORE_CLICKED = '@capital/FIND_OUT_MORE_CLICKED',
  DEDUCTION_PLANS_VIEWED_FROM_MAIN_NAV_BAR = '@capital/DEDUCTIONS_PLAN_VIEWED_FROM_MAIN_NAV_BAR',
  DEDUCTION_PLAN_VIEWED = '@capital/DEDUCTION_PLAN_VIEWED',
  OFFERS_VIEWED_FROM_MAIN_NAV_BAR = '@capital/OFFERS_VIEWED_FROM_MAIN_NAV_BAR',
  PRELIM_OFFER_PRESENTED = '@capital/PRELIM_OFFER_PRESENTED',
}

export enum CapitalAnalyticsEvents {
  LetMeKnowClicked = 'capital_let_me_know_button_clicked',
  GetCashAdvanceClicked = 'capital_get_cash_advance_button_clicked',
  ContinueCashAdvanceClicked = 'capital_continue_button_click',
  SliderAmountChanged = 'capital_offer_adjust_amount_slider',
  SliderMonthChanged = 'capital_offer_adjust_month_slider',
  AcceptOfferClicked = 'capital_offer_accepted',
  DeclineOfferClicked = 'capital_offer_declined',
  ViewDeductionsClicked = 'capital_view_deductions_button_clicked',
  ViewContractClicked = 'capital_view_contract_button_clicked',
  ReApplyClicked = 'capital_reapply_button_clicked',
  FindOutMoreClicked = 'capital_find_out_more_link_clicked',
  OffersTabClicked = 'capital_offers_tab_clicked',
  DeductionPlansTabClicked = 'capital_deduction_plans_tab_clicked',
  DeductionPlanClicked = 'capital_deduction_plan_clicked',
  PrelimOfferPresented = 'capital_prelim_offer_presented',
}

export interface CapitalAnalyticsEvent {
  event: CapitalAnalyticsEvents
  payload?: any
}

export type ActionAnalyticsEventsMap = {
  [key in CapitalActions]?: CapitalAnalyticsEvent
}

export interface AcceptOfferData {
  merchantBankConfirmation: boolean
  merchantOfferConfirmation: boolean
}

export interface DeclineOfferData {
  merchantBankConfirmation: boolean
  merchantOfferConfirmation: boolean
  declineReason: DeclineReasons
}
