import * as React from 'react'
import propTypes from 'prop-types'

import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'

import { connect } from 'react-redux'
import { wasRoutedFromApp } from 'redux/modules/routes'
import FlagshipBankDetailsError from './components/FlagshipBankDetailsError'
import FlagshipBankDetailsSuccess from './components/FlagshipBankDetailsSuccess'

function FlagshipFeedBackPopup({ show, isSuccess, inApp }) {
  return (
    <PopupContentLayout
      showing={show}
      popupBodyContent={
        isSuccess ? <FlagshipBankDetailsSuccess inApp={inApp} /> : <FlagshipBankDetailsError />
      }
      hideFooterContent
    />
  )
}

FlagshipFeedBackPopup.propTypes = {
  show: propTypes.bool,
  isSuccess: propTypes.bool.isRequired,
  inApp: propTypes.bool.isRequired,
}

FlagshipFeedBackPopup.defaultProps = {
  show: true,
}

const mapStateToProps = (state) => ({
  inApp: wasRoutedFromApp(state),
})

export default connect(mapStateToProps)(FlagshipFeedBackPopup)
