/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'

import CheckboxButton from 'components/buttons/CheckboxButton'

import classes from './styles.module.scss'

function DocumentTypeCard({
  setDocumentType,
  documentType,
  description,
  documentIcon,
  disableCheckbox,
}) {
  return (
    <div className={classes.documentTypeCardContainer} onClick={setDocumentType}>
      <div className={`${classes.documentTypeCardContainer} ${classes.documentTypeCard}`}>
        <div className={classes.checkBoxButton}>
          <CheckboxButton selected={documentType} disabled={disableCheckbox} />
        </div>
        <div className={classes.description}>
          <h5>{description}</h5>
        </div>
        <div className={classes.iconContainer}>
          <img src={documentIcon} alt='icon not available' />
        </div>
      </div>
    </div>
  )
}

DocumentTypeCard.propTypes = {
  documentType: PropTypes.string,
  setDocumentType: PropTypes.func,
  description: PropTypes.string,
  documentIcon: PropTypes.string,
  disableCheckbox: PropTypes.bool,
}

export default DocumentTypeCard
